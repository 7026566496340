/*
Icons mind
Plugin URI: https://iconsmind.com/view_icons/
*/
@font-face {
    font-family: icomoon;
    src: url(../fonts/icomoon.eot?-rdmvgc);
    src: url(../fonts/icomoon.eot?#iefix-rdmvgc) format('embedded-opentype'), url(../fonts/icomoon.woff?-rdmvgc) format('woff'), url(../fonts/icomoon.ttf?-rdmvgc) format('truetype'), url(../fonts/icomoon.svg?-rdmvgc#icomoon) format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap
}

[class*=" line-icon-"],
[class^=line-icon-] {
    font-family: icomoon;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.line-icon-A-Z:before {
    content: "\e600"
}

.line-icon-Aa:before {
    content: "\e601"
}

.line-icon-Add-Bag:before {
    content: "\e602"
}

.line-icon-Add-Basket:before {
    content: "\e603"
}

.line-icon-Add-Cart:before {
    content: "\e604"
}

.line-icon-Add-File:before {
    content: "\e605"
}

.line-icon-Add-SpaceAfterParagraph:before {
    content: "\e606"
}

.line-icon-Add-SpaceBeforeParagraph:before {
    content: "\e607"
}

.line-icon-Add-User:before {
    content: "\e608"
}

.line-icon-Add-UserStar:before {
    content: "\e609"
}

.line-icon-Add-Window:before {
    content: "\e60a"
}

.line-icon-Add:before {
    content: "\e60b"
}

.line-icon-Address-Book:before {
    content: "\e60c"
}

.line-icon-Address-Book2:before {
    content: "\e60d"
}

.line-icon-Administrator:before {
    content: "\e60e"
}

.line-icon-Aerobics-2:before {
    content: "\e60f"
}

.line-icon-Aerobics-3:before {
    content: "\e610"
}

.line-icon-Aerobics:before {
    content: "\e611"
}

.line-icon-Affiliate:before {
    content: "\e612"
}

.line-icon-Aim:before {
    content: "\e613"
}

.line-icon-Air-Balloon:before {
    content: "\e614"
}

.line-icon-Airbrush:before {
    content: "\e615"
}

.line-icon-Airship:before {
    content: "\e616"
}

.line-icon-Alarm-Clock:before {
    content: "\e617"
}

.line-icon-Alarm-Clock2:before {
    content: "\e618"
}

.line-icon-Alarm:before {
    content: "\e619"
}

.line-icon-Alien-2:before {
    content: "\e61a"
}

.line-icon-Alien:before {
    content: "\e61b"
}

.line-icon-Aligator:before {
    content: "\e61c"
}

.line-icon-Align-Center:before {
    content: "\e61d"
}

.line-icon-Align-JustifyAll:before {
    content: "\e61e"
}

.line-icon-Align-JustifyCenter:before {
    content: "\e61f"
}

.line-icon-Align-JustifyLeft:before {
    content: "\e620"
}

.line-icon-Align-JustifyRight:before {
    content: "\e621"
}

.line-icon-Align-Left:before {
    content: "\e622"
}

.line-icon-Align-Right:before {
    content: "\e623"
}

.line-icon-Alpha:before {
    content: "\e624"
}

.line-icon-Ambulance:before {
    content: "\e625"
}

.line-icon-AMX:before {
    content: "\e626"
}

.line-icon-Anchor-2:before {
    content: "\e627"
}

.line-icon-Anchor:before {
    content: "\e628"
}

.line-icon-Android-Store:before {
    content: "\e629"
}

.line-icon-Android:before {
    content: "\e62a"
}

.line-icon-Angel-Smiley:before {
    content: "\e62b"
}

.line-icon-Angel:before {
    content: "\e62c"
}

.line-icon-Angry:before {
    content: "\e62d"
}

.line-icon-Apple-Bite:before {
    content: "\e62e"
}

.line-icon-Apple-Store:before {
    content: "\e62f"
}

.line-icon-Apple:before {
    content: "\e630"
}

.line-icon-Approved-Window:before {
    content: "\e631"
}

.line-icon-Aquarius-2:before {
    content: "\e632"
}

.line-icon-Aquarius:before {
    content: "\e633"
}

.line-icon-Archery-2:before {
    content: "\e634"
}

.line-icon-Archery:before {
    content: "\e635"
}

.line-icon-Argentina:before {
    content: "\e636"
}

.line-icon-Aries-2:before {
    content: "\e637"
}

.line-icon-Aries:before {
    content: "\e638"
}

.line-icon-Army-Key:before {
    content: "\e639"
}

.line-icon-Arrow-Around:before {
    content: "\e63a"
}

.line-icon-Arrow-Back3:before {
    content: "\e63b"
}

.line-icon-Arrow-Back:before {
    content: "\e63c"
}

.line-icon-Arrow-Back2:before {
    content: "\e63d"
}

.line-icon-Arrow-Barrier:before {
    content: "\e63e"
}

.line-icon-Arrow-Circle:before {
    content: "\e63f"
}

.line-icon-Arrow-Cross:before {
    content: "\e640"
}

.line-icon-Arrow-Down:before {
    content: "\e641"
}

.line-icon-Arrow-Down2:before {
    content: "\e642"
}

.line-icon-Arrow-Down3:before {
    content: "\e643"
}

.line-icon-Arrow-DowninCircle:before {
    content: "\e644"
}

.line-icon-Arrow-Fork:before {
    content: "\e645"
}

.line-icon-Arrow-Forward:before {
    content: "\e646"
}

.line-icon-Arrow-Forward2:before {
    content: "\e647"
}

.line-icon-Arrow-From:before {
    content: "\e648"
}

.line-icon-Arrow-Inside:before {
    content: "\e649"
}

.line-icon-Arrow-Inside45:before {
    content: "\e64a"
}

.line-icon-Arrow-InsideGap:before {
    content: "\e64b"
}

.line-icon-Arrow-InsideGap45:before {
    content: "\e64c"
}

.line-icon-Arrow-Into:before {
    content: "\e64d"
}

.line-icon-Arrow-Join:before {
    content: "\e64e"
}

.line-icon-Arrow-Junction:before {
    content: "\e64f"
}

.line-icon-Arrow-Left:before {
    content: "\e650"
}

.line-icon-Arrow-Left2:before {
    content: "\e651"
}

.line-icon-Arrow-LeftinCircle:before {
    content: "\e652"
}

.line-icon-Arrow-Loop:before {
    content: "\e653"
}

.line-icon-Arrow-Merge:before {
    content: "\e654"
}

.line-icon-Arrow-Mix:before {
    content: "\e655"
}

.line-icon-Arrow-Next:before {
    content: "\e656"
}

.line-icon-Arrow-OutLeft:before {
    content: "\e657"
}

.line-icon-Arrow-OutRight:before {
    content: "\e658"
}

.line-icon-Arrow-Outside:before {
    content: "\e659"
}

.line-icon-Arrow-Outside45:before {
    content: "\e65a"
}

.line-icon-Arrow-OutsideGap:before {
    content: "\e65b"
}

.line-icon-Arrow-OutsideGap45:before {
    content: "\e65c"
}

.line-icon-Arrow-Over:before {
    content: "\e65d"
}

.line-icon-Arrow-Refresh:before {
    content: "\e65e"
}

.line-icon-Arrow-Refresh2:before {
    content: "\e65f"
}

.line-icon-Arrow-Right:before {
    content: "\e660"
}

.line-icon-Arrow-Right2:before {
    content: "\e661"
}

.line-icon-Arrow-RightinCircle:before {
    content: "\e662"
}

.line-icon-Arrow-Shuffle:before {
    content: "\e663"
}

.line-icon-Arrow-Squiggly:before {
    content: "\e664"
}

.line-icon-Arrow-Through:before {
    content: "\e665"
}

.line-icon-Arrow-To:before {
    content: "\e666"
}

.line-icon-Arrow-TurnLeft:before {
    content: "\e667"
}

.line-icon-Arrow-TurnRight:before {
    content: "\e668"
}

.line-icon-Arrow-Up:before {
    content: "\e669"
}

.line-icon-Arrow-Up2:before {
    content: "\e66a"
}

.line-icon-Arrow-Up3:before {
    content: "\e66b"
}

.line-icon-Arrow-UpinCircle:before {
    content: "\e66c"
}

.line-icon-Arrow-XLeft:before {
    content: "\e66d"
}

.line-icon-Arrow-XRight:before {
    content: "\e66e"
}

.line-icon-Ask:before {
    content: "\e66f"
}

.line-icon-Assistant:before {
    content: "\e670"
}

.line-icon-Astronaut:before {
    content: "\e671"
}

.line-icon-At-Sign:before {
    content: "\e672"
}

.line-icon-ATM:before {
    content: "\e673"
}

.line-icon-Atom:before {
    content: "\e674"
}

.line-icon-Audio:before {
    content: "\e675"
}

.line-icon-Auto-Flash:before {
    content: "\e676"
}

.line-icon-Autumn:before {
    content: "\e677"
}

.line-icon-Baby-Clothes:before {
    content: "\e678"
}

.line-icon-Baby-Clothes2:before {
    content: "\e679"
}

.line-icon-Baby-Cry:before {
    content: "\e67a"
}

.line-icon-Baby:before {
    content: "\e67b"
}

.line-icon-Back2:before {
    content: "\e67c"
}

.line-icon-Back-Media:before {
    content: "\e67d"
}

.line-icon-Back-Music:before {
    content: "\e67e"
}

.line-icon-Back:before {
    content: "\e67f"
}

.line-icon-Background:before {
    content: "\e680"
}

.line-icon-Bacteria:before {
    content: "\e681"
}

.line-icon-Bag-Coins:before {
    content: "\e682"
}

.line-icon-Bag-Items:before {
    content: "\e683"
}

.line-icon-Bag-Quantity:before {
    content: "\e684"
}

.line-icon-Bag:before {
    content: "\e685"
}

.line-icon-Bakelite:before {
    content: "\e686"
}

.line-icon-Ballet-Shoes:before {
    content: "\e687"
}

.line-icon-Balloon:before {
    content: "\e688"
}

.line-icon-Banana:before {
    content: "\e689"
}

.line-icon-Band-Aid:before {
    content: "\e68a"
}

.line-icon-Bank:before {
    content: "\e68b"
}

.line-icon-Bar-Chart:before {
    content: "\e68c"
}

.line-icon-Bar-Chart2:before {
    content: "\e68d"
}

.line-icon-Bar-Chart3:before {
    content: "\e68e"
}

.line-icon-Bar-Chart4:before {
    content: "\e68f"
}

.line-icon-Bar-Chart5:before {
    content: "\e690"
}

.line-icon-Bar-Code:before {
    content: "\e691"
}

.line-icon-Barricade-2:before {
    content: "\e692"
}

.line-icon-Barricade:before {
    content: "\e693"
}

.line-icon-Baseball:before {
    content: "\e694"
}

.line-icon-Basket-Ball:before {
    content: "\e695"
}

.line-icon-Basket-Coins:before {
    content: "\e696"
}

.line-icon-Basket-Items:before {
    content: "\e697"
}

.line-icon-Basket-Quantity:before {
    content: "\e698"
}

.line-icon-Bat-2:before {
    content: "\e699"
}

.line-icon-Bat:before {
    content: "\e69a"
}

.line-icon-Bathrobe:before {
    content: "\e69b"
}

.line-icon-Batman-Mask:before {
    content: "\e69c"
}

.line-icon-Battery-0:before {
    content: "\e69d"
}

.line-icon-Battery-25:before {
    content: "\e69e"
}

.line-icon-Battery-50:before {
    content: "\e69f"
}

.line-icon-Battery-75:before {
    content: "\e6a0"
}

.line-icon-Battery-100:before {
    content: "\e6a1"
}

.line-icon-Battery-Charge:before {
    content: "\e6a2"
}

.line-icon-Bear:before {
    content: "\e6a3"
}

.line-icon-Beard-2:before {
    content: "\e6a4"
}

.line-icon-Beard-3:before {
    content: "\e6a5"
}

.line-icon-Beard:before {
    content: "\e6a6"
}

.line-icon-Bebo:before {
    content: "\e6a7"
}

.line-icon-Bee:before {
    content: "\e6a8"
}

.line-icon-Beer-Glass:before {
    content: "\e6a9"
}

.line-icon-Beer:before {
    content: "\e6aa"
}

.line-icon-Bell-2:before {
    content: "\e6ab"
}

.line-icon-Bell:before {
    content: "\e6ac"
}

.line-icon-Belt-2:before {
    content: "\e6ad"
}

.line-icon-Belt-3:before {
    content: "\e6ae"
}

.line-icon-Belt:before {
    content: "\e6af"
}

.line-icon-Berlin-Tower:before {
    content: "\e6b0"
}

.line-icon-Beta:before {
    content: "\e6b1"
}

.line-icon-Betvibes:before {
    content: "\e6b2"
}

.line-icon-Bicycle-2:before {
    content: "\e6b3"
}

.line-icon-Bicycle-3:before {
    content: "\e6b4"
}

.line-icon-Bicycle:before {
    content: "\e6b5"
}

.line-icon-Big-Bang:before {
    content: "\e6b6"
}

.line-icon-Big-Data:before {
    content: "\e6b7"
}

.line-icon-Bike-Helmet:before {
    content: "\e6b8"
}

.line-icon-Bikini:before {
    content: "\e6b9"
}

.line-icon-Bilk-Bottle2:before {
    content: "\e6ba"
}

.line-icon-Billing:before {
    content: "\e6bb"
}

.line-icon-Bing:before {
    content: "\e6bc"
}

.line-icon-Binocular:before {
    content: "\e6bd"
}

.line-icon-Bio-Hazard:before {
    content: "\e6be"
}

.line-icon-Biotech:before {
    content: "\e6bf"
}

.line-icon-Bird-DeliveringLetter:before {
    content: "\e6c0"
}

.line-icon-Bird:before {
    content: "\e6c1"
}

.line-icon-Birthday-Cake:before {
    content: "\e6c2"
}

.line-icon-Bisexual:before {
    content: "\e6c3"
}

.line-icon-Bishop:before {
    content: "\e6c4"
}

.line-icon-Bitcoin:before {
    content: "\e6c5"
}

.line-icon-Black-Cat:before {
    content: "\e6c6"
}

.line-icon-Blackboard:before {
    content: "\e6c7"
}

.line-icon-Blinklist:before {
    content: "\e6c8"
}

.line-icon-Block-Cloud:before {
    content: "\e6c9"
}

.line-icon-Block-Window:before {
    content: "\e6ca"
}

.line-icon-Blogger:before {
    content: "\e6cb"
}

.line-icon-Blood:before {
    content: "\e6cc"
}

.line-icon-Blouse:before {
    content: "\e6cd"
}

.line-icon-Blueprint:before {
    content: "\e6ce"
}

.line-icon-Board:before {
    content: "\e6cf"
}

.line-icon-Bodybuilding:before {
    content: "\e6d0"
}

.line-icon-Bold-Text:before {
    content: "\e6d1"
}

.line-icon-Bone:before {
    content: "\e6d2"
}

.line-icon-Bones:before {
    content: "\e6d3"
}

.line-icon-Book:before {
    content: "\e6d4"
}

.line-icon-Bookmark:before {
    content: "\e6d5"
}

.line-icon-Books-2:before {
    content: "\e6d6"
}

.line-icon-Books:before {
    content: "\e6d7"
}

.line-icon-Boom:before {
    content: "\e6d8"
}

.line-icon-Boot-2:before {
    content: "\e6d9"
}

.line-icon-Boot:before {
    content: "\e6da"
}

.line-icon-Bottom-ToTop:before {
    content: "\e6db"
}

.line-icon-Bow-2:before {
    content: "\e6dc"
}

.line-icon-Bow-3:before {
    content: "\e6dd"
}

.line-icon-Bow-4:before {
    content: "\e6de"
}

.line-icon-Bow-5:before {
    content: "\e6df"
}

.line-icon-Bow-6:before {
    content: "\e6e0"
}

.line-icon-Bow:before {
    content: "\e6e1"
}

.line-icon-Bowling-2:before {
    content: "\e6e2"
}

.line-icon-Bowling:before {
    content: "\e6e3"
}

.line-icon-Box2:before {
    content: "\e6e4"
}

.line-icon-Box-Close:before {
    content: "\e6e5"
}

.line-icon-Box-Full:before {
    content: "\e6e6"
}

.line-icon-Box-Open:before {
    content: "\e6e7"
}

.line-icon-Box-withFolders:before {
    content: "\e6e8"
}

.line-icon-Box:before {
    content: "\e6e9"
}

.line-icon-Boy:before {
    content: "\e6ea"
}

.line-icon-Bra:before {
    content: "\e6eb"
}

.line-icon-Brain-2:before {
    content: "\e6ec"
}

.line-icon-Brain-3:before {
    content: "\e6ed"
}

.line-icon-Brain:before {
    content: "\e6ee"
}

.line-icon-Brazil:before {
    content: "\e6ef"
}

.line-icon-Bread-2:before {
    content: "\e6f0"
}

.line-icon-Bread:before {
    content: "\e6f1"
}

.line-icon-Bridge:before {
    content: "\e6f2"
}

.line-icon-Brightkite:before {
    content: "\e6f3"
}

.line-icon-Broke-Link2:before {
    content: "\e6f4"
}

.line-icon-Broken-Link:before {
    content: "\e6f5"
}

.line-icon-Broom:before {
    content: "\e6f6"
}

.line-icon-Brush:before {
    content: "\e6f7"
}

.line-icon-Bucket:before {
    content: "\e6f8"
}

.line-icon-Bug:before {
    content: "\e6f9"
}

.line-icon-Building:before {
    content: "\e6fa"
}

.line-icon-Bulleted-List:before {
    content: "\e6fb"
}

.line-icon-Bus-2:before {
    content: "\e6fc"
}

.line-icon-Bus:before {
    content: "\e6fd"
}

.line-icon-Business-Man:before {
    content: "\e6fe"
}

.line-icon-Business-ManWoman:before {
    content: "\e6ff"
}

.line-icon-Business-Mens:before {
    content: "\e700"
}

.line-icon-Business-Woman:before {
    content: "\e701"
}

.line-icon-Butterfly:before {
    content: "\e702"
}

.line-icon-Button:before {
    content: "\e703"
}

.line-icon-Cable-Car:before {
    content: "\e704"
}

.line-icon-Cake:before {
    content: "\e705"
}

.line-icon-Calculator-2:before {
    content: "\e706"
}

.line-icon-Calculator-3:before {
    content: "\e707"
}

.line-icon-Calculator:before {
    content: "\e708"
}

.line-icon-Calendar-2:before {
    content: "\e709"
}

.line-icon-Calendar-3:before {
    content: "\e70a"
}

.line-icon-Calendar-4:before {
    content: "\e70b"
}

.line-icon-Calendar-Clock:before {
    content: "\e70c"
}

.line-icon-Calendar:before {
    content: "\e70d"
}

.line-icon-Camel:before {
    content: "\e70e"
}

.line-icon-Camera-2:before {
    content: "\e70f"
}

.line-icon-Camera-3:before {
    content: "\e710"
}

.line-icon-Camera-4:before {
    content: "\e711"
}

.line-icon-Camera-5:before {
    content: "\e712"
}

.line-icon-Camera-Back:before {
    content: "\e713"
}

.line-icon-Camera:before {
    content: "\e714"
}

.line-icon-Can-2:before {
    content: "\e715"
}

.line-icon-Can:before {
    content: "\e716"
}

.line-icon-Canada:before {
    content: "\e717"
}

.line-icon-Cancer-2:before {
    content: "\e718"
}

.line-icon-Cancer-3:before {
    content: "\e719"
}

.line-icon-Cancer:before {
    content: "\e71a"
}

.line-icon-Candle:before {
    content: "\e71b"
}

.line-icon-Candy-Cane:before {
    content: "\e71c"
}

.line-icon-Candy:before {
    content: "\e71d"
}

.line-icon-Cannon:before {
    content: "\e71e"
}

.line-icon-Cap-2:before {
    content: "\e71f"
}

.line-icon-Cap-3:before {
    content: "\e720"
}

.line-icon-Cap-Smiley:before {
    content: "\e721"
}

.line-icon-Cap:before {
    content: "\e722"
}

.line-icon-Capricorn-2:before {
    content: "\e723"
}

.line-icon-Capricorn:before {
    content: "\e724"
}

.line-icon-Car-2:before {
    content: "\e725"
}

.line-icon-Car-3:before {
    content: "\e726"
}

.line-icon-Car-Coins:before {
    content: "\e727"
}

.line-icon-Car-Items:before {
    content: "\e728"
}

.line-icon-Car-Wheel:before {
    content: "\e729"
}

.line-icon-Car:before {
    content: "\e72a"
}

.line-icon-Cardigan:before {
    content: "\e72b"
}

.line-icon-Cardiovascular:before {
    content: "\e72c"
}

.line-icon-Cart-Quantity:before {
    content: "\e72d"
}

.line-icon-Casette-Tape:before {
    content: "\e72e"
}

.line-icon-Cash-Register:before {
    content: "\e72f"
}

.line-icon-Cash-register2:before {
    content: "\e730"
}

.line-icon-Castle:before {
    content: "\e731"
}

.line-icon-Cat:before {
    content: "\e732"
}

.line-icon-Cathedral:before {
    content: "\e733"
}

.line-icon-Cauldron:before {
    content: "\e734"
}

.line-icon-CD-2:before {
    content: "\e735"
}

.line-icon-CD-Cover:before {
    content: "\e736"
}

.line-icon-CD:before {
    content: "\e737"
}

.line-icon-Cello:before {
    content: "\e738"
}

.line-icon-Celsius:before {
    content: "\e739"
}

.line-icon-Chacked-Flag:before {
    content: "\e73a"
}

.line-icon-Chair:before {
    content: "\e73b"
}

.line-icon-Charger:before {
    content: "\e73c"
}

.line-icon-Check-2:before {
    content: "\e73d"
}

.line-icon-Check:before {
    content: "\e73e"
}

.line-icon-Checked-User:before {
    content: "\e73f"
}

.line-icon-Checkmate:before {
    content: "\e740"
}

.line-icon-Checkout-Bag:before {
    content: "\e741"
}

.line-icon-Checkout-Basket:before {
    content: "\e742"
}

.line-icon-Checkout:before {
    content: "\e743"
}

.line-icon-Cheese:before {
    content: "\e744"
}

.line-icon-Cheetah:before {
    content: "\e745"
}

.line-icon-Chef-Hat:before {
    content: "\e746"
}

.line-icon-Chef-Hat2:before {
    content: "\e747"
}

.line-icon-Chef:before {
    content: "\e748"
}

.line-icon-Chemical-2:before {
    content: "\e749"
}

.line-icon-Chemical-3:before {
    content: "\e74a"
}

.line-icon-Chemical-4:before {
    content: "\e74b"
}

.line-icon-Chemical-5:before {
    content: "\e74c"
}

.line-icon-Chemical:before {
    content: "\e74d"
}

.line-icon-Chess-Board:before {
    content: "\e74e"
}

.line-icon-Chess:before {
    content: "\e74f"
}

.line-icon-Chicken:before {
    content: "\e750"
}

.line-icon-Chile:before {
    content: "\e751"
}

.line-icon-Chimney:before {
    content: "\e752"
}

.line-icon-China:before {
    content: "\e753"
}

.line-icon-Chinese-Temple:before {
    content: "\e754"
}

.line-icon-Chip:before {
    content: "\e755"
}

.line-icon-Chopsticks-2:before {
    content: "\e756"
}

.line-icon-Chopsticks:before {
    content: "\e757"
}

.line-icon-Christmas-Ball:before {
    content: "\e758"
}

.line-icon-Christmas-Bell:before {
    content: "\e759"
}

.line-icon-Christmas-Candle:before {
    content: "\e75a"
}

.line-icon-Christmas-Hat:before {
    content: "\e75b"
}

.line-icon-Christmas-Sleigh:before {
    content: "\e75c"
}

.line-icon-Christmas-Snowman:before {
    content: "\e75d"
}

.line-icon-Christmas-Sock:before {
    content: "\e75e"
}

.line-icon-Christmas-Tree:before {
    content: "\e75f"
}

.line-icon-Christmas:before {
    content: "\e760"
}

.line-icon-Chrome:before {
    content: "\e761"
}

.line-icon-Chrysler-Building:before {
    content: "\e762"
}

.line-icon-Cinema:before {
    content: "\e763"
}

.line-icon-Circular-Point:before {
    content: "\e764"
}

.line-icon-City-Hall:before {
    content: "\e765"
}

.line-icon-Clamp:before {
    content: "\e766"
}

.line-icon-Clapperboard-Close:before {
    content: "\e767"
}

.line-icon-Clapperboard-Open:before {
    content: "\e768"
}

.line-icon-Claps:before {
    content: "\e769"
}

.line-icon-Clef:before {
    content: "\e76a"
}

.line-icon-Clinic:before {
    content: "\e76b"
}

.line-icon-Clock-2:before {
    content: "\e76c"
}

.line-icon-Clock-3:before {
    content: "\e76d"
}

.line-icon-Clock-4:before {
    content: "\e76e"
}

.line-icon-Clock-Back:before {
    content: "\e76f"
}

.line-icon-Clock-Forward:before {
    content: "\e770"
}

.line-icon-Clock:before {
    content: "\e771"
}

.line-icon-Close-Window:before {
    content: "\e772"
}

.line-icon-Close:before {
    content: "\e773"
}

.line-icon-Clothing-Store:before {
    content: "\e774"
}

.line-icon-Cloud--:before {
    content: "\e775"
}

.line-icon-Cloud-:before {
    content: "\e776"
}

.line-icon-Cloud-Camera:before {
    content: "\e777"
}

.line-icon-Cloud-Computer:before {
    content: "\e778"
}

.line-icon-Cloud-Email:before {
    content: "\e779"
}

.line-icon-Cloud-Hail:before {
    content: "\e77a"
}

.line-icon-Cloud-Laptop:before {
    content: "\e77b"
}

.line-icon-Cloud-Lock:before {
    content: "\e77c"
}

.line-icon-Cloud-Moon:before {
    content: "\e77d"
}

.line-icon-Cloud-Music:before {
    content: "\e77e"
}

.line-icon-Cloud-Picture:before {
    content: "\e77f"
}

.line-icon-Cloud-Rain:before {
    content: "\e780"
}

.line-icon-Cloud-Remove:before {
    content: "\e781"
}

.line-icon-Cloud-Secure:before {
    content: "\e782"
}

.line-icon-Cloud-Settings:before {
    content: "\e783"
}

.line-icon-Cloud-Smartphone:before {
    content: "\e784"
}

.line-icon-Cloud-Snow:before {
    content: "\e785"
}

.line-icon-Cloud-Sun:before {
    content: "\e786"
}

.line-icon-Cloud-Tablet:before {
    content: "\e787"
}

.line-icon-Cloud-Video:before {
    content: "\e788"
}

.line-icon-Cloud-Weather:before {
    content: "\e789"
}

.line-icon-Cloud:before {
    content: "\e78a"
}

.line-icon-Clouds-Weather:before {
    content: "\e78b"
}

.line-icon-Clouds:before {
    content: "\e78c"
}

.line-icon-Clown:before {
    content: "\e78d"
}

.line-icon-CMYK:before {
    content: "\e78e"
}

.line-icon-Coat:before {
    content: "\e78f"
}

.line-icon-Cocktail:before {
    content: "\e790"
}

.line-icon-Coconut:before {
    content: "\e791"
}

.line-icon-Code-Window:before {
    content: "\e792"
}

.line-icon-Coding:before {
    content: "\e793"
}

.line-icon-Coffee-2:before {
    content: "\e794"
}

.line-icon-Coffee-Bean:before {
    content: "\e795"
}

.line-icon-Coffee-Machine:before {
    content: "\e796"
}

.line-icon-Coffee-toGo:before {
    content: "\e797"
}

.line-icon-Coffee:before {
    content: "\e798"
}

.line-icon-Coffin:before {
    content: "\e799"
}

.line-icon-Coin:before {
    content: "\e79a"
}

.line-icon-Coins-2:before {
    content: "\e79b"
}

.line-icon-Coins-3:before {
    content: "\e79c"
}

.line-icon-Coins:before {
    content: "\e79d"
}

.line-icon-Colombia:before {
    content: "\e79e"
}

.line-icon-Colosseum:before {
    content: "\e79f"
}

.line-icon-Column-2:before {
    content: "\e7a0"
}

.line-icon-Column-3:before {
    content: "\e7a1"
}

.line-icon-Column:before {
    content: "\e7a2"
}

.line-icon-Comb-2:before {
    content: "\e7a3"
}

.line-icon-Comb:before {
    content: "\e7a4"
}

.line-icon-Communication-Tower:before {
    content: "\e7a5"
}

.line-icon-Communication-Tower2:before {
    content: "\e7a6"
}

.line-icon-Compass-2:before {
    content: "\e7a7"
}

.line-icon-Compass-3:before {
    content: "\e7a8"
}

.line-icon-Compass-4:before {
    content: "\e7a9"
}

.line-icon-Compass-Rose:before {
    content: "\e7aa"
}

.line-icon-Compass:before {
    content: "\e7ab"
}

.line-icon-Computer-2:before {
    content: "\e7ac"
}

.line-icon-Computer-3:before {
    content: "\e7ad"
}

.line-icon-Computer-Secure:before {
    content: "\e7ae"
}

.line-icon-Computer:before {
    content: "\e7af"
}

.line-icon-Conference:before {
    content: "\e7b0"
}

.line-icon-Confused:before {
    content: "\e7b1"
}

.line-icon-Conservation:before {
    content: "\e7b2"
}

.line-icon-Consulting:before {
    content: "\e7b3"
}

.line-icon-Contrast:before {
    content: "\e7b4"
}

.line-icon-Control-2:before {
    content: "\e7b5"
}

.line-icon-Control:before {
    content: "\e7b6"
}

.line-icon-Cookie-Man:before {
    content: "\e7b7"
}

.line-icon-Cookies:before {
    content: "\e7b8"
}

.line-icon-Cool-Guy:before {
    content: "\e7b9"
}

.line-icon-Cool:before {
    content: "\e7ba"
}

.line-icon-Copyright:before {
    content: "\e7bb"
}

.line-icon-Costume:before {
    content: "\e7bc"
}

.line-icon-Couple-Sign:before {
    content: "\e7bd"
}

.line-icon-Cow:before {
    content: "\e7be"
}

.line-icon-CPU:before {
    content: "\e7bf"
}

.line-icon-Crane:before {
    content: "\e7c0"
}

.line-icon-Cranium:before {
    content: "\e7c1"
}

.line-icon-Credit-Card:before {
    content: "\e7c2"
}

.line-icon-Credit-Card2:before {
    content: "\e7c3"
}

.line-icon-Credit-Card3:before {
    content: "\e7c4"
}

.line-icon-Cricket:before {
    content: "\e7c5"
}

.line-icon-Criminal:before {
    content: "\e7c6"
}

.line-icon-Croissant:before {
    content: "\e7c7"
}

.line-icon-Crop-2:before {
    content: "\e7c8"
}

.line-icon-Crop-3:before {
    content: "\e7c9"
}

.line-icon-Crown-2:before {
    content: "\e7ca"
}

.line-icon-Crown:before {
    content: "\e7cb"
}

.line-icon-Crying:before {
    content: "\e7cc"
}

.line-icon-Cube-Molecule:before {
    content: "\e7cd"
}

.line-icon-Cube-Molecule2:before {
    content: "\e7ce"
}

.line-icon-Cupcake:before {
    content: "\e7cf"
}

.line-icon-Cursor-Click:before {
    content: "\e7d0"
}

.line-icon-Cursor-Click2:before {
    content: "\e7d1"
}

.line-icon-Cursor-Move:before {
    content: "\e7d2"
}

.line-icon-Cursor-Move2:before {
    content: "\e7d3"
}

.line-icon-Cursor-Select:before {
    content: "\e7d4"
}

.line-icon-Cursor:before {
    content: "\e7d5"
}

.line-icon-D-Eyeglasses:before {
    content: "\e7d6"
}

.line-icon-D-Eyeglasses2:before {
    content: "\e7d7"
}

.line-icon-Dam:before {
    content: "\e7d8"
}

.line-icon-Danemark:before {
    content: "\e7d9"
}

.line-icon-Danger-2:before {
    content: "\e7da"
}

.line-icon-Danger:before {
    content: "\e7db"
}

.line-icon-Dashboard:before {
    content: "\e7dc"
}

.line-icon-Data-Backup:before {
    content: "\e7dd"
}

.line-icon-Data-Block:before {
    content: "\e7de"
}

.line-icon-Data-Center:before {
    content: "\e7df"
}

.line-icon-Data-Clock:before {
    content: "\e7e0"
}

.line-icon-Data-Cloud:before {
    content: "\e7e1"
}

.line-icon-Data-Compress:before {
    content: "\e7e2"
}

.line-icon-Data-Copy:before {
    content: "\e7e3"
}

.line-icon-Data-Download:before {
    content: "\e7e4"
}

.line-icon-Data-Financial:before {
    content: "\e7e5"
}

.line-icon-Data-Key:before {
    content: "\e7e6"
}

.line-icon-Data-Lock:before {
    content: "\e7e7"
}

.line-icon-Data-Network:before {
    content: "\e7e8"
}

.line-icon-Data-Password:before {
    content: "\e7e9"
}

.line-icon-Data-Power:before {
    content: "\e7ea"
}

.line-icon-Data-Refresh:before {
    content: "\e7eb"
}

.line-icon-Data-Save:before {
    content: "\e7ec"
}

.line-icon-Data-Search:before {
    content: "\e7ed"
}

.line-icon-Data-Security:before {
    content: "\e7ee"
}

.line-icon-Data-Settings:before {
    content: "\e7ef"
}

.line-icon-Data-Sharing:before {
    content: "\e7f0"
}

.line-icon-Data-Shield:before {
    content: "\e7f1"
}

.line-icon-Data-Signal:before {
    content: "\e7f2"
}

.line-icon-Data-Storage:before {
    content: "\e7f3"
}

.line-icon-Data-Stream:before {
    content: "\e7f4"
}

.line-icon-Data-Transfer:before {
    content: "\e7f5"
}

.line-icon-Data-Unlock:before {
    content: "\e7f6"
}

.line-icon-Data-Upload:before {
    content: "\e7f7"
}

.line-icon-Data-Yes:before {
    content: "\e7f8"
}

.line-icon-Data:before {
    content: "\e7f9"
}

.line-icon-David-Star:before {
    content: "\e7fa"
}

.line-icon-Daylight:before {
    content: "\e7fb"
}

.line-icon-Death:before {
    content: "\e7fc"
}

.line-icon-Debian:before {
    content: "\e7fd"
}

.line-icon-Dec:before {
    content: "\e7fe"
}

.line-icon-Decrase-Inedit:before {
    content: "\e7ff"
}

.line-icon-Deer-2:before {
    content: "\e800"
}

.line-icon-Deer:before {
    content: "\e801"
}

.line-icon-Delete-File:before {
    content: "\e802"
}

.line-icon-Delete-Window:before {
    content: "\e803"
}

.line-icon-Delicious:before {
    content: "\e804"
}

.line-icon-Depression:before {
    content: "\e805"
}

.line-icon-Deviantart:before {
    content: "\e806"
}

.line-icon-Device-SyncwithCloud:before {
    content: "\e807"
}

.line-icon-Diamond:before {
    content: "\e808"
}

.line-icon-Dice-2:before {
    content: "\e809"
}

.line-icon-Dice:before {
    content: "\e80a"
}

.line-icon-Digg:before {
    content: "\e80b"
}

.line-icon-Digital-Drawing:before {
    content: "\e80c"
}

.line-icon-Diigo:before {
    content: "\e80d"
}

.line-icon-Dinosaur:before {
    content: "\e80e"
}

.line-icon-Diploma-2:before {
    content: "\e80f"
}

.line-icon-Diploma:before {
    content: "\e810"
}

.line-icon-Direction-East:before {
    content: "\e811"
}

.line-icon-Direction-North:before {
    content: "\e812"
}

.line-icon-Direction-South:before {
    content: "\e813"
}

.line-icon-Direction-West:before {
    content: "\e814"
}

.line-icon-Director:before {
    content: "\e815"
}

.line-icon-Disk:before {
    content: "\e816"
}

.line-icon-Dj:before {
    content: "\e817"
}

.line-icon-DNA-2:before {
    content: "\e818"
}

.line-icon-DNA-Helix:before {
    content: "\e819"
}

.line-icon-DNA:before {
    content: "\e81a"
}

.line-icon-Doctor:before {
    content: "\e81b"
}

.line-icon-Dog:before {
    content: "\e81c"
}

.line-icon-Dollar-Sign:before {
    content: "\e81d"
}

.line-icon-Dollar-Sign2:before {
    content: "\e81e"
}

.line-icon-Dollar:before {
    content: "\e81f"
}

.line-icon-Dolphin:before {
    content: "\e820"
}

.line-icon-Domino:before {
    content: "\e821"
}

.line-icon-Door-Hanger:before {
    content: "\e822"
}

.line-icon-Door:before {
    content: "\e823"
}

.line-icon-Doplr:before {
    content: "\e824"
}

.line-icon-Double-Circle:before {
    content: "\e825"
}

.line-icon-Double-Tap:before {
    content: "\e826"
}

.line-icon-Doughnut:before {
    content: "\e827"
}

.line-icon-Dove:before {
    content: "\e828"
}

.line-icon-Down-2:before {
    content: "\e829"
}

.line-icon-Down-3:before {
    content: "\e82a"
}

.line-icon-Down-4:before {
    content: "\e82b"
}

.line-icon-Down:before {
    content: "\e82c"
}

.line-icon-Download-2:before {
    content: "\e82d"
}

.line-icon-Download-fromCloud:before {
    content: "\e82e"
}

.line-icon-Download-Window:before {
    content: "\e82f"
}

.line-icon-Download:before {
    content: "\e830"
}

.line-icon-Downward:before {
    content: "\e831"
}

.line-icon-Drag-Down:before {
    content: "\e832"
}

.line-icon-Drag-Left:before {
    content: "\e833"
}

.line-icon-Drag-Right:before {
    content: "\e834"
}

.line-icon-Drag-Up:before {
    content: "\e835"
}

.line-icon-Drag:before {
    content: "\e836"
}

.line-icon-Dress:before {
    content: "\e837"
}

.line-icon-Drill-2:before {
    content: "\e838"
}

.line-icon-Drill:before {
    content: "\e839"
}

.line-icon-Drop:before {
    content: "\e83a"
}

.line-icon-Dropbox:before {
    content: "\e83b"
}

.line-icon-Drum:before {
    content: "\e83c"
}

.line-icon-Dry:before {
    content: "\e83d"
}

.line-icon-Duck:before {
    content: "\e83e"
}

.line-icon-Dumbbell:before {
    content: "\e83f"
}

.line-icon-Duplicate-Layer:before {
    content: "\e840"
}

.line-icon-Duplicate-Window:before {
    content: "\e841"
}

.line-icon-DVD:before {
    content: "\e842"
}

.line-icon-Eagle:before {
    content: "\e843"
}

.line-icon-Ear:before {
    content: "\e844"
}

.line-icon-Earphones-2:before {
    content: "\e845"
}

.line-icon-Earphones:before {
    content: "\e846"
}

.line-icon-Eci-Icon:before {
    content: "\e847"
}

.line-icon-Edit-Map:before {
    content: "\e848"
}

.line-icon-Edit:before {
    content: "\e849"
}

.line-icon-Eggs:before {
    content: "\e84a"
}

.line-icon-Egypt:before {
    content: "\e84b"
}

.line-icon-Eifel-Tower:before {
    content: "\e84c"
}

.line-icon-eject-2:before {
    content: "\e84d"
}

.line-icon-Eject:before {
    content: "\e84e"
}

.line-icon-El-Castillo:before {
    content: "\e84f"
}

.line-icon-Elbow:before {
    content: "\e850"
}

.line-icon-Electric-Guitar:before {
    content: "\e851"
}

.line-icon-Electricity:before {
    content: "\e852"
}

.line-icon-Elephant:before {
    content: "\e853"
}

.line-icon-Email:before {
    content: "\e854"
}

.line-icon-Embassy:before {
    content: "\e855"
}

.line-icon-Empire-StateBuilding:before {
    content: "\e856"
}

.line-icon-Empty-Box:before {
    content: "\e857"
}

.line-icon-End2:before {
    content: "\e858"
}

.line-icon-End-2:before {
    content: "\e859"
}

.line-icon-End:before {
    content: "\e85a"
}

.line-icon-Endways:before {
    content: "\e85b"
}

.line-icon-Engineering:before {
    content: "\e85c"
}

.line-icon-Envelope-2:before {
    content: "\e85d"
}

.line-icon-Envelope:before {
    content: "\e85e"
}

.line-icon-Environmental-2:before {
    content: "\e85f"
}

.line-icon-Environmental-3:before {
    content: "\e860"
}

.line-icon-Environmental:before {
    content: "\e861"
}

.line-icon-Equalizer:before {
    content: "\e862"
}

.line-icon-Eraser-2:before {
    content: "\e863"
}

.line-icon-Eraser-3:before {
    content: "\e864"
}

.line-icon-Eraser:before {
    content: "\e865"
}

.line-icon-Error-404Window:before {
    content: "\e866"
}

.line-icon-Euro-Sign:before {
    content: "\e867"
}

.line-icon-Euro-Sign2:before {
    content: "\e868"
}

.line-icon-Euro:before {
    content: "\e869"
}

.line-icon-Evernote:before {
    content: "\e86a"
}

.line-icon-Evil:before {
    content: "\e86b"
}

.line-icon-Explode:before {
    content: "\e86c"
}

.line-icon-Eye-2:before {
    content: "\e86d"
}

.line-icon-Eye-Blind:before {
    content: "\e86e"
}

.line-icon-Eye-Invisible:before {
    content: "\e86f"
}

.line-icon-Eye-Scan:before {
    content: "\e870"
}

.line-icon-Eye-Visible:before {
    content: "\e871"
}

.line-icon-Eye:before {
    content: "\e872"
}

.line-icon-Eyebrow-2:before {
    content: "\e873"
}

.line-icon-Eyebrow-3:before {
    content: "\e874"
}

.line-icon-Eyebrow:before {
    content: "\e875"
}

.line-icon-Eyeglasses-Smiley:before {
    content: "\e876"
}

.line-icon-Eyeglasses-Smiley2:before {
    content: "\e877"
}

.line-icon-Face-Style:before {
    content: "\e878"
}

.line-icon-Face-Style2:before {
    content: "\e879"
}

.line-icon-Face-Style3:before {
    content: "\e87a"
}

.line-icon-Face-Style4:before {
    content: "\e87b"
}

.line-icon-Face-Style5:before {
    content: "\e87c"
}

.line-icon-Face-Style6:before {
    content: "\e87d"
}

.line-icon-Facebook-2:before {
    content: "\e87e"
}

.line-icon-Facebook:before {
    content: "\e87f"
}

.line-icon-Factory-2:before {
    content: "\e880"
}

.line-icon-Factory:before {
    content: "\e881"
}

.line-icon-Fahrenheit:before {
    content: "\e882"
}

.line-icon-Family-Sign:before {
    content: "\e883"
}

.line-icon-Fan:before {
    content: "\e884"
}

.line-icon-Farmer:before {
    content: "\e885"
}

.line-icon-Fashion:before {
    content: "\e886"
}

.line-icon-Favorite-Window:before {
    content: "\e887"
}

.line-icon-Fax:before {
    content: "\e888"
}

.line-icon-Feather:before {
    content: "\e889"
}

.line-icon-Feedburner:before {
    content: "\e88a"
}

.line-icon-Female-2:before {
    content: "\e88b"
}

.line-icon-Female-Sign:before {
    content: "\e88c"
}

.line-icon-Female:before {
    content: "\e88d"
}

.line-icon-File-Block:before {
    content: "\e88e"
}

.line-icon-File-Bookmark:before {
    content: "\e88f"
}

.line-icon-File-Chart:before {
    content: "\e890"
}

.line-icon-File-Clipboard:before {
    content: "\e891"
}

.line-icon-File-ClipboardFileText:before {
    content: "\e892"
}

.line-icon-File-ClipboardTextImage:before {
    content: "\e893"
}

.line-icon-File-Cloud:before {
    content: "\e894"
}

.line-icon-File-Copy:before {
    content: "\e895"
}

.line-icon-File-Copy2:before {
    content: "\e896"
}

.line-icon-File-CSV:before {
    content: "\e897"
}

.line-icon-File-Download:before {
    content: "\e898"
}

.line-icon-File-Edit:before {
    content: "\e899"
}

.line-icon-File-Excel:before {
    content: "\e89a"
}

.line-icon-File-Favorite:before {
    content: "\e89b"
}

.line-icon-File-Fire:before {
    content: "\e89c"
}

.line-icon-File-Graph:before {
    content: "\e89d"
}

.line-icon-File-Hide:before {
    content: "\e89e"
}

.line-icon-File-Horizontal:before {
    content: "\e89f"
}

.line-icon-File-HorizontalText:before {
    content: "\e8a0"
}

.line-icon-File-HTML:before {
    content: "\e8a1"
}

.line-icon-File-JPG:before {
    content: "\e8a2"
}

.line-icon-File-Link:before {
    content: "\e8a3"
}

.line-icon-File-Loading:before {
    content: "\e8a4"
}

.line-icon-File-Lock:before {
    content: "\e8a5"
}

.line-icon-File-Love:before {
    content: "\e8a6"
}

.line-icon-File-Music:before {
    content: "\e8a7"
}

.line-icon-File-Network:before {
    content: "\e8a8"
}

.line-icon-File-Pictures:before {
    content: "\e8a9"
}

.line-icon-File-Pie:before {
    content: "\e8aa"
}

.line-icon-File-Presentation:before {
    content: "\e8ab"
}

.line-icon-File-Refresh:before {
    content: "\e8ac"
}

.line-icon-File-Search:before {
    content: "\e8ad"
}

.line-icon-File-Settings:before {
    content: "\e8ae"
}

.line-icon-File-Share:before {
    content: "\e8af"
}

.line-icon-File-TextImage:before {
    content: "\e8b0"
}

.line-icon-File-Trash:before {
    content: "\e8b1"
}

.line-icon-File-TXT:before {
    content: "\e8b2"
}

.line-icon-File-Upload:before {
    content: "\e8b3"
}

.line-icon-File-Video:before {
    content: "\e8b4"
}

.line-icon-File-Word:before {
    content: "\e8b5"
}

.line-icon-File-Zip:before {
    content: "\e8b6"
}

.line-icon-File:before {
    content: "\e8b7"
}

.line-icon-Files:before {
    content: "\e8b8"
}

.line-icon-Film-Board:before {
    content: "\e8b9"
}

.line-icon-Film-Cartridge:before {
    content: "\e8ba"
}

.line-icon-Film-Strip:before {
    content: "\e8bb"
}

.line-icon-Film-Video:before {
    content: "\e8bc"
}

.line-icon-Film:before {
    content: "\e8bd"
}

.line-icon-Filter-2:before {
    content: "\e8be"
}

.line-icon-Filter:before {
    content: "\e8bf"
}

.line-icon-Financial:before {
    content: "\e8c0"
}

.line-icon-Find-User:before {
    content: "\e8c1"
}

.line-icon-Finger-DragFourSides:before {
    content: "\e8c2"
}

.line-icon-Finger-DragTwoSides:before {
    content: "\e8c3"
}

.line-icon-Finger-Print:before {
    content: "\e8c4"
}

.line-icon-Finger:before {
    content: "\e8c5"
}

.line-icon-Fingerprint-2:before {
    content: "\e8c6"
}

.line-icon-Fingerprint:before {
    content: "\e8c7"
}

.line-icon-Fire-Flame:before {
    content: "\e8c8"
}

.line-icon-Fire-Flame2:before {
    content: "\e8c9"
}

.line-icon-Fire-Hydrant:before {
    content: "\e8ca"
}

.line-icon-Fire-Staion:before {
    content: "\e8cb"
}

.line-icon-Firefox:before {
    content: "\e8cc"
}

.line-icon-Firewall:before {
    content: "\e8cd"
}

.line-icon-First-Aid:before {
    content: "\e8ce"
}

.line-icon-First:before {
    content: "\e8cf"
}

.line-icon-Fish-Food:before {
    content: "\e8d0"
}

.line-icon-Fish:before {
    content: "\e8d1"
}

.line-icon-Fit-To:before {
    content: "\e8d2"
}

.line-icon-Fit-To2:before {
    content: "\e8d3"
}

.line-icon-Five-Fingers:before {
    content: "\e8d4"
}

.line-icon-Five-FingersDrag:before {
    content: "\e8d5"
}

.line-icon-Five-FingersDrag2:before {
    content: "\e8d6"
}

.line-icon-Five-FingersTouch:before {
    content: "\e8d7"
}

.line-icon-Flag-2:before {
    content: "\e8d8"
}

.line-icon-Flag-3:before {
    content: "\e8d9"
}

.line-icon-Flag-4:before {
    content: "\e8da"
}

.line-icon-Flag-5:before {
    content: "\e8db"
}

.line-icon-Flag-6:before {
    content: "\e8dc"
}

.line-icon-Flag:before {
    content: "\e8dd"
}

.line-icon-Flamingo:before {
    content: "\e8de"
}

.line-icon-Flash-2:before {
    content: "\e8df"
}

.line-icon-Flash-Video:before {
    content: "\e8e0"
}

.line-icon-Flash:before {
    content: "\e8e1"
}

.line-icon-Flashlight:before {
    content: "\e8e2"
}

.line-icon-Flask-2:before {
    content: "\e8e3"
}

.line-icon-Flask:before {
    content: "\e8e4"
}

.line-icon-Flick:before {
    content: "\e8e5"
}

.line-icon-Flickr:before {
    content: "\e8e6"
}

.line-icon-Flowerpot:before {
    content: "\e8e7"
}

.line-icon-Fluorescent:before {
    content: "\e8e8"
}

.line-icon-Fog-Day:before {
    content: "\e8e9"
}

.line-icon-Fog-Night:before {
    content: "\e8ea"
}

.line-icon-Folder-Add:before {
    content: "\e8eb"
}

.line-icon-Folder-Archive:before {
    content: "\e8ec"
}

.line-icon-Folder-Binder:before {
    content: "\e8ed"
}

.line-icon-Folder-Binder2:before {
    content: "\e8ee"
}

.line-icon-Folder-Block:before {
    content: "\e8ef"
}

.line-icon-Folder-Bookmark:before {
    content: "\e8f0"
}

.line-icon-Folder-Close:before {
    content: "\e8f1"
}

.line-icon-Folder-Cloud:before {
    content: "\e8f2"
}

.line-icon-Folder-Delete:before {
    content: "\e8f3"
}

.line-icon-Folder-Download:before {
    content: "\e8f4"
}

.line-icon-Folder-Edit:before {
    content: "\e8f5"
}

.line-icon-Folder-Favorite:before {
    content: "\e8f6"
}

.line-icon-Folder-Fire:before {
    content: "\e8f7"
}

.line-icon-Folder-Hide:before {
    content: "\e8f8"
}

.line-icon-Folder-Link:before {
    content: "\e8f9"
}

.line-icon-Folder-Loading:before {
    content: "\e8fa"
}

.line-icon-Folder-Lock:before {
    content: "\e8fb"
}

.line-icon-Folder-Love:before {
    content: "\e8fc"
}

.line-icon-Folder-Music:before {
    content: "\e8fd"
}

.line-icon-Folder-Network:before {
    content: "\e8fe"
}

.line-icon-Folder-Open:before {
    content: "\e8ff"
}

.line-icon-Folder-Open2:before {
    content: "\e900"
}

.line-icon-Folder-Organizing:before {
    content: "\e901"
}

.line-icon-Folder-Pictures:before {
    content: "\e902"
}

.line-icon-Folder-Refresh:before {
    content: "\e903"
}

.line-icon-Folder-Remove-:before {
    content: "\e904"
}

.line-icon-Folder-Search:before {
    content: "\e905"
}

.line-icon-Folder-Settings:before {
    content: "\e906"
}

.line-icon-Folder-Share:before {
    content: "\e907"
}

.line-icon-Folder-Trash:before {
    content: "\e908"
}

.line-icon-Folder-Upload:before {
    content: "\e909"
}

.line-icon-Folder-Video:before {
    content: "\e90a"
}

.line-icon-Folder-WithDocument:before {
    content: "\e90b"
}

.line-icon-Folder-Zip:before {
    content: "\e90c"
}

.line-icon-Folder:before {
    content: "\e90d"
}

.line-icon-Folders:before {
    content: "\e90e"
}

.line-icon-Font-Color:before {
    content: "\e90f"
}

.line-icon-Font-Name:before {
    content: "\e910"
}

.line-icon-Font-Size:before {
    content: "\e911"
}

.line-icon-Font-Style:before {
    content: "\e912"
}

.line-icon-Font-StyleSubscript:before {
    content: "\e913"
}

.line-icon-Font-StyleSuperscript:before {
    content: "\e914"
}

.line-icon-Font-Window:before {
    content: "\e915"
}

.line-icon-Foot-2:before {
    content: "\e916"
}

.line-icon-Foot:before {
    content: "\e917"
}

.line-icon-Football-2:before {
    content: "\e918"
}

.line-icon-Football:before {
    content: "\e919"
}

.line-icon-Footprint-2:before {
    content: "\e91a"
}

.line-icon-Footprint-3:before {
    content: "\e91b"
}

.line-icon-Footprint:before {
    content: "\e91c"
}

.line-icon-Forest:before {
    content: "\e91d"
}

.line-icon-Fork:before {
    content: "\e91e"
}

.line-icon-Formspring:before {
    content: "\e91f"
}

.line-icon-Formula:before {
    content: "\e920"
}

.line-icon-Forsquare:before {
    content: "\e921"
}

.line-icon-Forward:before {
    content: "\e922"
}

.line-icon-Fountain-Pen:before {
    content: "\e923"
}

.line-icon-Four-Fingers:before {
    content: "\e924"
}

.line-icon-Four-FingersDrag:before {
    content: "\e925"
}

.line-icon-Four-FingersDrag2:before {
    content: "\e926"
}

.line-icon-Four-FingersTouch:before {
    content: "\e927"
}

.line-icon-Fox:before {
    content: "\e928"
}

.line-icon-Frankenstein:before {
    content: "\e929"
}

.line-icon-French-Fries:before {
    content: "\e92a"
}

.line-icon-Friendfeed:before {
    content: "\e92b"
}

.line-icon-Friendster:before {
    content: "\e92c"
}

.line-icon-Frog:before {
    content: "\e92d"
}

.line-icon-Fruits:before {
    content: "\e92e"
}

.line-icon-Fuel:before {
    content: "\e92f"
}

.line-icon-Full-Bag:before {
    content: "\e930"
}

.line-icon-Full-Basket:before {
    content: "\e931"
}

.line-icon-Full-Cart:before {
    content: "\e932"
}

.line-icon-Full-Moon:before {
    content: "\e933"
}

.line-icon-Full-Screen:before {
    content: "\e934"
}

.line-icon-Full-Screen2:before {
    content: "\e935"
}

.line-icon-Full-View:before {
    content: "\e936"
}

.line-icon-Full-View2:before {
    content: "\e937"
}

.line-icon-Full-ViewWindow:before {
    content: "\e938"
}

.line-icon-Function:before {
    content: "\e939"
}

.line-icon-Funky:before {
    content: "\e93a"
}

.line-icon-Funny-Bicycle:before {
    content: "\e93b"
}

.line-icon-Furl:before {
    content: "\e93c"
}

.line-icon-Gamepad-2:before {
    content: "\e93d"
}

.line-icon-Gamepad:before {
    content: "\e93e"
}

.line-icon-Gas-Pump:before {
    content: "\e93f"
}

.line-icon-Gaugage-2:before {
    content: "\e940"
}

.line-icon-Gaugage:before {
    content: "\e941"
}

.line-icon-Gay:before {
    content: "\e942"
}

.line-icon-Gear-2:before {
    content: "\e943"
}

.line-icon-Gear:before {
    content: "\e944"
}

.line-icon-Gears-2:before {
    content: "\e945"
}

.line-icon-Gears:before {
    content: "\e946"
}

.line-icon-Geek-2:before {
    content: "\e947"
}

.line-icon-Geek:before {
    content: "\e948"
}

.line-icon-Gemini-2:before {
    content: "\e949"
}

.line-icon-Gemini:before {
    content: "\e94a"
}

.line-icon-Genius:before {
    content: "\e94b"
}

.line-icon-Gentleman:before {
    content: "\e94c"
}

.line-icon-Geo--:before {
    content: "\e94d"
}

.line-icon-Geo-:before {
    content: "\e94e"
}

.line-icon-Geo-Close:before {
    content: "\e94f"
}

.line-icon-Geo-Love:before {
    content: "\e950"
}

.line-icon-Geo-Number:before {
    content: "\e951"
}

.line-icon-Geo-Star:before {
    content: "\e952"
}

.line-icon-Geo:before {
    content: "\e953"
}

.line-icon-Geo2--:before {
    content: "\e954"
}

.line-icon-Geo2-:before {
    content: "\e955"
}

.line-icon-Geo2-Close:before {
    content: "\e956"
}

.line-icon-Geo2-Love:before {
    content: "\e957"
}

.line-icon-Geo2-Number:before {
    content: "\e958"
}

.line-icon-Geo2-Star:before {
    content: "\e959"
}

.line-icon-Geo2:before {
    content: "\e95a"
}

.line-icon-Geo3--:before {
    content: "\e95b"
}

.line-icon-Geo3-:before {
    content: "\e95c"
}

.line-icon-Geo3-Close:before {
    content: "\e95d"
}

.line-icon-Geo3-Love:before {
    content: "\e95e"
}

.line-icon-Geo3-Number:before {
    content: "\e95f"
}

.line-icon-Geo3-Star:before {
    content: "\e960"
}

.line-icon-Geo3:before {
    content: "\e961"
}

.line-icon-Gey:before {
    content: "\e962"
}

.line-icon-Gift-Box:before {
    content: "\e963"
}

.line-icon-Giraffe:before {
    content: "\e964"
}

.line-icon-Girl:before {
    content: "\e965"
}

.line-icon-Glass-Water:before {
    content: "\e966"
}

.line-icon-Glasses-2:before {
    content: "\e967"
}

.line-icon-Glasses-3:before {
    content: "\e968"
}

.line-icon-Glasses:before {
    content: "\e969"
}

.line-icon-Global-Position:before {
    content: "\e96a"
}

.line-icon-Globe-2:before {
    content: "\e96b"
}

.line-icon-Globe:before {
    content: "\e96c"
}

.line-icon-Gloves:before {
    content: "\e96d"
}

.line-icon-Go-Bottom:before {
    content: "\e96e"
}

.line-icon-Go-Top:before {
    content: "\e96f"
}

.line-icon-Goggles:before {
    content: "\e970"
}

.line-icon-Golf-2:before {
    content: "\e971"
}

.line-icon-Golf:before {
    content: "\e972"
}

.line-icon-Google-Buzz:before {
    content: "\e973"
}

.line-icon-Google-Drive:before {
    content: "\e974"
}

.line-icon-Google-Play:before {
    content: "\e975"
}

.line-icon-Google-Plus:before {
    content: "\e976"
}

.line-icon-Google:before {
    content: "\e977"
}

.line-icon-Gopro:before {
    content: "\e978"
}

.line-icon-Gorilla:before {
    content: "\e979"
}

.line-icon-Gowalla:before {
    content: "\e97a"
}

.line-icon-Grave:before {
    content: "\e97b"
}

.line-icon-Graveyard:before {
    content: "\e97c"
}

.line-icon-Greece:before {
    content: "\e97d"
}

.line-icon-Green-Energy:before {
    content: "\e97e"
}

.line-icon-Green-House:before {
    content: "\e97f"
}

.line-icon-Guitar:before {
    content: "\e980"
}

.line-icon-Gun-2:before {
    content: "\e981"
}

.line-icon-Gun-3:before {
    content: "\e982"
}

.line-icon-Gun:before {
    content: "\e983"
}

.line-icon-Gymnastics:before {
    content: "\e984"
}

.line-icon-Hair-2:before {
    content: "\e985"
}

.line-icon-Hair-3:before {
    content: "\e986"
}

.line-icon-Hair-4:before {
    content: "\e987"
}

.line-icon-Hair:before {
    content: "\e988"
}

.line-icon-Half-Moon:before {
    content: "\e989"
}

.line-icon-Halloween-HalfMoon:before {
    content: "\e98a"
}

.line-icon-Halloween-Moon:before {
    content: "\e98b"
}

.line-icon-Hamburger:before {
    content: "\e98c"
}

.line-icon-Hammer:before {
    content: "\e98d"
}

.line-icon-Hand-Touch:before {
    content: "\e98e"
}

.line-icon-Hand-Touch2:before {
    content: "\e98f"
}

.line-icon-Hand-TouchSmartphone:before {
    content: "\e990"
}

.line-icon-Hand:before {
    content: "\e991"
}

.line-icon-Hands:before {
    content: "\e992"
}

.line-icon-Handshake:before {
    content: "\e993"
}

.line-icon-Hanger:before {
    content: "\e994"
}

.line-icon-Happy:before {
    content: "\e995"
}

.line-icon-Hat-2:before {
    content: "\e996"
}

.line-icon-Hat:before {
    content: "\e997"
}

.line-icon-Haunted-House:before {
    content: "\e998"
}

.line-icon-HD-Video:before {
    content: "\e999"
}

.line-icon-HD:before {
    content: "\e99a"
}

.line-icon-HDD:before {
    content: "\e99b"
}

.line-icon-Headphone:before {
    content: "\e99c"
}

.line-icon-Headphones:before {
    content: "\e99d"
}

.line-icon-Headset:before {
    content: "\e99e"
}

.line-icon-Heart-2:before {
    content: "\e99f"
}

.line-icon-Heart:before {
    content: "\e9a0"
}

.line-icon-Heels-2:before {
    content: "\e9a1"
}

.line-icon-Heels:before {
    content: "\e9a2"
}

.line-icon-Height-Window:before {
    content: "\e9a3"
}

.line-icon-Helicopter-2:before {
    content: "\e9a4"
}

.line-icon-Helicopter:before {
    content: "\e9a5"
}

.line-icon-Helix-2:before {
    content: "\e9a6"
}

.line-icon-Hello:before {
    content: "\e9a7"
}

.line-icon-Helmet-2:before {
    content: "\e9a8"
}

.line-icon-Helmet-3:before {
    content: "\e9a9"
}

.line-icon-Helmet:before {
    content: "\e9aa"
}

.line-icon-Hipo:before {
    content: "\e9ab"
}

.line-icon-Hipster-Glasses:before {
    content: "\e9ac"
}

.line-icon-Hipster-Glasses2:before {
    content: "\e9ad"
}

.line-icon-Hipster-Glasses3:before {
    content: "\e9ae"
}

.line-icon-Hipster-Headphones:before {
    content: "\e9af"
}

.line-icon-Hipster-Men:before {
    content: "\e9b0"
}

.line-icon-Hipster-Men2:before {
    content: "\e9b1"
}

.line-icon-Hipster-Men3:before {
    content: "\e9b2"
}

.line-icon-Hipster-Sunglasses:before {
    content: "\e9b3"
}

.line-icon-Hipster-Sunglasses2:before {
    content: "\e9b4"
}

.line-icon-Hipster-Sunglasses3:before {
    content: "\e9b5"
}

.line-icon-Hokey:before {
    content: "\e9b6"
}

.line-icon-Holly:before {
    content: "\e9b7"
}

.line-icon-Home-2:before {
    content: "\e9b8"
}

.line-icon-Home-3:before {
    content: "\e9b9"
}

.line-icon-Home-4:before {
    content: "\e9ba"
}

.line-icon-Home-5:before {
    content: "\e9bb"
}

.line-icon-Home-Window:before {
    content: "\e9bc"
}

.line-icon-Home:before {
    content: "\e9bd"
}

.line-icon-Homosexual:before {
    content: "\e9be"
}

.line-icon-Honey:before {
    content: "\e9bf"
}

.line-icon-Hong-Kong:before {
    content: "\e9c0"
}

.line-icon-Hoodie:before {
    content: "\e9c1"
}

.line-icon-Horror:before {
    content: "\e9c2"
}

.line-icon-Horse:before {
    content: "\e9c3"
}

.line-icon-Hospital-2:before {
    content: "\e9c4"
}

.line-icon-Hospital:before {
    content: "\e9c5"
}

.line-icon-Host:before {
    content: "\e9c6"
}

.line-icon-Hot-Dog:before {
    content: "\e9c7"
}

.line-icon-Hotel:before {
    content: "\e9c8"
}

.line-icon-Hour:before {
    content: "\e9c9"
}

.line-icon-Hub:before {
    content: "\e9ca"
}

.line-icon-Humor:before {
    content: "\e9cb"
}

.line-icon-Hurt:before {
    content: "\e9cc"
}

.line-icon-Ice-Cream:before {
    content: "\e9cd"
}

.line-icon-ICQ:before {
    content: "\e9ce"
}

.line-icon-ID-2:before {
    content: "\e9cf"
}

.line-icon-ID-3:before {
    content: "\e9d0"
}

.line-icon-ID-Card:before {
    content: "\e9d1"
}

.line-icon-Idea-2:before {
    content: "\e9d2"
}

.line-icon-Idea-3:before {
    content: "\e9d3"
}

.line-icon-Idea-4:before {
    content: "\e9d4"
}

.line-icon-Idea-5:before {
    content: "\e9d5"
}

.line-icon-Idea:before {
    content: "\e9d6"
}

.line-icon-Identification-Badge:before {
    content: "\e9d7"
}

.line-icon-ImDB:before {
    content: "\e9d8"
}

.line-icon-Inbox-Empty:before {
    content: "\e9d9"
}

.line-icon-Inbox-Forward:before {
    content: "\e9da"
}

.line-icon-Inbox-Full:before {
    content: "\e9db"
}

.line-icon-Inbox-Into:before {
    content: "\e9dc"
}

.line-icon-Inbox-Out:before {
    content: "\e9dd"
}

.line-icon-Inbox-Reply:before {
    content: "\e9de"
}

.line-icon-Inbox:before {
    content: "\e9df"
}

.line-icon-Increase-Inedit:before {
    content: "\e9e0"
}

.line-icon-Indent-FirstLine:before {
    content: "\e9e1"
}

.line-icon-Indent-LeftMargin:before {
    content: "\e9e2"
}

.line-icon-Indent-RightMargin:before {
    content: "\e9e3"
}

.line-icon-India:before {
    content: "\e9e4"
}

.line-icon-Info-Window:before {
    content: "\e9e5"
}

.line-icon-Information:before {
    content: "\e9e6"
}

.line-icon-Inifity:before {
    content: "\e9e7"
}

.line-icon-Instagram:before {
    content: "\e9e8"
}

.line-icon-Internet-2:before {
    content: "\e9e9"
}

.line-icon-Internet-Explorer:before {
    content: "\e9ea"
}

.line-icon-Internet-Smiley:before {
    content: "\e9eb"
}

.line-icon-Internet:before {
    content: "\e9ec"
}

.line-icon-iOS-Apple:before {
    content: "\e9ed"
}

.line-icon-Israel:before {
    content: "\e9ee"
}

.line-icon-Italic-Text:before {
    content: "\e9ef"
}

.line-icon-Jacket-2:before {
    content: "\e9f0"
}

.line-icon-Jacket:before {
    content: "\e9f1"
}

.line-icon-Jamaica:before {
    content: "\e9f2"
}

.line-icon-Japan:before {
    content: "\e9f3"
}

.line-icon-Japanese-Gate:before {
    content: "\e9f4"
}

.line-icon-Jeans:before {
    content: "\e9f5"
}

.line-icon-Jeep-2:before {
    content: "\e9f6"
}

.line-icon-Jeep:before {
    content: "\e9f7"
}

.line-icon-Jet:before {
    content: "\e9f8"
}

.line-icon-Joystick:before {
    content: "\e9f9"
}

.line-icon-Juice:before {
    content: "\e9fa"
}

.line-icon-Jump-Rope:before {
    content: "\e9fb"
}

.line-icon-Kangoroo:before {
    content: "\e9fc"
}

.line-icon-Kenya:before {
    content: "\e9fd"
}

.line-icon-Key-2:before {
    content: "\e9fe"
}

.line-icon-Key-3:before {
    content: "\e9ff"
}

.line-icon-Key-Lock:before {
    content: "\ea00"
}

.line-icon-Key:before {
    content: "\ea01"
}

.line-icon-Keyboard:before {
    content: "\ea02"
}

.line-icon-Keyboard3:before {
    content: "\ea03"
}

.line-icon-Keypad:before {
    content: "\ea04"
}

.line-icon-King-2:before {
    content: "\ea05"
}

.line-icon-King:before {
    content: "\ea06"
}

.line-icon-Kiss:before {
    content: "\ea07"
}

.line-icon-Knee:before {
    content: "\ea08"
}

.line-icon-Knife-2:before {
    content: "\ea09"
}

.line-icon-Knife:before {
    content: "\ea0a"
}

.line-icon-Knight:before {
    content: "\ea0b"
}

.line-icon-Koala:before {
    content: "\ea0c"
}

.line-icon-Korea:before {
    content: "\ea0d"
}

.line-icon-Lamp:before {
    content: "\ea0e"
}

.line-icon-Landscape-2:before {
    content: "\ea0f"
}

.line-icon-Landscape:before {
    content: "\ea10"
}

.line-icon-Lantern:before {
    content: "\ea11"
}

.line-icon-Laptop-2:before {
    content: "\ea12"
}

.line-icon-Laptop-3:before {
    content: "\ea13"
}

.line-icon-Laptop-Phone:before {
    content: "\ea14"
}

.line-icon-Laptop-Secure:before {
    content: "\ea15"
}

.line-icon-Laptop-Tablet:before {
    content: "\ea16"
}

.line-icon-Laptop:before {
    content: "\ea17"
}

.line-icon-Laser:before {
    content: "\ea18"
}

.line-icon-Last-FM:before {
    content: "\ea19"
}

.line-icon-Last:before {
    content: "\ea1a"
}

.line-icon-Laughing:before {
    content: "\ea1b"
}

.line-icon-Layer-1635:before {
    content: "\ea1c"
}

.line-icon-Layer-1646:before {
    content: "\ea1d"
}

.line-icon-Layer-Backward:before {
    content: "\ea1e"
}

.line-icon-Layer-Forward:before {
    content: "\ea1f"
}

.line-icon-Leafs-2:before {
    content: "\ea20"
}

.line-icon-Leafs:before {
    content: "\ea21"
}

.line-icon-Leaning-Tower:before {
    content: "\ea22"
}

.line-icon-Left--Right:before {
    content: "\ea23"
}

.line-icon-Left--Right3:before {
    content: "\ea24"
}

.line-icon-Left-2:before {
    content: "\ea25"
}

.line-icon-Left-3:before {
    content: "\ea26"
}

.line-icon-Left-4:before {
    content: "\ea27"
}

.line-icon-Left-ToRight:before {
    content: "\ea28"
}

.line-icon-Left:before {
    content: "\ea29"
}

.line-icon-Leg-2:before {
    content: "\ea2a"
}

.line-icon-Leg:before {
    content: "\ea2b"
}

.line-icon-Lego:before {
    content: "\ea2c"
}

.line-icon-Lemon:before {
    content: "\ea2d"
}

.line-icon-Len-2:before {
    content: "\ea2e"
}

.line-icon-Len-3:before {
    content: "\ea2f"
}

.line-icon-Len:before {
    content: "\ea30"
}

.line-icon-Leo-2:before {
    content: "\ea31"
}

.line-icon-Leo:before {
    content: "\ea32"
}

.line-icon-Leopard:before {
    content: "\ea33"
}

.line-icon-Lesbian:before {
    content: "\ea34"
}

.line-icon-Lesbians:before {
    content: "\ea35"
}

.line-icon-Letter-Close:before {
    content: "\ea36"
}

.line-icon-Letter-Open:before {
    content: "\ea37"
}

.line-icon-Letter-Sent:before {
    content: "\ea38"
}

.line-icon-Libra-2:before {
    content: "\ea39"
}

.line-icon-Libra:before {
    content: "\ea3a"
}

.line-icon-Library-2:before {
    content: "\ea3b"
}

.line-icon-Library:before {
    content: "\ea3c"
}

.line-icon-Life-Jacket:before {
    content: "\ea3d"
}

.line-icon-Life-Safer:before {
    content: "\ea3e"
}

.line-icon-Light-Bulb:before {
    content: "\ea3f"
}

.line-icon-Light-Bulb2:before {
    content: "\ea40"
}

.line-icon-Light-BulbLeaf:before {
    content: "\ea41"
}

.line-icon-Lighthouse:before {
    content: "\ea42"
}

.line-icon-Like-2:before {
    content: "\ea43"
}

.line-icon-Like:before {
    content: "\ea44"
}

.line-icon-Line-Chart:before {
    content: "\ea45"
}

.line-icon-Line-Chart2:before {
    content: "\ea46"
}

.line-icon-Line-Chart3:before {
    content: "\ea47"
}

.line-icon-Line-Chart4:before {
    content: "\ea48"
}

.line-icon-Line-Spacing:before {
    content: "\ea49"
}

.line-icon-Line-SpacingText:before {
    content: "\ea4a"
}

.line-icon-Link-2:before {
    content: "\ea4b"
}

.line-icon-Link:before {
    content: "\ea4c"
}

.line-icon-Linkedin-2:before {
    content: "\ea4d"
}

.line-icon-Linkedin:before {
    content: "\ea4e"
}

.line-icon-Linux:before {
    content: "\ea4f"
}

.line-icon-Lion:before {
    content: "\ea50"
}

.line-icon-Livejournal:before {
    content: "\ea51"
}

.line-icon-Loading-2:before {
    content: "\ea52"
}

.line-icon-Loading-3:before {
    content: "\ea53"
}

.line-icon-Loading-Window:before {
    content: "\ea54"
}

.line-icon-Loading:before {
    content: "\ea55"
}

.line-icon-Location-2:before {
    content: "\ea56"
}

.line-icon-Location:before {
    content: "\ea57"
}

.line-icon-Lock-2:before {
    content: "\ea58"
}

.line-icon-Lock-3:before {
    content: "\ea59"
}

.line-icon-Lock-User:before {
    content: "\ea5a"
}

.line-icon-Lock-Window:before {
    content: "\ea5b"
}

.line-icon-Lock:before {
    content: "\ea5c"
}

.line-icon-Lollipop-2:before {
    content: "\ea5d"
}

.line-icon-Lollipop-3:before {
    content: "\ea5e"
}

.line-icon-Lollipop:before {
    content: "\ea5f"
}

.line-icon-Loop:before {
    content: "\ea60"
}

.line-icon-Loud:before {
    content: "\ea61"
}

.line-icon-Loudspeaker:before {
    content: "\ea62"
}

.line-icon-Love-2:before {
    content: "\ea63"
}

.line-icon-Love-User:before {
    content: "\ea64"
}

.line-icon-Love-Window:before {
    content: "\ea65"
}

.line-icon-Love:before {
    content: "\ea66"
}

.line-icon-Lowercase-Text:before {
    content: "\ea67"
}

.line-icon-Luggafe-Front:before {
    content: "\ea68"
}

.line-icon-Luggage-2:before {
    content: "\ea69"
}

.line-icon-Macro:before {
    content: "\ea6a"
}

.line-icon-Magic-Wand:before {
    content: "\ea6b"
}

.line-icon-Magnet:before {
    content: "\ea6c"
}

.line-icon-Magnifi-Glass-:before {
    content: "\ea6d"
}

.line-icon-Magnifi-Glass:before {
    content: "\ea6e"
}

.line-icon-Magnifi-Glass2:before {
    content: "\ea6f"
}

.line-icon-Mail-2:before {
    content: "\ea70"
}

.line-icon-Mail-3:before {
    content: "\ea71"
}

.line-icon-Mail-Add:before {
    content: "\ea72"
}

.line-icon-Mail-Attachement:before {
    content: "\ea73"
}

.line-icon-Mail-Block:before {
    content: "\ea74"
}

.line-icon-Mail-Delete:before {
    content: "\ea75"
}

.line-icon-Mail-Favorite:before {
    content: "\ea76"
}

.line-icon-Mail-Forward:before {
    content: "\ea77"
}

.line-icon-Mail-Gallery:before {
    content: "\ea78"
}

.line-icon-Mail-Inbox:before {
    content: "\ea79"
}

.line-icon-Mail-Link:before {
    content: "\ea7a"
}

.line-icon-Mail-Lock:before {
    content: "\ea7b"
}

.line-icon-Mail-Love:before {
    content: "\ea7c"
}

.line-icon-Mail-Money:before {
    content: "\ea7d"
}

.line-icon-Mail-Open:before {
    content: "\ea7e"
}

.line-icon-Mail-Outbox:before {
    content: "\ea7f"
}

.line-icon-Mail-Password:before {
    content: "\ea80"
}

.line-icon-Mail-Photo:before {
    content: "\ea81"
}

.line-icon-Mail-Read:before {
    content: "\ea82"
}

.line-icon-Mail-Removex:before {
    content: "\ea83"
}

.line-icon-Mail-Reply:before {
    content: "\ea84"
}

.line-icon-Mail-ReplyAll:before {
    content: "\ea85"
}

.line-icon-Mail-Search:before {
    content: "\ea86"
}

.line-icon-Mail-Send:before {
    content: "\ea87"
}

.line-icon-Mail-Settings:before {
    content: "\ea88"
}

.line-icon-Mail-Unread:before {
    content: "\ea89"
}

.line-icon-Mail-Video:before {
    content: "\ea8a"
}

.line-icon-Mail-withAtSign:before {
    content: "\ea8b"
}

.line-icon-Mail-WithCursors:before {
    content: "\ea8c"
}

.line-icon-Mail:before {
    content: "\ea8d"
}

.line-icon-Mailbox-Empty:before {
    content: "\ea8e"
}

.line-icon-Mailbox-Full:before {
    content: "\ea8f"
}

.line-icon-Male-2:before {
    content: "\ea90"
}

.line-icon-Male-Sign:before {
    content: "\ea91"
}

.line-icon-Male:before {
    content: "\ea92"
}

.line-icon-MaleFemale:before {
    content: "\ea93"
}

.line-icon-Man-Sign:before {
    content: "\ea94"
}

.line-icon-Management:before {
    content: "\ea95"
}

.line-icon-Mans-Underwear:before {
    content: "\ea96"
}

.line-icon-Mans-Underwear2:before {
    content: "\ea97"
}

.line-icon-Map-Marker:before {
    content: "\ea98"
}

.line-icon-Map-Marker2:before {
    content: "\ea99"
}

.line-icon-Map-Marker3:before {
    content: "\ea9a"
}

.line-icon-Map:before {
    content: "\ea9b"
}

.line-icon-Map2:before {
    content: "\ea9c"
}

.line-icon-Marker-2:before {
    content: "\ea9d"
}

.line-icon-Marker-3:before {
    content: "\ea9e"
}

.line-icon-Marker:before {
    content: "\ea9f"
}

.line-icon-Martini-Glass:before {
    content: "\eaa0"
}

.line-icon-Mask:before {
    content: "\eaa1"
}

.line-icon-Master-Card:before {
    content: "\eaa2"
}

.line-icon-Maximize-Window:before {
    content: "\eaa3"
}

.line-icon-Maximize:before {
    content: "\eaa4"
}

.line-icon-Medal-2:before {
    content: "\eaa5"
}

.line-icon-Medal-3:before {
    content: "\eaa6"
}

.line-icon-Medal:before {
    content: "\eaa7"
}

.line-icon-Medical-Sign:before {
    content: "\eaa8"
}

.line-icon-Medicine-2:before {
    content: "\eaa9"
}

.line-icon-Medicine-3:before {
    content: "\eaaa"
}

.line-icon-Medicine:before {
    content: "\eaab"
}

.line-icon-Megaphone:before {
    content: "\eaac"
}

.line-icon-Memory-Card:before {
    content: "\eaad"
}

.line-icon-Memory-Card2:before {
    content: "\eaae"
}

.line-icon-Memory-Card3:before {
    content: "\eaaf"
}

.line-icon-Men:before {
    content: "\eab0"
}

.line-icon-Menorah:before {
    content: "\eab1"
}

.line-icon-Mens:before {
    content: "\eab2"
}

.line-icon-Metacafe:before {
    content: "\eab3"
}

.line-icon-Mexico:before {
    content: "\eab4"
}

.line-icon-Mic:before {
    content: "\eab5"
}

.line-icon-Microphone-2:before {
    content: "\eab6"
}

.line-icon-Microphone-3:before {
    content: "\eab7"
}

.line-icon-Microphone-4:before {
    content: "\eab8"
}

.line-icon-Microphone-5:before {
    content: "\eab9"
}

.line-icon-Microphone-6:before {
    content: "\eaba"
}

.line-icon-Microphone-7:before {
    content: "\eabb"
}

.line-icon-Microphone:before {
    content: "\eabc"
}

.line-icon-Microscope:before {
    content: "\eabd"
}

.line-icon-Milk-Bottle:before {
    content: "\eabe"
}

.line-icon-Mine:before {
    content: "\eabf"
}

.line-icon-Minimize-Maximize-Close-Window:before {
    content: "\eac0"
}

.line-icon-Minimize-Window:before {
    content: "\eac1"
}

.line-icon-Minimize:before {
    content: "\eac2"
}

.line-icon-Mirror:before {
    content: "\eac3"
}

.line-icon-Mixer:before {
    content: "\eac4"
}

.line-icon-Mixx:before {
    content: "\eac5"
}

.line-icon-Money-2:before {
    content: "\eac6"
}

.line-icon-Money-Bag:before {
    content: "\eac7"
}

.line-icon-Money-Smiley:before {
    content: "\eac8"
}

.line-icon-Money:before {
    content: "\eac9"
}

.line-icon-Monitor-2:before {
    content: "\eaca"
}

.line-icon-Monitor-3:before {
    content: "\eacb"
}

.line-icon-Monitor-4:before {
    content: "\eacc"
}

.line-icon-Monitor-5:before {
    content: "\eacd"
}

.line-icon-Monitor-Analytics:before {
    content: "\eace"
}

.line-icon-Monitor-Laptop:before {
    content: "\eacf"
}

.line-icon-Monitor-phone:before {
    content: "\ead0"
}

.line-icon-Monitor-Tablet:before {
    content: "\ead1"
}

.line-icon-Monitor-Vertical:before {
    content: "\ead2"
}

.line-icon-Monitor:before {
    content: "\ead3"
}

.line-icon-Monitoring:before {
    content: "\ead4"
}

.line-icon-Monkey:before {
    content: "\ead5"
}

.line-icon-Monster:before {
    content: "\ead6"
}

.line-icon-Morocco:before {
    content: "\ead7"
}

.line-icon-Motorcycle:before {
    content: "\ead8"
}

.line-icon-Mouse-2:before {
    content: "\ead9"
}

.line-icon-Mouse-3:before {
    content: "\eada"
}

.line-icon-Mouse-4:before {
    content: "\eadb"
}

.line-icon-Mouse-Pointer:before {
    content: "\eadc"
}

.line-icon-Mouse:before {
    content: "\eadd"
}

.line-icon-Moustache-Smiley:before {
    content: "\eade"
}

.line-icon-Movie-Ticket:before {
    content: "\eadf"
}

.line-icon-Movie:before {
    content: "\eae0"
}

.line-icon-Mp3-File:before {
    content: "\eae1"
}

.line-icon-Museum:before {
    content: "\eae2"
}

.line-icon-Mushroom:before {
    content: "\eae3"
}

.line-icon-Music-Note:before {
    content: "\eae4"
}

.line-icon-Music-Note2:before {
    content: "\eae5"
}

.line-icon-Music-Note3:before {
    content: "\eae6"
}

.line-icon-Music-Note4:before {
    content: "\eae7"
}

.line-icon-Music-Player:before {
    content: "\eae8"
}

.line-icon-Mustache-2:before {
    content: "\eae9"
}

.line-icon-Mustache-3:before {
    content: "\eaea"
}

.line-icon-Mustache-4:before {
    content: "\eaeb"
}

.line-icon-Mustache-5:before {
    content: "\eaec"
}

.line-icon-Mustache-6:before {
    content: "\eaed"
}

.line-icon-Mustache-7:before {
    content: "\eaee"
}

.line-icon-Mustache-8:before {
    content: "\eaef"
}

.line-icon-Mustache:before {
    content: "\eaf0"
}

.line-icon-Mute:before {
    content: "\eaf1"
}

.line-icon-Myspace:before {
    content: "\eaf2"
}

.line-icon-Navigat-Start:before {
    content: "\eaf3"
}

.line-icon-Navigate-End:before {
    content: "\eaf4"
}

.line-icon-Navigation-LeftWindow:before {
    content: "\eaf5"
}

.line-icon-Navigation-RightWindow:before {
    content: "\eaf6"
}

.line-icon-Nepal:before {
    content: "\eaf7"
}

.line-icon-Netscape:before {
    content: "\eaf8"
}

.line-icon-Network-Window:before {
    content: "\eaf9"
}

.line-icon-Network:before {
    content: "\eafa"
}

.line-icon-Neutron:before {
    content: "\eafb"
}

.line-icon-New-Mail:before {
    content: "\eafc"
}

.line-icon-New-Tab:before {
    content: "\eafd"
}

.line-icon-Newspaper-2:before {
    content: "\eafe"
}

.line-icon-Newspaper:before {
    content: "\eaff"
}

.line-icon-Newsvine:before {
    content: "\eb00"
}

.line-icon-Next2:before {
    content: "\eb01"
}

.line-icon-Next-3:before {
    content: "\eb02"
}

.line-icon-Next-Music:before {
    content: "\eb03"
}

.line-icon-Next:before {
    content: "\eb04"
}

.line-icon-No-Battery:before {
    content: "\eb05"
}

.line-icon-No-Drop:before {
    content: "\eb06"
}

.line-icon-No-Flash:before {
    content: "\eb07"
}

.line-icon-No-Smoking:before {
    content: "\eb08"
}

.line-icon-Noose:before {
    content: "\eb09"
}

.line-icon-Normal-Text:before {
    content: "\eb0a"
}

.line-icon-Note:before {
    content: "\eb0b"
}

.line-icon-Notepad-2:before {
    content: "\eb0c"
}

.line-icon-Notepad:before {
    content: "\eb0d"
}

.line-icon-Nuclear:before {
    content: "\eb0e"
}

.line-icon-Numbering-List:before {
    content: "\eb0f"
}

.line-icon-Nurse:before {
    content: "\eb10"
}

.line-icon-Office-Lamp:before {
    content: "\eb11"
}

.line-icon-Office:before {
    content: "\eb12"
}

.line-icon-Oil:before {
    content: "\eb13"
}

.line-icon-Old-Camera:before {
    content: "\eb14"
}

.line-icon-Old-Cassette:before {
    content: "\eb15"
}

.line-icon-Old-Clock:before {
    content: "\eb16"
}

.line-icon-Old-Radio:before {
    content: "\eb17"
}

.line-icon-Old-Sticky:before {
    content: "\eb18"
}

.line-icon-Old-Sticky2:before {
    content: "\eb19"
}

.line-icon-Old-Telephone:before {
    content: "\eb1a"
}

.line-icon-Old-TV:before {
    content: "\eb1b"
}

.line-icon-On-Air:before {
    content: "\eb1c"
}

.line-icon-On-Off-2:before {
    content: "\eb1d"
}

.line-icon-On-Off-3:before {
    content: "\eb1e"
}

.line-icon-On-off:before {
    content: "\eb1f"
}

.line-icon-One-Finger:before {
    content: "\eb20"
}

.line-icon-One-FingerTouch:before {
    content: "\eb21"
}

.line-icon-One-Window:before {
    content: "\eb22"
}

.line-icon-Open-Banana:before {
    content: "\eb23"
}

.line-icon-Open-Book:before {
    content: "\eb24"
}

.line-icon-Opera-House:before {
    content: "\eb25"
}

.line-icon-Opera:before {
    content: "\eb26"
}

.line-icon-Optimization:before {
    content: "\eb27"
}

.line-icon-Orientation-2:before {
    content: "\eb28"
}

.line-icon-Orientation-3:before {
    content: "\eb29"
}

.line-icon-Orientation:before {
    content: "\eb2a"
}

.line-icon-Orkut:before {
    content: "\eb2b"
}

.line-icon-Ornament:before {
    content: "\eb2c"
}

.line-icon-Over-Time:before {
    content: "\eb2d"
}

.line-icon-Over-Time2:before {
    content: "\eb2e"
}

.line-icon-Owl:before {
    content: "\eb2f"
}

.line-icon-Pac-Man:before {
    content: "\eb30"
}

.line-icon-Paint-Brush:before {
    content: "\eb31"
}

.line-icon-Paint-Bucket:before {
    content: "\eb32"
}

.line-icon-Paintbrush:before {
    content: "\eb33"
}

.line-icon-Palette:before {
    content: "\eb34"
}

.line-icon-Palm-Tree:before {
    content: "\eb35"
}

.line-icon-Panda:before {
    content: "\eb36"
}

.line-icon-Panorama:before {
    content: "\eb37"
}

.line-icon-Pantheon:before {
    content: "\eb38"
}

.line-icon-Pantone:before {
    content: "\eb39"
}

.line-icon-Pants:before {
    content: "\eb3a"
}

.line-icon-Paper-Plane:before {
    content: "\eb3b"
}

.line-icon-Paper:before {
    content: "\eb3c"
}

.line-icon-Parasailing:before {
    content: "\eb3d"
}

.line-icon-Parrot:before {
    content: "\eb3e"
}

.line-icon-Password-2shopping:before {
    content: "\eb3f"
}

.line-icon-Password-Field:before {
    content: "\eb40"
}

.line-icon-Password-shopping:before {
    content: "\eb41"
}

.line-icon-Password:before {
    content: "\eb42"
}

.line-icon-pause-2:before {
    content: "\eb43"
}

.line-icon-Pause:before {
    content: "\eb44"
}

.line-icon-Paw:before {
    content: "\eb45"
}

.line-icon-Pawn:before {
    content: "\eb46"
}

.line-icon-Paypal:before {
    content: "\eb47"
}

.line-icon-Pen-2:before {
    content: "\eb48"
}

.line-icon-Pen-3:before {
    content: "\eb49"
}

.line-icon-Pen-4:before {
    content: "\eb4a"
}

.line-icon-Pen-5:before {
    content: "\eb4b"
}

.line-icon-Pen-6:before {
    content: "\eb4c"
}

.line-icon-Pen:before {
    content: "\eb4d"
}

.line-icon-Pencil-Ruler:before {
    content: "\eb4e"
}

.line-icon-Pencil:before {
    content: "\eb4f"
}

.line-icon-Penguin:before {
    content: "\eb50"
}

.line-icon-Pentagon:before {
    content: "\eb51"
}

.line-icon-People-onCloud:before {
    content: "\eb52"
}

.line-icon-Pepper-withFire:before {
    content: "\eb53"
}

.line-icon-Pepper:before {
    content: "\eb54"
}

.line-icon-Petrol:before {
    content: "\eb55"
}

.line-icon-Petronas-Tower:before {
    content: "\eb56"
}

.line-icon-Philipines:before {
    content: "\eb57"
}

.line-icon-Phone-2:before {
    content: "\eb58"
}

.line-icon-Phone-3:before {
    content: "\eb59"
}

.line-icon-Phone-3G:before {
    content: "\eb5a"
}

.line-icon-Phone-4G:before {
    content: "\eb5b"
}

.line-icon-Phone-Simcard:before {
    content: "\eb5c"
}

.line-icon-Phone-SMS:before {
    content: "\eb5d"
}

.line-icon-Phone-Wifi:before {
    content: "\eb5e"
}

.line-icon-Phone:before {
    content: "\eb5f"
}

.line-icon-Photo-2:before {
    content: "\eb60"
}

.line-icon-Photo-3:before {
    content: "\eb61"
}

.line-icon-Photo-Album:before {
    content: "\eb62"
}

.line-icon-Photo-Album2:before {
    content: "\eb63"
}

.line-icon-Photo-Album3:before {
    content: "\eb64"
}

.line-icon-Photo:before {
    content: "\eb65"
}

.line-icon-Photos:before {
    content: "\eb66"
}

.line-icon-Physics:before {
    content: "\eb67"
}

.line-icon-Pi:before {
    content: "\eb68"
}

.line-icon-Piano:before {
    content: "\eb69"
}

.line-icon-Picasa:before {
    content: "\eb6a"
}

.line-icon-Pie-Chart:before {
    content: "\eb6b"
}

.line-icon-Pie-Chart2:before {
    content: "\eb6c"
}

.line-icon-Pie-Chart3:before {
    content: "\eb6d"
}

.line-icon-Pilates-2:before {
    content: "\eb6e"
}

.line-icon-Pilates-3:before {
    content: "\eb6f"
}

.line-icon-Pilates:before {
    content: "\eb70"
}

.line-icon-Pilot:before {
    content: "\eb71"
}

.line-icon-Pinch:before {
    content: "\eb72"
}

.line-icon-Ping-Pong:before {
    content: "\eb73"
}

.line-icon-Pinterest:before {
    content: "\eb74"
}

.line-icon-Pipe:before {
    content: "\eb75"
}

.line-icon-Pipette:before {
    content: "\eb76"
}

.line-icon-Piramids:before {
    content: "\eb77"
}

.line-icon-Pisces-2:before {
    content: "\eb78"
}

.line-icon-Pisces:before {
    content: "\eb79"
}

.line-icon-Pizza-Slice:before {
    content: "\eb7a"
}

.line-icon-Pizza:before {
    content: "\eb7b"
}

.line-icon-Plane-2:before {
    content: "\eb7c"
}

.line-icon-Plane:before {
    content: "\eb7d"
}

.line-icon-Plant:before {
    content: "\eb7e"
}

.line-icon-Plasmid:before {
    content: "\eb7f"
}

.line-icon-Plaster:before {
    content: "\eb80"
}

.line-icon-Plastic-CupPhone:before {
    content: "\eb81"
}

.line-icon-Plastic-CupPhone2:before {
    content: "\eb82"
}

.line-icon-Plate:before {
    content: "\eb83"
}

.line-icon-Plates:before {
    content: "\eb84"
}

.line-icon-Plaxo:before {
    content: "\eb85"
}

.line-icon-Play-Music:before {
    content: "\eb86"
}

.line-icon-Plug-In:before {
    content: "\eb87"
}

.line-icon-Plug-In2:before {
    content: "\eb88"
}

.line-icon-Plurk:before {
    content: "\eb89"
}

.line-icon-Pointer:before {
    content: "\eb8a"
}

.line-icon-Poland:before {
    content: "\eb8b"
}

.line-icon-Police-Man:before {
    content: "\eb8c"
}

.line-icon-Police-Station:before {
    content: "\eb8d"
}

.line-icon-Police-Woman:before {
    content: "\eb8e"
}

.line-icon-Police:before {
    content: "\eb8f"
}

.line-icon-Polo-Shirt:before {
    content: "\eb90"
}

.line-icon-Portrait:before {
    content: "\eb91"
}

.line-icon-Portugal:before {
    content: "\eb92"
}

.line-icon-Post-Mail:before {
    content: "\eb93"
}

.line-icon-Post-Mail2:before {
    content: "\eb94"
}

.line-icon-Post-Office:before {
    content: "\eb95"
}

.line-icon-Post-Sign:before {
    content: "\eb96"
}

.line-icon-Post-Sign2ways:before {
    content: "\eb97"
}

.line-icon-Posterous:before {
    content: "\eb98"
}

.line-icon-Pound-Sign:before {
    content: "\eb99"
}

.line-icon-Pound-Sign2:before {
    content: "\eb9a"
}

.line-icon-Pound:before {
    content: "\eb9b"
}

.line-icon-Power-2:before {
    content: "\eb9c"
}

.line-icon-Power-3:before {
    content: "\eb9d"
}

.line-icon-Power-Cable:before {
    content: "\eb9e"
}

.line-icon-Power-Station:before {
    content: "\eb9f"
}

.line-icon-Power:before {
    content: "\eba0"
}

.line-icon-Prater:before {
    content: "\eba1"
}

.line-icon-Present:before {
    content: "\eba2"
}

.line-icon-Presents:before {
    content: "\eba3"
}

.line-icon-Press:before {
    content: "\eba4"
}

.line-icon-Preview:before {
    content: "\eba5"
}

.line-icon-Previous:before {
    content: "\eba6"
}

.line-icon-Pricing:before {
    content: "\eba7"
}

.line-icon-Printer:before {
    content: "\eba8"
}

.line-icon-Professor:before {
    content: "\eba9"
}

.line-icon-Profile:before {
    content: "\ebaa"
}

.line-icon-Project:before {
    content: "\ebab"
}

.line-icon-Projector-2:before {
    content: "\ebac"
}

.line-icon-Projector:before {
    content: "\ebad"
}

.line-icon-Pulse:before {
    content: "\ebae"
}

.line-icon-Pumpkin:before {
    content: "\ebaf"
}

.line-icon-Punk:before {
    content: "\ebb0"
}

.line-icon-Punker:before {
    content: "\ebb1"
}

.line-icon-Puzzle:before {
    content: "\ebb2"
}

.line-icon-QIK:before {
    content: "\ebb3"
}

.line-icon-QR-Code:before {
    content: "\ebb4"
}

.line-icon-Queen-2:before {
    content: "\ebb5"
}

.line-icon-Queen:before {
    content: "\ebb6"
}

.line-icon-Quill-2:before {
    content: "\ebb7"
}

.line-icon-Quill-3:before {
    content: "\ebb8"
}

.line-icon-Quill:before {
    content: "\ebb9"
}

.line-icon-Quotes-2:before {
    content: "\ebba"
}

.line-icon-Quotes:before {
    content: "\ebbb"
}

.line-icon-Radio:before {
    content: "\ebbc"
}

.line-icon-Radioactive:before {
    content: "\ebbd"
}

.line-icon-Rafting:before {
    content: "\ebbe"
}

.line-icon-Rain-Drop:before {
    content: "\ebbf"
}

.line-icon-Rainbow-2:before {
    content: "\ebc0"
}

.line-icon-Rainbow:before {
    content: "\ebc1"
}

.line-icon-Ram:before {
    content: "\ebc2"
}

.line-icon-Razzor-Blade:before {
    content: "\ebc3"
}

.line-icon-Receipt-2:before {
    content: "\ebc4"
}

.line-icon-Receipt-3:before {
    content: "\ebc5"
}

.line-icon-Receipt-4:before {
    content: "\ebc6"
}

.line-icon-Receipt:before {
    content: "\ebc7"
}

.line-icon-Record2:before {
    content: "\ebc8"
}

.line-icon-Record-3:before {
    content: "\ebc9"
}

.line-icon-Record-Music:before {
    content: "\ebca"
}

.line-icon-Record:before {
    content: "\ebcb"
}

.line-icon-Recycling-2:before {
    content: "\ebcc"
}

.line-icon-Recycling:before {
    content: "\ebcd"
}

.line-icon-Reddit:before {
    content: "\ebce"
}

.line-icon-Redhat:before {
    content: "\ebcf"
}

.line-icon-Redirect:before {
    content: "\ebd0"
}

.line-icon-Redo:before {
    content: "\ebd1"
}

.line-icon-Reel:before {
    content: "\ebd2"
}

.line-icon-Refinery:before {
    content: "\ebd3"
}

.line-icon-Refresh-Window:before {
    content: "\ebd4"
}

.line-icon-Refresh:before {
    content: "\ebd5"
}

.line-icon-Reload-2:before {
    content: "\ebd6"
}

.line-icon-Reload-3:before {
    content: "\ebd7"
}

.line-icon-Reload:before {
    content: "\ebd8"
}

.line-icon-Remote-Controll:before {
    content: "\ebd9"
}

.line-icon-Remote-Controll2:before {
    content: "\ebda"
}

.line-icon-Remove-Bag:before {
    content: "\ebdb"
}

.line-icon-Remove-Basket:before {
    content: "\ebdc"
}

.line-icon-Remove-Cart:before {
    content: "\ebdd"
}

.line-icon-Remove-File:before {
    content: "\ebde"
}

.line-icon-Remove-User:before {
    content: "\ebdf"
}

.line-icon-Remove-Window:before {
    content: "\ebe0"
}

.line-icon-Remove:before {
    content: "\ebe1"
}

.line-icon-Rename:before {
    content: "\ebe2"
}

.line-icon-Repair:before {
    content: "\ebe3"
}

.line-icon-Repeat-2:before {
    content: "\ebe4"
}

.line-icon-Repeat-3:before {
    content: "\ebe5"
}

.line-icon-Repeat-4:before {
    content: "\ebe6"
}

.line-icon-Repeat-5:before {
    content: "\ebe7"
}

.line-icon-Repeat-6:before {
    content: "\ebe8"
}

.line-icon-Repeat-7:before {
    content: "\ebe9"
}

.line-icon-Repeat:before {
    content: "\ebea"
}

.line-icon-Reset:before {
    content: "\ebeb"
}

.line-icon-Resize:before {
    content: "\ebec"
}

.line-icon-Restore-Window:before {
    content: "\ebed"
}

.line-icon-Retouching:before {
    content: "\ebee"
}

.line-icon-Retro-Camera:before {
    content: "\ebef"
}

.line-icon-Retro:before {
    content: "\ebf0"
}

.line-icon-Retweet:before {
    content: "\ebf1"
}

.line-icon-Reverbnation:before {
    content: "\ebf2"
}

.line-icon-Rewind:before {
    content: "\ebf3"
}

.line-icon-RGB:before {
    content: "\ebf4"
}

.line-icon-Ribbon-2:before {
    content: "\ebf5"
}

.line-icon-Ribbon-3:before {
    content: "\ebf6"
}

.line-icon-Ribbon:before {
    content: "\ebf7"
}

.line-icon-Right-2:before {
    content: "\ebf8"
}

.line-icon-Right-3:before {
    content: "\ebf9"
}

.line-icon-Right-4:before {
    content: "\ebfa"
}

.line-icon-Right-ToLeft:before {
    content: "\ebfb"
}

.line-icon-Right:before {
    content: "\ebfc"
}

.line-icon-Road-2:before {
    content: "\ebfd"
}

.line-icon-Road-3:before {
    content: "\ebfe"
}

.line-icon-Road:before {
    content: "\ebff"
}

.line-icon-Robot-2:before {
    content: "\ec00"
}

.line-icon-Robot:before {
    content: "\ec01"
}

.line-icon-Rock-andRoll:before {
    content: "\ec02"
}

.line-icon-Rocket:before {
    content: "\ec03"
}

.line-icon-Roller:before {
    content: "\ec04"
}

.line-icon-Roof:before {
    content: "\ec05"
}

.line-icon-Rook:before {
    content: "\ec06"
}

.line-icon-Rotate-Gesture:before {
    content: "\ec07"
}

.line-icon-Rotate-Gesture2:before {
    content: "\ec08"
}

.line-icon-Rotate-Gesture3:before {
    content: "\ec09"
}

.line-icon-Rotation-390:before {
    content: "\ec0a"
}

.line-icon-Rotation:before {
    content: "\ec0b"
}

.line-icon-Router-2:before {
    content: "\ec0c"
}

.line-icon-Router:before {
    content: "\ec0d"
}

.line-icon-RSS:before {
    content: "\ec0e"
}

.line-icon-Ruler-2:before {
    content: "\ec0f"
}

.line-icon-Ruler:before {
    content: "\ec10"
}

.line-icon-Running-Shoes:before {
    content: "\ec11"
}

.line-icon-Running:before {
    content: "\ec12"
}

.line-icon-Safari:before {
    content: "\ec13"
}

.line-icon-Safe-Box:before {
    content: "\ec14"
}

.line-icon-Safe-Box2:before {
    content: "\ec15"
}

.line-icon-Safety-PinClose:before {
    content: "\ec16"
}

.line-icon-Safety-PinOpen:before {
    content: "\ec17"
}

.line-icon-Sagittarus-2:before {
    content: "\ec18"
}

.line-icon-Sagittarus:before {
    content: "\ec19"
}

.line-icon-Sailing-Ship:before {
    content: "\ec1a"
}

.line-icon-Sand-watch:before {
    content: "\ec1b"
}

.line-icon-Sand-watch2:before {
    content: "\ec1c"
}

.line-icon-Santa-Claus:before {
    content: "\ec1d"
}

.line-icon-Santa-Claus2:before {
    content: "\ec1e"
}

.line-icon-Santa-onSled:before {
    content: "\ec1f"
}

.line-icon-Satelite-2:before {
    content: "\ec20"
}

.line-icon-Satelite:before {
    content: "\ec21"
}

.line-icon-Save-Window:before {
    content: "\ec22"
}

.line-icon-Save:before {
    content: "\ec23"
}

.line-icon-Saw:before {
    content: "\ec24"
}

.line-icon-Saxophone:before {
    content: "\ec25"
}

.line-icon-Scale:before {
    content: "\ec26"
}

.line-icon-Scarf:before {
    content: "\ec27"
}

.line-icon-Scissor:before {
    content: "\ec28"
}

.line-icon-Scooter-Front:before {
    content: "\ec29"
}

.line-icon-Scooter:before {
    content: "\ec2a"
}

.line-icon-Scorpio-2:before {
    content: "\ec2b"
}

.line-icon-Scorpio:before {
    content: "\ec2c"
}

.line-icon-Scotland:before {
    content: "\ec2d"
}

.line-icon-Screwdriver:before {
    content: "\ec2e"
}

.line-icon-Scroll-Fast:before {
    content: "\ec2f"
}

.line-icon-Scroll:before {
    content: "\ec30"
}

.line-icon-Scroller-2:before {
    content: "\ec31"
}

.line-icon-Scroller:before {
    content: "\ec32"
}

.line-icon-Sea-Dog:before {
    content: "\ec33"
}

.line-icon-Search-onCloud:before {
    content: "\ec34"
}

.line-icon-Search-People:before {
    content: "\ec35"
}

.line-icon-secound:before {
    content: "\ec36"
}

.line-icon-secound2:before {
    content: "\ec37"
}

.line-icon-Security-Block:before {
    content: "\ec38"
}

.line-icon-Security-Bug:before {
    content: "\ec39"
}

.line-icon-Security-Camera:before {
    content: "\ec3a"
}

.line-icon-Security-Check:before {
    content: "\ec3b"
}

.line-icon-Security-Settings:before {
    content: "\ec3c"
}

.line-icon-Security-Smiley:before {
    content: "\ec3d"
}

.line-icon-Securiy-Remove:before {
    content: "\ec3e"
}

.line-icon-Seed:before {
    content: "\ec3f"
}

.line-icon-Selfie:before {
    content: "\ec40"
}

.line-icon-Serbia:before {
    content: "\ec41"
}

.line-icon-Server-2:before {
    content: "\ec42"
}

.line-icon-Server:before {
    content: "\ec43"
}

.line-icon-Servers:before {
    content: "\ec44"
}

.line-icon-Settings-Window:before {
    content: "\ec45"
}

.line-icon-Sewing-Machine:before {
    content: "\ec46"
}

.line-icon-Sexual:before {
    content: "\ec47"
}

.line-icon-Share-onCloud:before {
    content: "\ec48"
}

.line-icon-Share-Window:before {
    content: "\ec49"
}

.line-icon-Share:before {
    content: "\ec4a"
}

.line-icon-Sharethis:before {
    content: "\ec4b"
}

.line-icon-Shark:before {
    content: "\ec4c"
}

.line-icon-Sheep:before {
    content: "\ec4d"
}

.line-icon-Sheriff-Badge:before {
    content: "\ec4e"
}

.line-icon-Shield:before {
    content: "\ec4f"
}

.line-icon-Ship-2:before {
    content: "\ec50"
}

.line-icon-Ship:before {
    content: "\ec51"
}

.line-icon-Shirt:before {
    content: "\ec52"
}

.line-icon-Shoes-2:before {
    content: "\ec53"
}

.line-icon-Shoes-3:before {
    content: "\ec54"
}

.line-icon-Shoes:before {
    content: "\ec55"
}

.line-icon-Shop-2:before {
    content: "\ec56"
}

.line-icon-Shop-3:before {
    content: "\ec57"
}

.line-icon-Shop-4:before {
    content: "\ec58"
}

.line-icon-Shop:before {
    content: "\ec59"
}

.line-icon-Shopping-Bag:before {
    content: "\ec5a"
}

.line-icon-Shopping-Basket:before {
    content: "\ec5b"
}

.line-icon-Shopping-Cart:before {
    content: "\ec5c"
}

.line-icon-Short-Pants:before {
    content: "\ec5d"
}

.line-icon-Shoutwire:before {
    content: "\ec5e"
}

.line-icon-Shovel:before {
    content: "\ec5f"
}

.line-icon-Shuffle-2:before {
    content: "\ec60"
}

.line-icon-Shuffle-3:before {
    content: "\ec61"
}

.line-icon-Shuffle-4:before {
    content: "\ec62"
}

.line-icon-Shuffle:before {
    content: "\ec63"
}

.line-icon-Shutter:before {
    content: "\ec64"
}

.line-icon-Sidebar-Window:before {
    content: "\ec65"
}

.line-icon-Signal:before {
    content: "\ec66"
}

.line-icon-Singapore:before {
    content: "\ec67"
}

.line-icon-Skate-Shoes:before {
    content: "\ec68"
}

.line-icon-Skateboard-2:before {
    content: "\ec69"
}

.line-icon-Skateboard:before {
    content: "\ec6a"
}

.line-icon-Skeleton:before {
    content: "\ec6b"
}

.line-icon-Ski:before {
    content: "\ec6c"
}

.line-icon-Skirt:before {
    content: "\ec6d"
}

.line-icon-Skrill:before {
    content: "\ec6e"
}

.line-icon-Skull:before {
    content: "\ec6f"
}

.line-icon-Skydiving:before {
    content: "\ec70"
}

.line-icon-Skype:before {
    content: "\ec71"
}

.line-icon-Sled-withGifts:before {
    content: "\ec72"
}

.line-icon-Sled:before {
    content: "\ec73"
}

.line-icon-Sleeping:before {
    content: "\ec74"
}

.line-icon-Sleet:before {
    content: "\ec75"
}

.line-icon-Slippers:before {
    content: "\ec76"
}

.line-icon-Smart:before {
    content: "\ec77"
}

.line-icon-Smartphone-2:before {
    content: "\ec78"
}

.line-icon-Smartphone-3:before {
    content: "\ec79"
}

.line-icon-Smartphone-4:before {
    content: "\ec7a"
}

.line-icon-Smartphone-Secure:before {
    content: "\ec7b"
}

.line-icon-Smartphone:before {
    content: "\ec7c"
}

.line-icon-Smile:before {
    content: "\ec7d"
}

.line-icon-Smoking-Area:before {
    content: "\ec7e"
}

.line-icon-Smoking-Pipe:before {
    content: "\ec7f"
}

.line-icon-Snake:before {
    content: "\ec80"
}

.line-icon-Snorkel:before {
    content: "\ec81"
}

.line-icon-Snow-2:before {
    content: "\ec82"
}

.line-icon-Snow-Dome:before {
    content: "\ec83"
}

.line-icon-Snow-Storm:before {
    content: "\ec84"
}

.line-icon-Snow:before {
    content: "\ec85"
}

.line-icon-Snowflake-2:before {
    content: "\ec86"
}

.line-icon-Snowflake-3:before {
    content: "\ec87"
}

.line-icon-Snowflake-4:before {
    content: "\ec88"
}

.line-icon-Snowflake:before {
    content: "\ec89"
}

.line-icon-Snowman:before {
    content: "\ec8a"
}

.line-icon-Soccer-Ball:before {
    content: "\ec8b"
}

.line-icon-Soccer-Shoes:before {
    content: "\ec8c"
}

.line-icon-Socks:before {
    content: "\ec8d"
}

.line-icon-Solar:before {
    content: "\ec8e"
}

.line-icon-Sound-Wave:before {
    content: "\ec8f"
}

.line-icon-Sound:before {
    content: "\ec90"
}

.line-icon-Soundcloud:before {
    content: "\ec91"
}

.line-icon-Soup:before {
    content: "\ec92"
}

.line-icon-South-Africa:before {
    content: "\ec93"
}

.line-icon-Space-Needle:before {
    content: "\ec94"
}

.line-icon-Spain:before {
    content: "\ec95"
}

.line-icon-Spam-Mail:before {
    content: "\ec96"
}

.line-icon-Speach-Bubble:before {
    content: "\ec97"
}

.line-icon-Speach-Bubble2:before {
    content: "\ec98"
}

.line-icon-Speach-Bubble3:before {
    content: "\ec99"
}

.line-icon-Speach-Bubble4:before {
    content: "\ec9a"
}

.line-icon-Speach-Bubble5:before {
    content: "\ec9b"
}

.line-icon-Speach-Bubble6:before {
    content: "\ec9c"
}

.line-icon-Speach-Bubble7:before {
    content: "\ec9d"
}

.line-icon-Speach-Bubble8:before {
    content: "\ec9e"
}

.line-icon-Speach-Bubble9:before {
    content: "\ec9f"
}

.line-icon-Speach-Bubble10:before {
    content: "\eca0"
}

.line-icon-Speach-Bubble11:before {
    content: "\eca1"
}

.line-icon-Speach-Bubble12:before {
    content: "\eca2"
}

.line-icon-Speach-Bubble13:before {
    content: "\eca3"
}

.line-icon-Speach-BubbleAsking:before {
    content: "\eca4"
}

.line-icon-Speach-BubbleComic:before {
    content: "\eca5"
}

.line-icon-Speach-BubbleComic2:before {
    content: "\eca6"
}

.line-icon-Speach-BubbleComic3:before {
    content: "\eca7"
}

.line-icon-Speach-BubbleComic4:before {
    content: "\eca8"
}

.line-icon-Speach-BubbleDialog:before {
    content: "\eca9"
}

.line-icon-Speach-Bubbles:before {
    content: "\ecaa"
}

.line-icon-Speak-2:before {
    content: "\ecab"
}

.line-icon-Speak:before {
    content: "\ecac"
}

.line-icon-Speaker-2:before {
    content: "\ecad"
}

.line-icon-Speaker:before {
    content: "\ecae"
}

.line-icon-Spell-Check:before {
    content: "\ecaf"
}

.line-icon-Spell-CheckABC:before {
    content: "\ecb0"
}

.line-icon-Spermium:before {
    content: "\ecb1"
}

.line-icon-Spider:before {
    content: "\ecb2"
}

.line-icon-Spiderweb:before {
    content: "\ecb3"
}

.line-icon-Split-FourSquareWindow:before {
    content: "\ecb4"
}

.line-icon-Split-Horizontal:before {
    content: "\ecb5"
}

.line-icon-Split-Horizontal2Window:before {
    content: "\ecb6"
}

.line-icon-Split-Vertical:before {
    content: "\ecb7"
}

.line-icon-Split-Vertical2:before {
    content: "\ecb8"
}

.line-icon-Split-Window:before {
    content: "\ecb9"
}

.line-icon-Spoder:before {
    content: "\ecba"
}

.line-icon-Spoon:before {
    content: "\ecbb"
}

.line-icon-Sport-Mode:before {
    content: "\ecbc"
}

.line-icon-Sports-Clothings1:before {
    content: "\ecbd"
}

.line-icon-Sports-Clothings2:before {
    content: "\ecbe"
}

.line-icon-Sports-Shirt:before {
    content: "\ecbf"
}

.line-icon-Spot:before {
    content: "\ecc0"
}

.line-icon-Spray:before {
    content: "\ecc1"
}

.line-icon-Spread:before {
    content: "\ecc2"
}

.line-icon-Spring:before {
    content: "\ecc3"
}

.line-icon-Spurl:before {
    content: "\ecc4"
}

.line-icon-Spy:before {
    content: "\ecc5"
}

.line-icon-Squirrel:before {
    content: "\ecc6"
}

.line-icon-SSL:before {
    content: "\ecc7"
}

.line-icon-St-BasilsCathedral:before {
    content: "\ecc8"
}

.line-icon-St-PaulsCathedral:before {
    content: "\ecc9"
}

.line-icon-Stamp-2:before {
    content: "\ecca"
}

.line-icon-Stamp:before {
    content: "\eccb"
}

.line-icon-Stapler:before {
    content: "\eccc"
}

.line-icon-Star-Track:before {
    content: "\eccd"
}

.line-icon-Star:before {
    content: "\ecce"
}

.line-icon-Starfish:before {
    content: "\eccf"
}

.line-icon-Start2:before {
    content: "\ecd0"
}

.line-icon-Start-3:before {
    content: "\ecd1"
}

.line-icon-Start-ways:before {
    content: "\ecd2"
}

.line-icon-Start:before {
    content: "\ecd3"
}

.line-icon-Statistic:before {
    content: "\ecd4"
}

.line-icon-Stethoscope:before {
    content: "\ecd5"
}

.line-icon-stop--2:before {
    content: "\ecd6"
}

.line-icon-Stop-Music:before {
    content: "\ecd7"
}

.line-icon-Stop:before {
    content: "\ecd8"
}

.line-icon-Stopwatch-2:before {
    content: "\ecd9"
}

.line-icon-Stopwatch:before {
    content: "\ecda"
}

.line-icon-Storm:before {
    content: "\ecdb"
}

.line-icon-Street-View:before {
    content: "\ecdc"
}

.line-icon-Street-View2:before {
    content: "\ecdd"
}

.line-icon-Strikethrough-Text:before {
    content: "\ecde"
}

.line-icon-Stroller:before {
    content: "\ecdf"
}

.line-icon-Structure:before {
    content: "\ece0"
}

.line-icon-Student-Female:before {
    content: "\ece1"
}

.line-icon-Student-Hat:before {
    content: "\ece2"
}

.line-icon-Student-Hat2:before {
    content: "\ece3"
}

.line-icon-Student-Male:before {
    content: "\ece4"
}

.line-icon-Student-MaleFemale:before {
    content: "\ece5"
}

.line-icon-Students:before {
    content: "\ece6"
}

.line-icon-Studio-Flash:before {
    content: "\ece7"
}

.line-icon-Studio-Lightbox:before {
    content: "\ece8"
}

.line-icon-Stumbleupon:before {
    content: "\ece9"
}

.line-icon-Suit:before {
    content: "\ecea"
}

.line-icon-Suitcase:before {
    content: "\eceb"
}

.line-icon-Sum-2:before {
    content: "\ecec"
}

.line-icon-Sum:before {
    content: "\eced"
}

.line-icon-Summer:before {
    content: "\ecee"
}

.line-icon-Sun-CloudyRain:before {
    content: "\ecef"
}

.line-icon-Sun:before {
    content: "\ecf0"
}

.line-icon-Sunglasses-2:before {
    content: "\ecf1"
}

.line-icon-Sunglasses-3:before {
    content: "\ecf2"
}

.line-icon-Sunglasses-Smiley:before {
    content: "\ecf3"
}

.line-icon-Sunglasses-Smiley2:before {
    content: "\ecf4"
}

.line-icon-Sunglasses-W:before {
    content: "\ecf5"
}

.line-icon-Sunglasses-W2:before {
    content: "\ecf6"
}

.line-icon-Sunglasses-W3:before {
    content: "\ecf7"
}

.line-icon-Sunglasses:before {
    content: "\ecf8"
}

.line-icon-Sunrise:before {
    content: "\ecf9"
}

.line-icon-Sunset:before {
    content: "\ecfa"
}

.line-icon-Superman:before {
    content: "\ecfb"
}

.line-icon-Support:before {
    content: "\ecfc"
}

.line-icon-Surprise:before {
    content: "\ecfd"
}

.line-icon-Sushi:before {
    content: "\ecfe"
}

.line-icon-Sweden:before {
    content: "\ecff"
}

.line-icon-Swimming-Short:before {
    content: "\ed00"
}

.line-icon-Swimming:before {
    content: "\ed01"
}

.line-icon-Swimmwear:before {
    content: "\ed02"
}

.line-icon-Switch:before {
    content: "\ed03"
}

.line-icon-Switzerland:before {
    content: "\ed04"
}

.line-icon-Sync-Cloud:before {
    content: "\ed05"
}

.line-icon-Sync:before {
    content: "\ed06"
}

.line-icon-Synchronize-2:before {
    content: "\ed07"
}

.line-icon-Synchronize:before {
    content: "\ed08"
}

.line-icon-T-Shirt:before {
    content: "\ed09"
}

.line-icon-Tablet-2:before {
    content: "\ed0a"
}

.line-icon-Tablet-3:before {
    content: "\ed0b"
}

.line-icon-Tablet-Orientation:before {
    content: "\ed0c"
}

.line-icon-Tablet-Phone:before {
    content: "\ed0d"
}

.line-icon-Tablet-Secure:before {
    content: "\ed0e"
}

.line-icon-Tablet-Vertical:before {
    content: "\ed0f"
}

.line-icon-Tablet:before {
    content: "\ed10"
}

.line-icon-Tactic:before {
    content: "\ed11"
}

.line-icon-Tag-2:before {
    content: "\ed12"
}

.line-icon-Tag-3:before {
    content: "\ed13"
}

.line-icon-Tag-4:before {
    content: "\ed14"
}

.line-icon-Tag-5:before {
    content: "\ed15"
}

.line-icon-Tag:before {
    content: "\ed16"
}

.line-icon-Taj-Mahal:before {
    content: "\ed17"
}

.line-icon-Talk-Man:before {
    content: "\ed18"
}

.line-icon-Tap:before {
    content: "\ed19"
}

.line-icon-Target-Market:before {
    content: "\ed1a"
}

.line-icon-Target:before {
    content: "\ed1b"
}

.line-icon-Taurus-2:before {
    content: "\ed1c"
}

.line-icon-Taurus:before {
    content: "\ed1d"
}

.line-icon-Taxi-2:before {
    content: "\ed1e"
}

.line-icon-Taxi-Sign:before {
    content: "\ed1f"
}

.line-icon-Taxi:before {
    content: "\ed20"
}

.line-icon-Teacher:before {
    content: "\ed21"
}

.line-icon-Teapot:before {
    content: "\ed22"
}

.line-icon-Technorati:before {
    content: "\ed23"
}

.line-icon-Teddy-Bear:before {
    content: "\ed24"
}

.line-icon-Tee-Mug:before {
    content: "\ed25"
}

.line-icon-Telephone-2:before {
    content: "\ed26"
}

.line-icon-Telephone:before {
    content: "\ed27"
}

.line-icon-Telescope:before {
    content: "\ed28"
}

.line-icon-Temperature-2:before {
    content: "\ed29"
}

.line-icon-Temperature-3:before {
    content: "\ed2a"
}

.line-icon-Temperature:before {
    content: "\ed2b"
}

.line-icon-Temple:before {
    content: "\ed2c"
}

.line-icon-Tennis-Ball:before {
    content: "\ed2d"
}

.line-icon-Tennis:before {
    content: "\ed2e"
}

.line-icon-Tent:before {
    content: "\ed2f"
}

.line-icon-Test-Tube:before {
    content: "\ed30"
}

.line-icon-Test-Tube2:before {
    content: "\ed31"
}

.line-icon-Testimonal:before {
    content: "\ed32"
}

.line-icon-Text-Box:before {
    content: "\ed33"
}

.line-icon-Text-Effect:before {
    content: "\ed34"
}

.line-icon-Text-HighlightColor:before {
    content: "\ed35"
}

.line-icon-Text-Paragraph:before {
    content: "\ed36"
}

.line-icon-Thailand:before {
    content: "\ed37"
}

.line-icon-The-WhiteHouse:before {
    content: "\ed38"
}

.line-icon-This-SideUp:before {
    content: "\ed39"
}

.line-icon-Thread:before {
    content: "\ed3a"
}

.line-icon-Three-ArrowFork:before {
    content: "\ed3b"
}

.line-icon-Three-Fingers:before {
    content: "\ed3c"
}

.line-icon-Three-FingersDrag:before {
    content: "\ed3d"
}

.line-icon-Three-FingersDrag2:before {
    content: "\ed3e"
}

.line-icon-Three-FingersTouch:before {
    content: "\ed3f"
}

.line-icon-Thumb:before {
    content: "\ed40"
}

.line-icon-Thumbs-DownSmiley:before {
    content: "\ed41"
}

.line-icon-Thumbs-UpSmiley:before {
    content: "\ed42"
}

.line-icon-Thunder:before {
    content: "\ed43"
}

.line-icon-Thunderstorm:before {
    content: "\ed44"
}

.line-icon-Ticket:before {
    content: "\ed45"
}

.line-icon-Tie-2:before {
    content: "\ed46"
}

.line-icon-Tie-3:before {
    content: "\ed47"
}

.line-icon-Tie-4:before {
    content: "\ed48"
}

.line-icon-Tie:before {
    content: "\ed49"
}

.line-icon-Tiger:before {
    content: "\ed4a"
}

.line-icon-Time-Backup:before {
    content: "\ed4b"
}

.line-icon-Time-Bomb:before {
    content: "\ed4c"
}

.line-icon-Time-Clock:before {
    content: "\ed4d"
}

.line-icon-Time-Fire:before {
    content: "\ed4e"
}

.line-icon-Time-Machine:before {
    content: "\ed4f"
}

.line-icon-Time-Window:before {
    content: "\ed50"
}

.line-icon-Timer-2:before {
    content: "\ed51"
}

.line-icon-Timer:before {
    content: "\ed52"
}

.line-icon-To-Bottom:before {
    content: "\ed53"
}

.line-icon-To-Bottom2:before {
    content: "\ed54"
}

.line-icon-To-Left:before {
    content: "\ed55"
}

.line-icon-To-Right:before {
    content: "\ed56"
}

.line-icon-To-Top:before {
    content: "\ed57"
}

.line-icon-To-Top2:before {
    content: "\ed58"
}

.line-icon-Token-:before {
    content: "\ed59"
}

.line-icon-Tomato:before {
    content: "\ed5a"
}

.line-icon-Tongue:before {
    content: "\ed5b"
}

.line-icon-Tooth-2:before {
    content: "\ed5c"
}

.line-icon-Tooth:before {
    content: "\ed5d"
}

.line-icon-Top-ToBottom:before {
    content: "\ed5e"
}

.line-icon-Touch-Window:before {
    content: "\ed5f"
}

.line-icon-Tourch:before {
    content: "\ed60"
}

.line-icon-Tower-2:before {
    content: "\ed61"
}

.line-icon-Tower-Bridge:before {
    content: "\ed62"
}

.line-icon-Tower:before {
    content: "\ed63"
}

.line-icon-Trace:before {
    content: "\ed64"
}

.line-icon-Tractor:before {
    content: "\ed65"
}

.line-icon-traffic-Light:before {
    content: "\ed66"
}

.line-icon-Traffic-Light2:before {
    content: "\ed67"
}

.line-icon-Train-2:before {
    content: "\ed68"
}

.line-icon-Train:before {
    content: "\ed69"
}

.line-icon-Tram:before {
    content: "\ed6a"
}

.line-icon-Transform-2:before {
    content: "\ed6b"
}

.line-icon-Transform-3:before {
    content: "\ed6c"
}

.line-icon-Transform-4:before {
    content: "\ed6d"
}

.line-icon-Transform:before {
    content: "\ed6e"
}

.line-icon-Trash-withMen:before {
    content: "\ed6f"
}

.line-icon-Tree-2:before {
    content: "\ed70"
}

.line-icon-Tree-3:before {
    content: "\ed71"
}

.line-icon-Tree-4:before {
    content: "\ed72"
}

.line-icon-Tree-5:before {
    content: "\ed73"
}

.line-icon-Tree:before {
    content: "\ed74"
}

.line-icon-Trekking:before {
    content: "\ed75"
}

.line-icon-Triangle-ArrowDown:before {
    content: "\ed76"
}

.line-icon-Triangle-ArrowLeft:before {
    content: "\ed77"
}

.line-icon-Triangle-ArrowRight:before {
    content: "\ed78"
}

.line-icon-Triangle-ArrowUp:before {
    content: "\ed79"
}

.line-icon-Tripod-2:before {
    content: "\ed7a"
}

.line-icon-Tripod-andVideo:before {
    content: "\ed7b"
}

.line-icon-Tripod-withCamera:before {
    content: "\ed7c"
}

.line-icon-Tripod-withGopro:before {
    content: "\ed7d"
}

.line-icon-Trophy-2:before {
    content: "\ed7e"
}

.line-icon-Trophy:before {
    content: "\ed7f"
}

.line-icon-Truck:before {
    content: "\ed80"
}

.line-icon-Trumpet:before {
    content: "\ed81"
}

.line-icon-Tumblr:before {
    content: "\ed82"
}

.line-icon-Turkey:before {
    content: "\ed83"
}

.line-icon-Turn-Down:before {
    content: "\ed84"
}

.line-icon-Turn-Down2:before {
    content: "\ed85"
}

.line-icon-Turn-DownFromLeft:before {
    content: "\ed86"
}

.line-icon-Turn-DownFromRight:before {
    content: "\ed87"
}

.line-icon-Turn-Left:before {
    content: "\ed88"
}

.line-icon-Turn-Left3:before {
    content: "\ed89"
}

.line-icon-Turn-Right:before {
    content: "\ed8a"
}

.line-icon-Turn-Right3:before {
    content: "\ed8b"
}

.line-icon-Turn-Up:before {
    content: "\ed8c"
}

.line-icon-Turn-Up2:before {
    content: "\ed8d"
}

.line-icon-Turtle:before {
    content: "\ed8e"
}

.line-icon-Tuxedo:before {
    content: "\ed8f"
}

.line-icon-TV:before {
    content: "\ed90"
}

.line-icon-Twister:before {
    content: "\ed91"
}

.line-icon-Twitter-2:before {
    content: "\ed92"
}

.line-icon-Twitter:before {
    content: "\ed93"
}

.line-icon-Two-Fingers:before {
    content: "\ed94"
}

.line-icon-Two-FingersDrag:before {
    content: "\ed95"
}

.line-icon-Two-FingersDrag2:before {
    content: "\ed96"
}

.line-icon-Two-FingersScroll:before {
    content: "\ed97"
}

.line-icon-Two-FingersTouch:before {
    content: "\ed98"
}

.line-icon-Two-Windows:before {
    content: "\ed99"
}

.line-icon-Type-Pass:before {
    content: "\ed9a"
}

.line-icon-Ukraine:before {
    content: "\ed9b"
}

.line-icon-Umbrela:before {
    content: "\ed9c"
}

.line-icon-Umbrella-2:before {
    content: "\ed9d"
}

.line-icon-Umbrella-3:before {
    content: "\ed9e"
}

.line-icon-Under-LineText:before {
    content: "\ed9f"
}

.line-icon-Undo:before {
    content: "\eda0"
}

.line-icon-United-Kingdom:before {
    content: "\eda1"
}

.line-icon-United-States:before {
    content: "\eda2"
}

.line-icon-University-2:before {
    content: "\eda3"
}

.line-icon-University:before {
    content: "\eda4"
}

.line-icon-Unlike-2:before {
    content: "\eda5"
}

.line-icon-Unlike:before {
    content: "\eda6"
}

.line-icon-Unlock-2:before {
    content: "\eda7"
}

.line-icon-Unlock-3:before {
    content: "\eda8"
}

.line-icon-Unlock:before {
    content: "\eda9"
}

.line-icon-Up--Down:before {
    content: "\edaa"
}

.line-icon-Up--Down3:before {
    content: "\edab"
}

.line-icon-Up-2:before {
    content: "\edac"
}

.line-icon-Up-3:before {
    content: "\edad"
}

.line-icon-Up-4:before {
    content: "\edae"
}

.line-icon-Up:before {
    content: "\edaf"
}

.line-icon-Upgrade:before {
    content: "\edb0"
}

.line-icon-Upload-2:before {
    content: "\edb1"
}

.line-icon-Upload-toCloud:before {
    content: "\edb2"
}

.line-icon-Upload-Window:before {
    content: "\edb3"
}

.line-icon-Upload:before {
    content: "\edb4"
}

.line-icon-Uppercase-Text:before {
    content: "\edb5"
}

.line-icon-Upward:before {
    content: "\edb6"
}

.line-icon-URL-Window:before {
    content: "\edb7"
}

.line-icon-Usb-2:before {
    content: "\edb8"
}

.line-icon-Usb-Cable:before {
    content: "\edb9"
}

.line-icon-Usb:before {
    content: "\edba"
}

.line-icon-User:before {
    content: "\edbb"
}

.line-icon-Ustream:before {
    content: "\edbc"
}

.line-icon-Vase:before {
    content: "\edbd"
}

.line-icon-Vector-2:before {
    content: "\edbe"
}

.line-icon-Vector-3:before {
    content: "\edbf"
}

.line-icon-Vector-4:before {
    content: "\edc0"
}

.line-icon-Vector-5:before {
    content: "\edc1"
}

.line-icon-Vector:before {
    content: "\edc2"
}

.line-icon-Venn-Diagram:before {
    content: "\edc3"
}

.line-icon-Vest-2:before {
    content: "\edc4"
}

.line-icon-Vest:before {
    content: "\edc5"
}

.line-icon-Viddler:before {
    content: "\edc6"
}

.line-icon-Video-2:before {
    content: "\edc7"
}

.line-icon-Video-3:before {
    content: "\edc8"
}

.line-icon-Video-4:before {
    content: "\edc9"
}

.line-icon-Video-5:before {
    content: "\edca"
}

.line-icon-Video-6:before {
    content: "\edcb"
}

.line-icon-Video-GameController:before {
    content: "\edcc"
}

.line-icon-Video-Len:before {
    content: "\edcd"
}

.line-icon-Video-Len2:before {
    content: "\edce"
}

.line-icon-Video-Photographer:before {
    content: "\edcf"
}

.line-icon-Video-Tripod:before {
    content: "\edd0"
}

.line-icon-Video:before {
    content: "\edd1"
}

.line-icon-Vietnam:before {
    content: "\edd2"
}

.line-icon-View-Height:before {
    content: "\edd3"
}

.line-icon-View-Width:before {
    content: "\edd4"
}

.line-icon-Vimeo:before {
    content: "\edd5"
}

.line-icon-Virgo-2:before {
    content: "\edd6"
}

.line-icon-Virgo:before {
    content: "\edd7"
}

.line-icon-Virus-2:before {
    content: "\edd8"
}

.line-icon-Virus-3:before {
    content: "\edd9"
}

.line-icon-Virus:before {
    content: "\edda"
}

.line-icon-Visa:before {
    content: "\eddb"
}

.line-icon-Voice:before {
    content: "\eddc"
}

.line-icon-Voicemail:before {
    content: "\eddd"
}

.line-icon-Volleyball:before {
    content: "\edde"
}

.line-icon-Volume-Down:before {
    content: "\eddf"
}

.line-icon-Volume-Up:before {
    content: "\ede0"
}

.line-icon-VPN:before {
    content: "\ede1"
}

.line-icon-Wacom-Tablet:before {
    content: "\ede2"
}

.line-icon-Waiter:before {
    content: "\ede3"
}

.line-icon-Walkie-Talkie:before {
    content: "\ede4"
}

.line-icon-Wallet-2:before {
    content: "\ede5"
}

.line-icon-Wallet-3:before {
    content: "\ede6"
}

.line-icon-Wallet:before {
    content: "\ede7"
}

.line-icon-Warehouse:before {
    content: "\ede8"
}

.line-icon-Warning-Window:before {
    content: "\ede9"
}

.line-icon-Watch-2:before {
    content: "\edea"
}

.line-icon-Watch-3:before {
    content: "\edeb"
}

.line-icon-Watch:before {
    content: "\edec"
}

.line-icon-Wave-2:before {
    content: "\eded"
}

.line-icon-Wave:before {
    content: "\edee"
}

.line-icon-Webcam:before {
    content: "\edef"
}

.line-icon-weight-Lift:before {
    content: "\edf0"
}

.line-icon-Wheelbarrow:before {
    content: "\edf1"
}

.line-icon-Wheelchair:before {
    content: "\edf2"
}

.line-icon-Width-Window:before {
    content: "\edf3"
}

.line-icon-Wifi-2:before {
    content: "\edf4"
}

.line-icon-Wifi-Keyboard:before {
    content: "\edf5"
}

.line-icon-Wifi:before {
    content: "\edf6"
}

.line-icon-Wind-Turbine:before {
    content: "\edf7"
}

.line-icon-Windmill:before {
    content: "\edf8"
}

.line-icon-Window-2:before {
    content: "\edf9"
}

.line-icon-Window:before {
    content: "\edfa"
}

.line-icon-Windows-2:before {
    content: "\edfb"
}

.line-icon-Windows-Microsoft:before {
    content: "\edfc"
}

.line-icon-Windows:before {
    content: "\edfd"
}

.line-icon-Windsock:before {
    content: "\edfe"
}

.line-icon-Windy:before {
    content: "\edff"
}

.line-icon-Wine-Bottle:before {
    content: "\ee00"
}

.line-icon-Wine-Glass:before {
    content: "\ee01"
}

.line-icon-Wink:before {
    content: "\ee02"
}

.line-icon-Winter-2:before {
    content: "\ee03"
}

.line-icon-Winter:before {
    content: "\ee04"
}

.line-icon-Wireless:before {
    content: "\ee05"
}

.line-icon-Witch-Hat:before {
    content: "\ee06"
}

.line-icon-Witch:before {
    content: "\ee07"
}

.line-icon-Wizard:before {
    content: "\ee08"
}

.line-icon-Wolf:before {
    content: "\ee09"
}

.line-icon-Woman-Sign:before {
    content: "\ee0a"
}

.line-icon-WomanMan:before {
    content: "\ee0b"
}

.line-icon-Womans-Underwear:before {
    content: "\ee0c"
}

.line-icon-Womans-Underwear2:before {
    content: "\ee0d"
}

.line-icon-Women:before {
    content: "\ee0e"
}

.line-icon-Wonder-Woman:before {
    content: "\ee0f"
}

.line-icon-Wordpress:before {
    content: "\ee10"
}

.line-icon-Worker-Clothes:before {
    content: "\ee11"
}

.line-icon-Worker:before {
    content: "\ee12"
}

.line-icon-Wrap-Text:before {
    content: "\ee13"
}

.line-icon-Wreath:before {
    content: "\ee14"
}

.line-icon-Wrench:before {
    content: "\ee15"
}

.line-icon-X-Box:before {
    content: "\ee16"
}

.line-icon-X-ray:before {
    content: "\ee17"
}

.line-icon-Xanga:before {
    content: "\ee18"
}

.line-icon-Xing:before {
    content: "\ee19"
}

.line-icon-Yacht:before {
    content: "\ee1a"
}

.line-icon-Yahoo-Buzz:before {
    content: "\ee1b"
}

.line-icon-Yahoo:before {
    content: "\ee1c"
}

.line-icon-Yelp:before {
    content: "\ee1d"
}

.line-icon-Yes:before {
    content: "\ee1e"
}

.line-icon-Ying-Yang:before {
    content: "\ee1f"
}

.line-icon-Youtube:before {
    content: "\ee20"
}

.line-icon-Z-A:before {
    content: "\ee21"
}

.line-icon-Zebra:before {
    content: "\ee22"
}

.line-icon-Zombie:before {
    content: "\ee23"
}

.line-icon-Zoom-Gesture:before {
    content: "\ee24"
}

.line-icon-Zootool:before {
    content: "\ee25"
}

@font-face {
    font-family: icomoon-solid;
    src: url(../fonts/icomoon-solid.eot?1sxah1);
    src: url(../fonts/icomoon-solid.eot?#iefix1sxah1) format('embedded-opentype'), url(../fonts/icomoon-solid.woff?1sxah1) format('woff'), url(../fonts/icomoon-solid.ttf?1sxah1) format('truetype'), url(../fonts/icomoon-solid.svg?1sxah1#icomoon) format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap
}

[class*=" solid-icon-"],
[class^=solid-icon-] {
    font-family: icomoon-solid;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.solid-icon-A-Z:before {
    content: "\e600"
}

.solid-icon-Aa:before {
    content: "\e601"
}

.solid-icon-Add-Bag:before {
    content: "\e602"
}

.solid-icon-Add-Basket:before {
    content: "\e603"
}

.solid-icon-Add-Cart:before {
    content: "\e604"
}

.solid-icon-Add-File:before {
    content: "\e605"
}

.solid-icon-Add-SpaceAfterParagraph:before {
    content: "\e606"
}

.solid-icon-Add-SpaceBeforeParagraph:before {
    content: "\e607"
}

.solid-icon-Add-User:before {
    content: "\e608"
}

.solid-icon-Add-UserStar:before {
    content: "\e609"
}

.solid-icon-Add-Window:before {
    content: "\e60a"
}

.solid-icon-Add:before {
    content: "\e60b"
}

.solid-icon-Address-Book:before {
    content: "\e60c"
}

.solid-icon-Address-Book2:before {
    content: "\e60d"
}

.solid-icon-Administrator:before {
    content: "\e60e"
}

.solid-icon-Aerobics-2:before {
    content: "\e60f"
}

.solid-icon-Aerobics-3:before {
    content: "\e610"
}

.solid-icon-Aerobics:before {
    content: "\e611"
}

.solid-icon-Affiliate:before {
    content: "\e612"
}

.solid-icon-Aim:before {
    content: "\e613"
}

.solid-icon-Air-Balloon:before {
    content: "\e614"
}

.solid-icon-Airbrush:before {
    content: "\e615"
}

.solid-icon-Airship:before {
    content: "\e616"
}

.solid-icon-Alarm-Clock:before {
    content: "\e617"
}

.solid-icon-Alarm-Clock2:before {
    content: "\e618"
}

.solid-icon-Alarm:before {
    content: "\e619"
}

.solid-icon-Alien-2:before {
    content: "\e61a"
}

.solid-icon-Alien:before {
    content: "\e61b"
}

.solid-icon-Aligator:before {
    content: "\e61c"
}

.solid-icon-Align-Center:before {
    content: "\e61d"
}

.solid-icon-Align-JustifyAll:before {
    content: "\e61e"
}

.solid-icon-Align-JustifyCenter:before {
    content: "\e61f"
}

.solid-icon-Align-JustifyLeft:before {
    content: "\e620"
}

.solid-icon-Align-JustifyRight:before {
    content: "\e621"
}

.solid-icon-Align-Left:before {
    content: "\e622"
}

.solid-icon-Align-Right:before {
    content: "\e623"
}

.solid-icon-Alpha:before {
    content: "\e624"
}

.solid-icon-Ambulance:before {
    content: "\e625"
}

.solid-icon-AMX:before {
    content: "\e626"
}

.solid-icon-Anchor-2:before {
    content: "\e627"
}

.solid-icon-Anchor:before {
    content: "\e628"
}

.solid-icon-Android-Store:before {
    content: "\e629"
}

.solid-icon-Android:before {
    content: "\e62a"
}

.solid-icon-Angel-Smiley:before {
    content: "\e62b"
}

.solid-icon-Angel:before {
    content: "\e62c"
}

.solid-icon-Angry:before {
    content: "\e62d"
}

.solid-icon-Apple-Bite:before {
    content: "\e62e"
}

.solid-icon-Apple-Store:before {
    content: "\e62f"
}

.solid-icon-Apple:before {
    content: "\e630"
}

.solid-icon-Approved-Window:before {
    content: "\e631"
}

.solid-icon-Aquarius-2:before {
    content: "\e632"
}

.solid-icon-Aquarius:before {
    content: "\e633"
}

.solid-icon-Archery-2:before {
    content: "\e634"
}

.solid-icon-Archery:before {
    content: "\e635"
}

.solid-icon-Argentina:before {
    content: "\e636"
}

.solid-icon-Aries-2:before {
    content: "\e637"
}

.solid-icon-Aries:before {
    content: "\e638"
}

.solid-icon-Army-Key:before {
    content: "\e639"
}

.solid-icon-Arrow-Around:before {
    content: "\e63a"
}

.solid-icon-Arrow-Back:before {
    content: "\e63b"
}

.solid-icon-Arrow-Back2:before {
    content: "\e63c"
}

.solid-icon-Arrow-back3:before {
    content: "\e63d"
}

.solid-icon-Arrow-Barrier:before {
    content: "\e63e"
}

.solid-icon-Arrow-Circle:before {
    content: "\e63f"
}

.solid-icon-Arrow-Cross:before {
    content: "\e640"
}

.solid-icon-Arrow-Down:before {
    content: "\e641"
}

.solid-icon-Arrow-Down2:before {
    content: "\e642"
}

.solid-icon-Arrow-Down3:before {
    content: "\e643"
}

.solid-icon-Arrow-DowninCircle:before {
    content: "\e644"
}

.solid-icon-Arrow-Fork:before {
    content: "\e645"
}

.solid-icon-Arrow-Forward:before {
    content: "\e646"
}

.solid-icon-Arrow-Forward2:before {
    content: "\e647"
}

.solid-icon-Arrow-From:before {
    content: "\e648"
}

.solid-icon-Arrow-Inside:before {
    content: "\e649"
}

.solid-icon-Arrow-Inside45:before {
    content: "\e64a"
}

.solid-icon-Arrow-InsideGap:before {
    content: "\e64b"
}

.solid-icon-Arrow-InsideGap45:before {
    content: "\e64c"
}

.solid-icon-Arrow-Into:before {
    content: "\e64d"
}

.solid-icon-Arrow-Join:before {
    content: "\e64e"
}

.solid-icon-Arrow-Junction:before {
    content: "\e64f"
}

.solid-icon-Arrow-Left:before {
    content: "\e650"
}

.solid-icon-Arrow-Left2:before {
    content: "\e651"
}

.solid-icon-Arrow-LeftinCircle:before {
    content: "\e652"
}

.solid-icon-Arrow-Loop:before {
    content: "\e653"
}

.solid-icon-Arrow-Merge:before {
    content: "\e654"
}

.solid-icon-Arrow-Mix:before {
    content: "\e655"
}

.solid-icon-Arrow-Next:before {
    content: "\e656"
}

.solid-icon-Arrow-OutLeft:before {
    content: "\e657"
}

.solid-icon-Arrow-OutRight:before {
    content: "\e658"
}

.solid-icon-Arrow-Outside:before {
    content: "\e659"
}

.solid-icon-Arrow-Outside45:before {
    content: "\e65a"
}

.solid-icon-Arrow-OutsideGap:before {
    content: "\e65b"
}

.solid-icon-Arrow-OutsideGap45:before {
    content: "\e65c"
}

.solid-icon-Arrow-Over:before {
    content: "\e65d"
}

.solid-icon-Arrow-Refresh:before {
    content: "\e65e"
}

.solid-icon-Arrow-Refresh2:before {
    content: "\e65f"
}

.solid-icon-Arrow-Right:before {
    content: "\e660"
}

.solid-icon-Arrow-Right2:before {
    content: "\e661"
}

.solid-icon-Arrow-RightinCircle:before {
    content: "\e662"
}

.solid-icon-Arrow-Shuffle:before {
    content: "\e663"
}

.solid-icon-Arrow-Squiggly:before {
    content: "\e664"
}

.solid-icon-Arrow-Through:before {
    content: "\e665"
}

.solid-icon-Arrow-To:before {
    content: "\e666"
}

.solid-icon-Arrow-TurnLeft:before {
    content: "\e667"
}

.solid-icon-Arrow-TurnRight:before {
    content: "\e668"
}

.solid-icon-Arrow-Up:before {
    content: "\e669"
}

.solid-icon-Arrow-Up2:before {
    content: "\e66a"
}

.solid-icon-Arrow-Up3:before {
    content: "\e66b"
}

.solid-icon-Arrow-UpinCircle:before {
    content: "\e66c"
}

.solid-icon-Arrow-XLeft:before {
    content: "\e66d"
}

.solid-icon-Arrow-XRight:before {
    content: "\e66e"
}

.solid-icon-Ask:before {
    content: "\e66f"
}

.solid-icon-Assistant:before {
    content: "\e670"
}

.solid-icon-Astronaut:before {
    content: "\e671"
}

.solid-icon-At-Sign:before {
    content: "\e672"
}

.solid-icon-ATM:before {
    content: "\e673"
}

.solid-icon-Atom:before {
    content: "\e674"
}

.solid-icon-Audio:before {
    content: "\e675"
}

.solid-icon-Auto-Flash:before {
    content: "\e676"
}

.solid-icon-Autumn:before {
    content: "\e677"
}

.solid-icon-Baby-Clothes:before {
    content: "\e678"
}

.solid-icon-Baby-Clothes2:before {
    content: "\e679"
}

.solid-icon-Baby-Cry:before {
    content: "\e67a"
}

.solid-icon-Baby:before {
    content: "\e67b"
}

.solid-icon-Back-Media:before {
    content: "\e67c"
}

.solid-icon-Back-Media2:before {
    content: "\e67d"
}

.solid-icon-Back-Music:before {
    content: "\e67e"
}

.solid-icon-Back:before {
    content: "\e67f"
}

.solid-icon-Background:before {
    content: "\e680"
}

.solid-icon-Bacteria:before {
    content: "\e681"
}

.solid-icon-Bag-Coins:before {
    content: "\e682"
}

.solid-icon-Bag-Items:before {
    content: "\e683"
}

.solid-icon-Bag-Quantity:before {
    content: "\e684"
}

.solid-icon-Bag:before {
    content: "\e685"
}

.solid-icon-Bakelite:before {
    content: "\e686"
}

.solid-icon-Ballet-Shoes:before {
    content: "\e687"
}

.solid-icon-Balloon:before {
    content: "\e688"
}

.solid-icon-Banana:before {
    content: "\e689"
}

.solid-icon-Band-Aid:before {
    content: "\e68a"
}

.solid-icon-Bank:before {
    content: "\e68b"
}

.solid-icon-Bar-Chart:before {
    content: "\e68c"
}

.solid-icon-Bar-Chart2:before {
    content: "\e68d"
}

.solid-icon-Bar-Chart3:before {
    content: "\e68e"
}

.solid-icon-Bar-Chart4:before {
    content: "\e68f"
}

.solid-icon-Bar-Chart5:before {
    content: "\e690"
}

.solid-icon-Bar-Code:before {
    content: "\e691"
}

.solid-icon-Barricade-2:before {
    content: "\e692"
}

.solid-icon-Barricade:before {
    content: "\e693"
}

.solid-icon-Baseball:before {
    content: "\e694"
}

.solid-icon-Basket-Ball:before {
    content: "\e695"
}

.solid-icon-Basket-Coins:before {
    content: "\e696"
}

.solid-icon-Basket-Items:before {
    content: "\e697"
}

.solid-icon-Basket-Quantity:before {
    content: "\e698"
}

.solid-icon-Bat-2:before {
    content: "\e699"
}

.solid-icon-Bat:before {
    content: "\e69a"
}

.solid-icon-Bathrobe:before {
    content: "\e69b"
}

.solid-icon-Batman-Mask:before {
    content: "\e69c"
}

.solid-icon-Battery-0:before {
    content: "\e69d"
}

.solid-icon-Battery-25:before {
    content: "\e69e"
}

.solid-icon-Battery-50:before {
    content: "\e69f"
}

.solid-icon-Battery-75:before {
    content: "\e6a0"
}

.solid-icon-Battery-100:before {
    content: "\e6a1"
}

.solid-icon-Battery-Charge:before {
    content: "\e6a2"
}

.solid-icon-Bear:before {
    content: "\e6a3"
}

.solid-icon-Beard-2:before {
    content: "\e6a4"
}

.solid-icon-Beard-3:before {
    content: "\e6a5"
}

.solid-icon-Beard:before {
    content: "\e6a6"
}

.solid-icon-Bebo:before {
    content: "\e6a7"
}

.solid-icon-Bee:before {
    content: "\e6a8"
}

.solid-icon-Beer-Glass:before {
    content: "\e6a9"
}

.solid-icon-Beer:before {
    content: "\e6aa"
}

.solid-icon-Behance:before {
    content: "\e6ab"
}

.solid-icon-Bell-2:before {
    content: "\e6ac"
}

.solid-icon-Bell:before {
    content: "\e6ad"
}

.solid-icon-Belt-2:before {
    content: "\e6ae"
}

.solid-icon-Belt-3:before {
    content: "\e6af"
}

.solid-icon-Belt:before {
    content: "\e6b0"
}

.solid-icon-Berlin-Tower:before {
    content: "\e6b1"
}

.solid-icon-Beta:before {
    content: "\e6b2"
}

.solid-icon-Betvibes:before {
    content: "\e6b3"
}

.solid-icon-Bicycle-2:before {
    content: "\e6b4"
}

.solid-icon-Bicycle-3:before {
    content: "\e6b5"
}

.solid-icon-Bicycle:before {
    content: "\e6b6"
}

.solid-icon-Big-Bang:before {
    content: "\e6b7"
}

.solid-icon-Big-Data:before {
    content: "\e6b8"
}

.solid-icon-Bike-Helmet:before {
    content: "\e6b9"
}

.solid-icon-Bikini:before {
    content: "\e6ba"
}

.solid-icon-Bilk-Bottle2:before {
    content: "\e6bb"
}

.solid-icon-Billing:before {
    content: "\e6bc"
}

.solid-icon-Bing:before {
    content: "\e6bd"
}

.solid-icon-Binocular:before {
    content: "\e6be"
}

.solid-icon-Bio-Hazard:before {
    content: "\e6bf"
}

.solid-icon-Biotech:before {
    content: "\e6c0"
}

.solid-icon-Bird-DeliveringLetter:before {
    content: "\e6c1"
}

.solid-icon-Bird:before {
    content: "\e6c2"
}

.solid-icon-Birthday-Cake:before {
    content: "\e6c3"
}

.solid-icon-Bisexual:before {
    content: "\e6c4"
}

.solid-icon-Bishop:before {
    content: "\e6c5"
}

.solid-icon-Bitcoin:before {
    content: "\e6c6"
}

.solid-icon-Black-Cat:before {
    content: "\e6c7"
}

.solid-icon-Blackboard:before {
    content: "\e6c8"
}

.solid-icon-Blinklist:before {
    content: "\e6c9"
}

.solid-icon-Block-Cloud:before {
    content: "\e6ca"
}

.solid-icon-Block-Window:before {
    content: "\e6cb"
}

.solid-icon-Blogger:before {
    content: "\e6cc"
}

.solid-icon-Blood:before {
    content: "\e6cd"
}

.solid-icon-Blouse:before {
    content: "\e6ce"
}

.solid-icon-Blueprint:before {
    content: "\e6cf"
}

.solid-icon-Board:before {
    content: "\e6d0"
}

.solid-icon-Bodybuilding:before {
    content: "\e6d1"
}

.solid-icon-Bold-Text:before {
    content: "\e6d2"
}

.solid-icon-Bone:before {
    content: "\e6d3"
}

.solid-icon-Bones:before {
    content: "\e6d4"
}

.solid-icon-Book:before {
    content: "\e6d5"
}

.solid-icon-Bookmark:before {
    content: "\e6d6"
}

.solid-icon-Books-2:before {
    content: "\e6d7"
}

.solid-icon-Books:before {
    content: "\e6d8"
}

.solid-icon-Boom:before {
    content: "\e6d9"
}

.solid-icon-Boot-2:before {
    content: "\e6da"
}

.solid-icon-Boot:before {
    content: "\e6db"
}

.solid-icon-Bottom-ToTop:before {
    content: "\e6dc"
}

.solid-icon-Bow-2:before {
    content: "\e6dd"
}

.solid-icon-Bow-3:before {
    content: "\e6de"
}

.solid-icon-Bow-4:before {
    content: "\e6df"
}

.solid-icon-Bow-5:before {
    content: "\e6e0"
}

.solid-icon-Bow-6:before {
    content: "\e6e1"
}

.solid-icon-Bow:before {
    content: "\e6e2"
}

.solid-icon-Bowling-2:before {
    content: "\e6e3"
}

.solid-icon-Bowling:before {
    content: "\e6e4"
}

.solid-icon-Box-Close:before {
    content: "\e6e5"
}

.solid-icon-Box-Full:before {
    content: "\e6e6"
}

.solid-icon-Box-Gloves:before {
    content: "\e6e7"
}

.solid-icon-Box-Open:before {
    content: "\e6e8"
}

.solid-icon-Box-withFolders:before {
    content: "\e6e9"
}

.solid-icon-Box:before {
    content: "\e6ea"
}

.solid-icon-Boy:before {
    content: "\e6eb"
}

.solid-icon-Bra:before {
    content: "\e6ec"
}

.solid-icon-Brain-2:before {
    content: "\e6ed"
}

.solid-icon-Brain-3:before {
    content: "\e6ee"
}

.solid-icon-Brain:before {
    content: "\e6ef"
}

.solid-icon-Brazil:before {
    content: "\e6f0"
}

.solid-icon-Bread-2:before {
    content: "\e6f1"
}

.solid-icon-Bread:before {
    content: "\e6f2"
}

.solid-icon-Bridge:before {
    content: "\e6f3"
}

.solid-icon-Brightkite:before {
    content: "\e6f4"
}

.solid-icon-Broke-Link2:before {
    content: "\e6f5"
}

.solid-icon-Broken-Link:before {
    content: "\e6f6"
}

.solid-icon-Broom:before {
    content: "\e6f7"
}

.solid-icon-Brush:before {
    content: "\e6f8"
}

.solid-icon-Bucket:before {
    content: "\e6f9"
}

.solid-icon-Bug:before {
    content: "\e6fa"
}

.solid-icon-Building:before {
    content: "\e6fb"
}

.solid-icon-Bulleted-List:before {
    content: "\e6fc"
}

.solid-icon-Bus-2:before {
    content: "\e6fd"
}

.solid-icon-Bus:before {
    content: "\e6fe"
}

.solid-icon-Business-Man:before {
    content: "\e6ff"
}

.solid-icon-Business-ManWoman:before {
    content: "\e700"
}

.solid-icon-Business-Mens:before {
    content: "\e701"
}

.solid-icon-Business-Woman:before {
    content: "\e702"
}

.solid-icon-Butterfly:before {
    content: "\e703"
}

.solid-icon-Button:before {
    content: "\e704"
}

.solid-icon-Cable-Car:before {
    content: "\e705"
}

.solid-icon-Cake:before {
    content: "\e706"
}

.solid-icon-Calculator-2:before {
    content: "\e707"
}

.solid-icon-Calculator-3:before {
    content: "\e708"
}

.solid-icon-Calculator:before {
    content: "\e709"
}

.solid-icon-Calendar-2:before {
    content: "\e70a"
}

.solid-icon-Calendar-3:before {
    content: "\e70b"
}

.solid-icon-Calendar-4:before {
    content: "\e70c"
}

.solid-icon-Calendar-Clock:before {
    content: "\e70d"
}

.solid-icon-Calendar:before {
    content: "\e70e"
}

.solid-icon-Camel:before {
    content: "\e70f"
}

.solid-icon-Camera-2:before {
    content: "\e710"
}

.solid-icon-Camera-3:before {
    content: "\e711"
}

.solid-icon-Camera-4:before {
    content: "\e712"
}

.solid-icon-Camera-5:before {
    content: "\e713"
}

.solid-icon-Camera-Back:before {
    content: "\e714"
}

.solid-icon-Camera:before {
    content: "\e715"
}

.solid-icon-Can-2:before {
    content: "\e716"
}

.solid-icon-Can:before {
    content: "\e717"
}

.solid-icon-Canada:before {
    content: "\e718"
}

.solid-icon-Cancer-2:before {
    content: "\e719"
}

.solid-icon-Cancer-3:before {
    content: "\e71a"
}

.solid-icon-Cancer:before {
    content: "\e71b"
}

.solid-icon-Candle:before {
    content: "\e71c"
}

.solid-icon-Candy-Cane:before {
    content: "\e71d"
}

.solid-icon-Candy:before {
    content: "\e71e"
}

.solid-icon-Cannon:before {
    content: "\e71f"
}

.solid-icon-Cap-2:before {
    content: "\e720"
}

.solid-icon-Cap-3:before {
    content: "\e721"
}

.solid-icon-Cap-Smiley:before {
    content: "\e722"
}

.solid-icon-Cap:before {
    content: "\e723"
}

.solid-icon-Capricorn-2:before {
    content: "\e724"
}

.solid-icon-Capricorn:before {
    content: "\e725"
}

.solid-icon-Car-2:before {
    content: "\e726"
}

.solid-icon-Car-3:before {
    content: "\e727"
}

.solid-icon-Car-Coins:before {
    content: "\e728"
}

.solid-icon-Car-Items:before {
    content: "\e729"
}

.solid-icon-Car-Wheel:before {
    content: "\e72a"
}

.solid-icon-Car:before {
    content: "\e72b"
}

.solid-icon-Cardigan:before {
    content: "\e72c"
}

.solid-icon-Cardiovascular:before {
    content: "\e72d"
}

.solid-icon-Cart-Quantity:before {
    content: "\e72e"
}

.solid-icon-Casette-Tape:before {
    content: "\e72f"
}

.solid-icon-Cash-Register:before {
    content: "\e730"
}

.solid-icon-Cash-register2:before {
    content: "\e731"
}

.solid-icon-Castle:before {
    content: "\e732"
}

.solid-icon-Cat:before {
    content: "\e733"
}

.solid-icon-Cathedral:before {
    content: "\e734"
}

.solid-icon-Cauldron:before {
    content: "\e735"
}

.solid-icon-CD-2:before {
    content: "\e736"
}

.solid-icon-CD-Cover:before {
    content: "\e737"
}

.solid-icon-CD:before {
    content: "\e738"
}

.solid-icon-Cello:before {
    content: "\e739"
}

.solid-icon-Celsius:before {
    content: "\e73a"
}

.solid-icon-Chacked-Flag:before {
    content: "\e73b"
}

.solid-icon-Chair:before {
    content: "\e73c"
}

.solid-icon-Charger:before {
    content: "\e73d"
}

.solid-icon-Check-2:before {
    content: "\e73e"
}

.solid-icon-Check:before {
    content: "\e73f"
}

.solid-icon-Checked-User:before {
    content: "\e740"
}

.solid-icon-Checkmate:before {
    content: "\e741"
}

.solid-icon-Checkout-Bag:before {
    content: "\e742"
}

.solid-icon-Checkout-Basket:before {
    content: "\e743"
}

.solid-icon-Checkout:before {
    content: "\e744"
}

.solid-icon-Cheese:before {
    content: "\e745"
}

.solid-icon-Cheetah:before {
    content: "\e746"
}

.solid-icon-Chef-Hat:before {
    content: "\e747"
}

.solid-icon-Chef-Hat2:before {
    content: "\e748"
}

.solid-icon-Chef:before {
    content: "\e749"
}

.solid-icon-Chemical-2:before {
    content: "\e74a"
}

.solid-icon-Chemical-3:before {
    content: "\e74b"
}

.solid-icon-Chemical-4:before {
    content: "\e74c"
}

.solid-icon-Chemical-5:before {
    content: "\e74d"
}

.solid-icon-Chemical:before {
    content: "\e74e"
}

.solid-icon-Chess-Board:before {
    content: "\e74f"
}

.solid-icon-Chess:before {
    content: "\e750"
}

.solid-icon-Chicken:before {
    content: "\e751"
}

.solid-icon-Chile:before {
    content: "\e752"
}

.solid-icon-Chimney:before {
    content: "\e753"
}

.solid-icon-China:before {
    content: "\e754"
}

.solid-icon-Chinese-Temple:before {
    content: "\e755"
}

.solid-icon-Chip:before {
    content: "\e756"
}

.solid-icon-Chopsticks-2:before {
    content: "\e757"
}

.solid-icon-Chopsticks:before {
    content: "\e758"
}

.solid-icon-Christmas-Ball:before {
    content: "\e759"
}

.solid-icon-Christmas-Bell:before {
    content: "\e75a"
}

.solid-icon-Christmas-Candle:before {
    content: "\e75b"
}

.solid-icon-Christmas-Hat:before {
    content: "\e75c"
}

.solid-icon-Christmas-Sleigh:before {
    content: "\e75d"
}

.solid-icon-Christmas-Snowman:before {
    content: "\e75e"
}

.solid-icon-Christmas-Sock:before {
    content: "\e75f"
}

.solid-icon-Christmas-Tree:before {
    content: "\e760"
}

.solid-icon-Christmas:before {
    content: "\e761"
}

.solid-icon-Chrome:before {
    content: "\e762"
}

.solid-icon-Chrysler-Building:before {
    content: "\e763"
}

.solid-icon-Cinema:before {
    content: "\e764"
}

.solid-icon-Circular-Point:before {
    content: "\e765"
}

.solid-icon-City-Hall:before {
    content: "\e766"
}

.solid-icon-Clamp:before {
    content: "\e767"
}

.solid-icon-Clapperboard-Close:before {
    content: "\e768"
}

.solid-icon-Clapperboard-Open:before {
    content: "\e769"
}

.solid-icon-Claps:before {
    content: "\e76a"
}

.solid-icon-Clef:before {
    content: "\e76b"
}

.solid-icon-Clinic:before {
    content: "\e76c"
}

.solid-icon-Clock-2:before {
    content: "\e76d"
}

.solid-icon-Clock-3:before {
    content: "\e76e"
}

.solid-icon-Clock-4:before {
    content: "\e76f"
}

.solid-icon-Clock-Back:before {
    content: "\e770"
}

.solid-icon-Clock-Forward:before {
    content: "\e771"
}

.solid-icon-Clock:before {
    content: "\e772"
}

.solid-icon-Close-Window:before {
    content: "\e773"
}

.solid-icon-Close:before {
    content: "\e774"
}

.solid-icon-Clothing-Store:before {
    content: "\e775"
}

.solid-icon-Cloud--:before {
    content: "\e776"
}

.solid-icon-Cloud-:before {
    content: "\e777"
}

.solid-icon-Cloud-Camera:before {
    content: "\e778"
}

.solid-icon-Cloud-Computer:before {
    content: "\e779"
}

.solid-icon-Cloud-Email:before {
    content: "\e77a"
}

.solid-icon-Cloud-Hail:before {
    content: "\e77b"
}

.solid-icon-Cloud-Laptop:before {
    content: "\e77c"
}

.solid-icon-Cloud-Lock:before {
    content: "\e77d"
}

.solid-icon-Cloud-Moon:before {
    content: "\e77e"
}

.solid-icon-Cloud-Music:before {
    content: "\e77f"
}

.solid-icon-Cloud-Picture:before {
    content: "\e780"
}

.solid-icon-Cloud-Rain:before {
    content: "\e781"
}

.solid-icon-Cloud-Remove:before {
    content: "\e782"
}

.solid-icon-Cloud-Secure:before {
    content: "\e783"
}

.solid-icon-Cloud-Settings:before {
    content: "\e784"
}

.solid-icon-Cloud-Smartphone:before {
    content: "\e785"
}

.solid-icon-Cloud-Snow:before {
    content: "\e786"
}

.solid-icon-Cloud-Sun:before {
    content: "\e787"
}

.solid-icon-Cloud-Tablet:before {
    content: "\e788"
}

.solid-icon-Cloud-Video:before {
    content: "\e789"
}

.solid-icon-Cloud-Weather:before {
    content: "\e78a"
}

.solid-icon-Cloud:before {
    content: "\e78b"
}

.solid-icon-Clouds-Weather:before {
    content: "\e78c"
}

.solid-icon-Clouds:before {
    content: "\e78d"
}

.solid-icon-Clown:before {
    content: "\e78e"
}

.solid-icon-CMYK:before {
    content: "\e78f"
}

.solid-icon-Coat:before {
    content: "\e790"
}

.solid-icon-Cocktail:before {
    content: "\e791"
}

.solid-icon-Coconut:before {
    content: "\e792"
}

.solid-icon-Code-Window:before {
    content: "\e793"
}

.solid-icon-Coding:before {
    content: "\e794"
}

.solid-icon-Coffee-2:before {
    content: "\e795"
}

.solid-icon-Coffee-Bean:before {
    content: "\e796"
}

.solid-icon-Coffee-Machine:before {
    content: "\e797"
}

.solid-icon-Coffee-toGo:before {
    content: "\e798"
}

.solid-icon-Coffee:before {
    content: "\e799"
}

.solid-icon-Coffin:before {
    content: "\e79a"
}

.solid-icon-Coin:before {
    content: "\e79b"
}

.solid-icon-Coins-2:before {
    content: "\e79c"
}

.solid-icon-Coins-3:before {
    content: "\e79d"
}

.solid-icon-Coins:before {
    content: "\e79e"
}

.solid-icon-Colombia:before {
    content: "\e79f"
}

.solid-icon-Colosseum:before {
    content: "\e7a0"
}

.solid-icon-Column-2:before {
    content: "\e7a1"
}

.solid-icon-Column-3:before {
    content: "\e7a2"
}

.solid-icon-Column:before {
    content: "\e7a3"
}

.solid-icon-Comb-2:before {
    content: "\e7a4"
}

.solid-icon-Comb:before {
    content: "\e7a5"
}

.solid-icon-Communication-Tower:before {
    content: "\e7a6"
}

.solid-icon-Communication-Tower2:before {
    content: "\e7a7"
}

.solid-icon-Compass-2:before {
    content: "\e7a8"
}

.solid-icon-Compass-3:before {
    content: "\e7a9"
}

.solid-icon-Compass-4:before {
    content: "\e7aa"
}

.solid-icon-Compass-Rose:before {
    content: "\e7ab"
}

.solid-icon-Compass:before {
    content: "\e7ac"
}

.solid-icon-Computer-2:before {
    content: "\e7ad"
}

.solid-icon-Computer-3:before {
    content: "\e7ae"
}

.solid-icon-Computer-Secure:before {
    content: "\e7af"
}

.solid-icon-Computer:before {
    content: "\e7b0"
}

.solid-icon-Conference:before {
    content: "\e7b1"
}

.solid-icon-Confused:before {
    content: "\e7b2"
}

.solid-icon-Conservation:before {
    content: "\e7b3"
}

.solid-icon-Consulting:before {
    content: "\e7b4"
}

.solid-icon-Contrast:before {
    content: "\e7b5"
}

.solid-icon-Control-2:before {
    content: "\e7b6"
}

.solid-icon-Control:before {
    content: "\e7b7"
}

.solid-icon-Cookie-Man:before {
    content: "\e7b8"
}

.solid-icon-Cookies:before {
    content: "\e7b9"
}

.solid-icon-Cool-Guy:before {
    content: "\e7ba"
}

.solid-icon-Cool:before {
    content: "\e7bb"
}

.solid-icon-Copyright:before {
    content: "\e7bc"
}

.solid-icon-Costume:before {
    content: "\e7bd"
}

.solid-icon-Couple-Sign:before {
    content: "\e7be"
}

.solid-icon-Cow:before {
    content: "\e7bf"
}

.solid-icon-CPU:before {
    content: "\e7c0"
}

.solid-icon-Crane:before {
    content: "\e7c1"
}

.solid-icon-Cranium:before {
    content: "\e7c2"
}

.solid-icon-Credit-Card:before {
    content: "\e7c3"
}

.solid-icon-Credit-Card2:before {
    content: "\e7c4"
}

.solid-icon-Credit-Card3:before {
    content: "\e7c5"
}

.solid-icon-Cricket:before {
    content: "\e7c6"
}

.solid-icon-Criminal:before {
    content: "\e7c7"
}

.solid-icon-Croissant:before {
    content: "\e7c8"
}

.solid-icon-Crop-2:before {
    content: "\e7c9"
}

.solid-icon-Crop-3:before {
    content: "\e7ca"
}

.solid-icon-Crown-2:before {
    content: "\e7cb"
}

.solid-icon-Crown:before {
    content: "\e7cc"
}

.solid-icon-Crying:before {
    content: "\e7cd"
}

.solid-icon-Cube-Molecule:before {
    content: "\e7ce"
}

.solid-icon-Cube-Molecule2:before {
    content: "\e7cf"
}

.solid-icon-Cupcake:before {
    content: "\e7d0"
}

.solid-icon-Cursor-Click:before {
    content: "\e7d1"
}

.solid-icon-Cursor-Click2:before {
    content: "\e7d2"
}

.solid-icon-Cursor-Move:before {
    content: "\e7d3"
}

.solid-icon-Cursor-Move2:before {
    content: "\e7d4"
}

.solid-icon-Cursor-Select:before {
    content: "\e7d5"
}

.solid-icon-Cursor:before {
    content: "\e7d6"
}

.solid-icon-D-Eyeglasses:before {
    content: "\e7d7"
}

.solid-icon-D-Eyeglasses2:before {
    content: "\e7d8"
}

.solid-icon-Dam:before {
    content: "\e7d9"
}

.solid-icon-Danemark:before {
    content: "\e7da"
}

.solid-icon-Danger-2:before {
    content: "\e7db"
}

.solid-icon-Danger:before {
    content: "\e7dc"
}

.solid-icon-Dashboard:before {
    content: "\e7dd"
}

.solid-icon-Data-Backup:before {
    content: "\e7de"
}

.solid-icon-Data-Block:before {
    content: "\e7df"
}

.solid-icon-Data-Center:before {
    content: "\e7e0"
}

.solid-icon-Data-Clock:before {
    content: "\e7e1"
}

.solid-icon-Data-Cloud:before {
    content: "\e7e2"
}

.solid-icon-Data-Compress:before {
    content: "\e7e3"
}

.solid-icon-Data-Copy:before {
    content: "\e7e4"
}

.solid-icon-Data-Download:before {
    content: "\e7e5"
}

.solid-icon-Data-Financial:before {
    content: "\e7e6"
}

.solid-icon-Data-Key:before {
    content: "\e7e7"
}

.solid-icon-Data-Lock:before {
    content: "\e7e8"
}

.solid-icon-Data-Network:before {
    content: "\e7e9"
}

.solid-icon-Data-Password:before {
    content: "\e7ea"
}

.solid-icon-Data-Power:before {
    content: "\e7eb"
}

.solid-icon-Data-Refresh:before {
    content: "\e7ec"
}

.solid-icon-Data-Save:before {
    content: "\e7ed"
}

.solid-icon-Data-Search:before {
    content: "\e7ee"
}

.solid-icon-Data-Security:before {
    content: "\e7ef"
}

.solid-icon-Data-Settings:before {
    content: "\e7f0"
}

.solid-icon-Data-Sharing:before {
    content: "\e7f1"
}

.solid-icon-Data-Shield:before {
    content: "\e7f2"
}

.solid-icon-Data-Signal:before {
    content: "\e7f3"
}

.solid-icon-Data-Storage:before {
    content: "\e7f4"
}

.solid-icon-Data-Stream:before {
    content: "\e7f5"
}

.solid-icon-Data-Transfer:before {
    content: "\e7f6"
}

.solid-icon-Data-Unlock:before {
    content: "\e7f7"
}

.solid-icon-Data-Upload:before {
    content: "\e7f8"
}

.solid-icon-Data-Yes:before {
    content: "\e7f9"
}

.solid-icon-Data:before {
    content: "\e7fa"
}

.solid-icon-David-Star:before {
    content: "\e7fb"
}

.solid-icon-Daylight:before {
    content: "\e7fc"
}

.solid-icon-Death:before {
    content: "\e7fd"
}

.solid-icon-Debian:before {
    content: "\e7fe"
}

.solid-icon-Dec:before {
    content: "\e7ff"
}

.solid-icon-Decrase-Inedit:before {
    content: "\e800"
}

.solid-icon-Deer-2:before {
    content: "\e801"
}

.solid-icon-Deer:before {
    content: "\e802"
}

.solid-icon-Delete-File:before {
    content: "\e803"
}

.solid-icon-Delete-Window:before {
    content: "\e804"
}

.solid-icon-Delicious:before {
    content: "\e805"
}

.solid-icon-Depression:before {
    content: "\e806"
}

.solid-icon-Deviantart:before {
    content: "\e807"
}

.solid-icon-Device-SyncwithCloud:before {
    content: "\e808"
}

.solid-icon-Diamond:before {
    content: "\e809"
}

.solid-icon-Dice-2:before {
    content: "\e80a"
}

.solid-icon-Dice:before {
    content: "\e80b"
}

.solid-icon-Digg:before {
    content: "\e80c"
}

.solid-icon-Digital-Drawing:before {
    content: "\e80d"
}

.solid-icon-Diigo:before {
    content: "\e80e"
}

.solid-icon-Dinosaur:before {
    content: "\e80f"
}

.solid-icon-Diploma-2:before {
    content: "\e810"
}

.solid-icon-Diploma:before {
    content: "\e811"
}

.solid-icon-Direction-East:before {
    content: "\e812"
}

.solid-icon-Direction-North:before {
    content: "\e813"
}

.solid-icon-Direction-South:before {
    content: "\e814"
}

.solid-icon-Direction-West:before {
    content: "\e815"
}

.solid-icon-Director:before {
    content: "\e816"
}

.solid-icon-Disk:before {
    content: "\e817"
}

.solid-icon-Dj:before {
    content: "\e818"
}

.solid-icon-DNA-2:before {
    content: "\e819"
}

.solid-icon-DNA-Helix:before {
    content: "\e81a"
}

.solid-icon-DNA:before {
    content: "\e81b"
}

.solid-icon-Doctor:before {
    content: "\e81c"
}

.solid-icon-Dog:before {
    content: "\e81d"
}

.solid-icon-Dollar-Sign:before {
    content: "\e81e"
}

.solid-icon-Dollar-Sign2:before {
    content: "\e81f"
}

.solid-icon-Dollar:before {
    content: "\e820"
}

.solid-icon-Dolphin:before {
    content: "\e821"
}

.solid-icon-Domino:before {
    content: "\e822"
}

.solid-icon-Door-Hanger:before {
    content: "\e823"
}

.solid-icon-Door:before {
    content: "\e824"
}

.solid-icon-Doplr:before {
    content: "\e825"
}

.solid-icon-Double-Circle:before {
    content: "\e826"
}

.solid-icon-Double-Tap:before {
    content: "\e827"
}

.solid-icon-Doughnut:before {
    content: "\e828"
}

.solid-icon-Dove:before {
    content: "\e829"
}

.solid-icon-Down-2:before {
    content: "\e82a"
}

.solid-icon-Down-3:before {
    content: "\e82b"
}

.solid-icon-Down-4:before {
    content: "\e82c"
}

.solid-icon-Down:before {
    content: "\e82d"
}

.solid-icon-Download-2:before {
    content: "\e82e"
}

.solid-icon-Download-fromCloud:before {
    content: "\e82f"
}

.solid-icon-Download-Window:before {
    content: "\e830"
}

.solid-icon-Download:before {
    content: "\e831"
}

.solid-icon-Downward:before {
    content: "\e832"
}

.solid-icon-Drag-Down:before {
    content: "\e833"
}

.solid-icon-Drag-Left:before {
    content: "\e834"
}

.solid-icon-Drag-Right:before {
    content: "\e835"
}

.solid-icon-Drag-Up:before {
    content: "\e836"
}

.solid-icon-Drag:before {
    content: "\e837"
}

.solid-icon-Dress:before {
    content: "\e838"
}

.solid-icon-Dribble:before {
    content: "\e839"
}

.solid-icon-Drill-2:before {
    content: "\e83a"
}

.solid-icon-Drill:before {
    content: "\e83b"
}

.solid-icon-Drop:before {
    content: "\e83c"
}

.solid-icon-Dropbox:before {
    content: "\e83d"
}

.solid-icon-Drum:before {
    content: "\e83e"
}

.solid-icon-Dry:before {
    content: "\e83f"
}

.solid-icon-Duck:before {
    content: "\e840"
}

.solid-icon-Dumbbell:before {
    content: "\e841"
}

.solid-icon-Duplicate-Layer:before {
    content: "\e842"
}

.solid-icon-Duplicate-Window:before {
    content: "\e843"
}

.solid-icon-DVD:before {
    content: "\e844"
}

.solid-icon-Eagle:before {
    content: "\e845"
}

.solid-icon-Ear:before {
    content: "\e846"
}

.solid-icon-Earphones-2:before {
    content: "\e847"
}

.solid-icon-Earphones:before {
    content: "\e848"
}

.solid-icon-Eci-Icon:before {
    content: "\e849"
}

.solid-icon-Edit-Map:before {
    content: "\e84a"
}

.solid-icon-Edit:before {
    content: "\e84b"
}

.solid-icon-Eggs:before {
    content: "\e84c"
}

.solid-icon-Egypt:before {
    content: "\e84d"
}

.solid-icon-Eifel-Tower:before {
    content: "\e84e"
}

.solid-icon-Eject-2:before {
    content: "\e84f"
}

.solid-icon-Eject:before {
    content: "\e850"
}

.solid-icon-El-Castillo:before {
    content: "\e851"
}

.solid-icon-Elbow:before {
    content: "\e852"
}

.solid-icon-Electric-Guitar:before {
    content: "\e853"
}

.solid-icon-Electricity:before {
    content: "\e854"
}

.solid-icon-Elephant:before {
    content: "\e855"
}

.solid-icon-Email:before {
    content: "\e856"
}

.solid-icon-Embassy:before {
    content: "\e857"
}

.solid-icon-Empire-StateBuilding:before {
    content: "\e858"
}

.solid-icon-Empty-Box:before {
    content: "\e859"
}

.solid-icon-End2:before {
    content: "\e85a"
}

.solid-icon-End-2:before {
    content: "\e85b"
}

.solid-icon-End:before {
    content: "\e85c"
}

.solid-icon-Endways:before {
    content: "\e85d"
}

.solid-icon-Engineering:before {
    content: "\e85e"
}

.solid-icon-Envelope-2:before {
    content: "\e85f"
}

.solid-icon-Envelope:before {
    content: "\e860"
}

.solid-icon-Environmental-2:before {
    content: "\e861"
}

.solid-icon-Environmental-3:before {
    content: "\e862"
}

.solid-icon-Environmental:before {
    content: "\e863"
}

.solid-icon-Equalizer:before {
    content: "\e864"
}

.solid-icon-Eraser-2:before {
    content: "\e865"
}

.solid-icon-Eraser-3:before {
    content: "\e866"
}

.solid-icon-Eraser:before {
    content: "\e867"
}

.solid-icon-Error-404Window:before {
    content: "\e868"
}

.solid-icon-Euro-Sign:before {
    content: "\e869"
}

.solid-icon-Euro-Sign2:before {
    content: "\e86a"
}

.solid-icon-Euro:before {
    content: "\e86b"
}

.solid-icon-Evernote:before {
    content: "\e86c"
}

.solid-icon-Evil:before {
    content: "\e86d"
}

.solid-icon-Explode:before {
    content: "\e86e"
}

.solid-icon-Eye-2:before {
    content: "\e86f"
}

.solid-icon-Eye-Blind:before {
    content: "\e870"
}

.solid-icon-Eye-Invisible:before {
    content: "\e871"
}

.solid-icon-Eye-Scan:before {
    content: "\e872"
}

.solid-icon-Eye-Visible:before {
    content: "\e873"
}

.solid-icon-Eye:before {
    content: "\e874"
}

.solid-icon-Eyebrow-2:before {
    content: "\e875"
}

.solid-icon-Eyebrow-3:before {
    content: "\e876"
}

.solid-icon-Eyebrow:before {
    content: "\e877"
}

.solid-icon-Eyeglasses-Smiley:before {
    content: "\e878"
}

.solid-icon-Eyeglasses-Smiley2:before {
    content: "\e879"
}

.solid-icon-Face-Style:before {
    content: "\e87a"
}

.solid-icon-Face-Style2:before {
    content: "\e87b"
}

.solid-icon-Face-Style3:before {
    content: "\e87c"
}

.solid-icon-Face-Style4:before {
    content: "\e87d"
}

.solid-icon-Face-Style5:before {
    content: "\e87e"
}

.solid-icon-Face-Style6:before {
    content: "\e87f"
}

.solid-icon-Facebook-2:before {
    content: "\e880"
}

.solid-icon-Facebook:before {
    content: "\e881"
}

.solid-icon-Factory-2:before {
    content: "\e882"
}

.solid-icon-Factory:before {
    content: "\e883"
}

.solid-icon-Fahrenheit:before {
    content: "\e884"
}

.solid-icon-Family-Sign:before {
    content: "\e885"
}

.solid-icon-Fan:before {
    content: "\e886"
}

.solid-icon-Farmer:before {
    content: "\e887"
}

.solid-icon-Fashion:before {
    content: "\e888"
}

.solid-icon-Favorite-Window:before {
    content: "\e889"
}

.solid-icon-Fax:before {
    content: "\e88a"
}

.solid-icon-Feather:before {
    content: "\e88b"
}

.solid-icon-Feedburner:before {
    content: "\e88c"
}

.solid-icon-Female-2:before {
    content: "\e88d"
}

.solid-icon-Female-Sign:before {
    content: "\e88e"
}

.solid-icon-Female:before {
    content: "\e88f"
}

.solid-icon-File-Block:before {
    content: "\e890"
}

.solid-icon-File-Bookmark:before {
    content: "\e891"
}

.solid-icon-File-Chart:before {
    content: "\e892"
}

.solid-icon-File-Clipboard:before {
    content: "\e893"
}

.solid-icon-File-ClipboardFileText:before {
    content: "\e894"
}

.solid-icon-File-ClipboardTextImage:before {
    content: "\e895"
}

.solid-icon-File-Cloud:before {
    content: "\e896"
}

.solid-icon-File-Copy:before {
    content: "\e897"
}

.solid-icon-File-Copy2:before {
    content: "\e898"
}

.solid-icon-File-CSV:before {
    content: "\e899"
}

.solid-icon-File-Download:before {
    content: "\e89a"
}

.solid-icon-File-Edit:before {
    content: "\e89b"
}

.solid-icon-File-Excel:before {
    content: "\e89c"
}

.solid-icon-File-Favorite:before {
    content: "\e89d"
}

.solid-icon-File-Fire:before {
    content: "\e89e"
}

.solid-icon-File-Graph:before {
    content: "\e89f"
}

.solid-icon-File-Hide:before {
    content: "\e8a0"
}

.solid-icon-File-Horizontal:before {
    content: "\e8a1"
}

.solid-icon-File-HorizontalText:before {
    content: "\e8a2"
}

.solid-icon-File-HTML:before {
    content: "\e8a3"
}

.solid-icon-File-JPG:before {
    content: "\e8a4"
}

.solid-icon-File-Link:before {
    content: "\e8a5"
}

.solid-icon-File-Loading:before {
    content: "\e8a6"
}

.solid-icon-File-Lock:before {
    content: "\e8a7"
}

.solid-icon-File-Love:before {
    content: "\e8a8"
}

.solid-icon-File-Music:before {
    content: "\e8a9"
}

.solid-icon-File-Network:before {
    content: "\e8aa"
}

.solid-icon-File-Pictures:before {
    content: "\e8ab"
}

.solid-icon-File-Pie:before {
    content: "\e8ac"
}

.solid-icon-File-Presentation:before {
    content: "\e8ad"
}

.solid-icon-File-Refresh:before {
    content: "\e8ae"
}

.solid-icon-File-Search:before {
    content: "\e8af"
}

.solid-icon-File-Settings:before {
    content: "\e8b0"
}

.solid-icon-File-Share:before {
    content: "\e8b1"
}

.solid-icon-File-TextImage:before {
    content: "\e8b2"
}

.solid-icon-File-Trash:before {
    content: "\e8b3"
}

.solid-icon-File-TXT:before {
    content: "\e8b4"
}

.solid-icon-File-Upload:before {
    content: "\e8b5"
}

.solid-icon-File-Video:before {
    content: "\e8b6"
}

.solid-icon-File-Word:before {
    content: "\e8b7"
}

.solid-icon-File-Zip:before {
    content: "\e8b8"
}

.solid-icon-File:before {
    content: "\e8b9"
}

.solid-icon-Files:before {
    content: "\e8ba"
}

.solid-icon-Film-Board:before {
    content: "\e8bb"
}

.solid-icon-Film-Cartridge:before {
    content: "\e8bc"
}

.solid-icon-Film-Strip:before {
    content: "\e8bd"
}

.solid-icon-Film-Video:before {
    content: "\e8be"
}

.solid-icon-Film:before {
    content: "\e8bf"
}

.solid-icon-Filter-2:before {
    content: "\e8c0"
}

.solid-icon-Filter:before {
    content: "\e8c1"
}

.solid-icon-Financial:before {
    content: "\e8c2"
}

.solid-icon-Find-User:before {
    content: "\e8c3"
}

.solid-icon-Finger-DragFourSides:before {
    content: "\e8c4"
}

.solid-icon-Finger-DragTwoSides:before {
    content: "\e8c5"
}

.solid-icon-Finger-Print:before {
    content: "\e8c6"
}

.solid-icon-Finger:before {
    content: "\e8c7"
}

.solid-icon-Fingerprint-2:before {
    content: "\e8c8"
}

.solid-icon-Fingerprint:before {
    content: "\e8c9"
}

.solid-icon-Fire-Flame:before {
    content: "\e8ca"
}

.solid-icon-Fire-Flame2:before {
    content: "\e8cb"
}

.solid-icon-Fire-Hydrant:before {
    content: "\e8cc"
}

.solid-icon-Fire-Staion:before {
    content: "\e8cd"
}

.solid-icon-Firefox:before {
    content: "\e8ce"
}

.solid-icon-Firewall:before {
    content: "\e8cf"
}

.solid-icon-First-Aid:before {
    content: "\e8d0"
}

.solid-icon-First:before {
    content: "\e8d1"
}

.solid-icon-Fish-Food:before {
    content: "\e8d2"
}

.solid-icon-Fish:before {
    content: "\e8d3"
}

.solid-icon-Fit-To:before {
    content: "\e8d4"
}

.solid-icon-Fit-To2:before {
    content: "\e8d5"
}

.solid-icon-Five-Fingers:before {
    content: "\e8d6"
}

.solid-icon-Five-FingersDrag:before {
    content: "\e8d7"
}

.solid-icon-Five-FingersDrag2:before {
    content: "\e8d8"
}

.solid-icon-Five-FingersTouch:before {
    content: "\e8d9"
}

.solid-icon-Flag-2:before {
    content: "\e8da"
}

.solid-icon-Flag-3:before {
    content: "\e8db"
}

.solid-icon-Flag-4:before {
    content: "\e8dc"
}

.solid-icon-Flag-5:before {
    content: "\e8dd"
}

.solid-icon-Flag-6:before {
    content: "\e8de"
}

.solid-icon-Flag:before {
    content: "\e8df"
}

.solid-icon-Flamingo:before {
    content: "\e8e0"
}

.solid-icon-Flash-2:before {
    content: "\e8e1"
}

.solid-icon-Flash-Video:before {
    content: "\e8e2"
}

.solid-icon-Flash:before {
    content: "\e8e3"
}

.solid-icon-Flashlight:before {
    content: "\e8e4"
}

.solid-icon-Flask-2:before {
    content: "\e8e5"
}

.solid-icon-Flask:before {
    content: "\e8e6"
}

.solid-icon-Flick:before {
    content: "\e8e7"
}

.solid-icon-Flickr:before {
    content: "\e8e8"
}

.solid-icon-Flowerpot:before {
    content: "\e8e9"
}

.solid-icon-Fluorescent:before {
    content: "\e8ea"
}

.solid-icon-Fog-Day:before {
    content: "\e8eb"
}

.solid-icon-Fog-Night:before {
    content: "\e8ec"
}

.solid-icon-Folder-Add:before {
    content: "\e8ed"
}

.solid-icon-Folder-Archive:before {
    content: "\e8ee"
}

.solid-icon-Folder-Binder:before {
    content: "\e8ef"
}

.solid-icon-Folder-Binder2:before {
    content: "\e8f0"
}

.solid-icon-Folder-Block:before {
    content: "\e8f1"
}

.solid-icon-Folder-Bookmark:before {
    content: "\e8f2"
}

.solid-icon-Folder-Close:before {
    content: "\e8f3"
}

.solid-icon-Folder-Cloud:before {
    content: "\e8f4"
}

.solid-icon-Folder-Delete:before {
    content: "\e8f5"
}

.solid-icon-Folder-Download:before {
    content: "\e8f6"
}

.solid-icon-Folder-Edit:before {
    content: "\e8f7"
}

.solid-icon-Folder-Favorite:before {
    content: "\e8f8"
}

.solid-icon-Folder-Fire:before {
    content: "\e8f9"
}

.solid-icon-Folder-Hide:before {
    content: "\e8fa"
}

.solid-icon-Folder-Link:before {
    content: "\e8fb"
}

.solid-icon-Folder-Loading:before {
    content: "\e8fc"
}

.solid-icon-Folder-Lock:before {
    content: "\e8fd"
}

.solid-icon-Folder-Love:before {
    content: "\e8fe"
}

.solid-icon-Folder-Music:before {
    content: "\e8ff"
}

.solid-icon-Folder-Network:before {
    content: "\e900"
}

.solid-icon-Folder-Open:before {
    content: "\e901"
}

.solid-icon-Folder-Open2:before {
    content: "\e902"
}

.solid-icon-Folder-Organizing:before {
    content: "\e903"
}

.solid-icon-Folder-Pictures:before {
    content: "\e904"
}

.solid-icon-Folder-Refresh:before {
    content: "\e905"
}

.solid-icon-Folder-Remove-:before {
    content: "\e906"
}

.solid-icon-Folder-Search:before {
    content: "\e907"
}

.solid-icon-Folder-Settings:before {
    content: "\e908"
}

.solid-icon-Folder-Share:before {
    content: "\e909"
}

.solid-icon-Folder-Trash:before {
    content: "\e90a"
}

.solid-icon-Folder-Upload:before {
    content: "\e90b"
}

.solid-icon-Folder-Video:before {
    content: "\e90c"
}

.solid-icon-Folder-WithDocument:before {
    content: "\e90d"
}

.solid-icon-Folder-Zip:before {
    content: "\e90e"
}

.solid-icon-Folder:before {
    content: "\e90f"
}

.solid-icon-Folders:before {
    content: "\e910"
}

.solid-icon-Font-Color:before {
    content: "\e911"
}

.solid-icon-Font-Name:before {
    content: "\e912"
}

.solid-icon-Font-Size:before {
    content: "\e913"
}

.solid-icon-Font-Style:before {
    content: "\e914"
}

.solid-icon-Font-StyleSubscript:before {
    content: "\e915"
}

.solid-icon-Font-StyleSuperscript:before {
    content: "\e916"
}

.solid-icon-Font-Window:before {
    content: "\e917"
}

.solid-icon-Foot-2:before {
    content: "\e918"
}

.solid-icon-Foot:before {
    content: "\e919"
}

.solid-icon-Football-2:before {
    content: "\e91a"
}

.solid-icon-Football:before {
    content: "\e91b"
}

.solid-icon-Footprint-2:before {
    content: "\e91c"
}

.solid-icon-Footprint-3:before {
    content: "\e91d"
}

.solid-icon-Footprint:before {
    content: "\e91e"
}

.solid-icon-Forest:before {
    content: "\e91f"
}

.solid-icon-Fork:before {
    content: "\e920"
}

.solid-icon-Formspring:before {
    content: "\e921"
}

.solid-icon-Formula:before {
    content: "\e922"
}

.solid-icon-Forsquare:before {
    content: "\e923"
}

.solid-icon-Forward:before {
    content: "\e924"
}

.solid-icon-Fountain-Pen:before {
    content: "\e925"
}

.solid-icon-Four-Fingers:before {
    content: "\e926"
}

.solid-icon-Four-FingersDrag:before {
    content: "\e927"
}

.solid-icon-Four-FingersDrag2:before {
    content: "\e928"
}

.solid-icon-Four-FingersTouch:before {
    content: "\e929"
}

.solid-icon-Fox:before {
    content: "\e92a"
}

.solid-icon-Frankenstein:before {
    content: "\e92b"
}

.solid-icon-French-Fries:before {
    content: "\e92c"
}

.solid-icon-Friendfeed:before {
    content: "\e92d"
}

.solid-icon-Friendster:before {
    content: "\e92e"
}

.solid-icon-Frog:before {
    content: "\e92f"
}

.solid-icon-Fruits:before {
    content: "\e930"
}

.solid-icon-Fuel:before {
    content: "\e931"
}

.solid-icon-Full-Basket:before {
    content: "\e932"
}

.solid-icon-Full-Cart:before {
    content: "\e933"
}

.solid-icon-Full-Moon:before {
    content: "\e934"
}

.solid-icon-Full-Screen:before {
    content: "\e935"
}

.solid-icon-Full-Screen2:before {
    content: "\e936"
}

.solid-icon-Full-View:before {
    content: "\e937"
}

.solid-icon-Full-View2:before {
    content: "\e938"
}

.solid-icon-Full-ViewWindow:before {
    content: "\e939"
}

.solid-icon-Function:before {
    content: "\e93a"
}

.solid-icon-Funky:before {
    content: "\e93b"
}

.solid-icon-Funny-Bicycle:before {
    content: "\e93c"
}

.solid-icon-Furl:before {
    content: "\e93d"
}

.solid-icon-Fyll-Bag:before {
    content: "\e93e"
}

.solid-icon-Gamepad-2:before {
    content: "\e93f"
}

.solid-icon-Gamepad:before {
    content: "\e940"
}

.solid-icon-Gas-Pump:before {
    content: "\e941"
}

.solid-icon-Gaugage-2:before {
    content: "\e942"
}

.solid-icon-Gaugage:before {
    content: "\e943"
}

.solid-icon-Gay:before {
    content: "\e944"
}

.solid-icon-Gear-2:before {
    content: "\e945"
}

.solid-icon-Gear:before {
    content: "\e946"
}

.solid-icon-Gears-2:before {
    content: "\e947"
}

.solid-icon-Gears:before {
    content: "\e948"
}

.solid-icon-Geek-2:before {
    content: "\e949"
}

.solid-icon-Geek:before {
    content: "\e94a"
}

.solid-icon-Gemini-2:before {
    content: "\e94b"
}

.solid-icon-Gemini:before {
    content: "\e94c"
}

.solid-icon-Genius:before {
    content: "\e94d"
}

.solid-icon-Gentleman:before {
    content: "\e94e"
}

.solid-icon-Geo--:before {
    content: "\e94f"
}

.solid-icon-Geo-:before {
    content: "\e950"
}

.solid-icon-Geo-Close:before {
    content: "\e951"
}

.solid-icon-Geo-Love:before {
    content: "\e952"
}

.solid-icon-Geo-Number:before {
    content: "\e953"
}

.solid-icon-Geo-Star:before {
    content: "\e954"
}

.solid-icon-Geo:before {
    content: "\e955"
}

.solid-icon-Geo2--:before {
    content: "\e956"
}

.solid-icon-Geo2-:before {
    content: "\e957"
}

.solid-icon-Geo2-Close:before {
    content: "\e958"
}

.solid-icon-Geo2-Love:before {
    content: "\e959"
}

.solid-icon-Geo2-Number:before {
    content: "\e95a"
}

.solid-icon-Geo2-Star:before {
    content: "\e95b"
}

.solid-icon-Geo2:before {
    content: "\e95c"
}

.solid-icon-Geo3--:before {
    content: "\e95d"
}

.solid-icon-Geo3-:before {
    content: "\e95e"
}

.solid-icon-Geo3-Close:before {
    content: "\e95f"
}

.solid-icon-Geo3-Love:before {
    content: "\e960"
}

.solid-icon-Geo3-Number:before {
    content: "\e961"
}

.solid-icon-Geo3-Star:before {
    content: "\e962"
}

.solid-icon-Geo3:before {
    content: "\e963"
}

.solid-icon-Gey:before {
    content: "\e964"
}

.solid-icon-Gift-Box:before {
    content: "\e965"
}

.solid-icon-Giraffe:before {
    content: "\e966"
}

.solid-icon-Girl:before {
    content: "\e967"
}

.solid-icon-Glass-Water:before {
    content: "\e968"
}

.solid-icon-Glasses-2:before {
    content: "\e969"
}

.solid-icon-Glasses-3:before {
    content: "\e96a"
}

.solid-icon-Glasses:before {
    content: "\e96b"
}

.solid-icon-Global-Position:before {
    content: "\e96c"
}

.solid-icon-Globe-2:before {
    content: "\e96d"
}

.solid-icon-Globe:before {
    content: "\e96e"
}

.solid-icon-Gloves:before {
    content: "\e96f"
}

.solid-icon-Go-Bottom:before {
    content: "\e970"
}

.solid-icon-Go-Top:before {
    content: "\e971"
}

.solid-icon-Goggles:before {
    content: "\e972"
}

.solid-icon-Golf-2:before {
    content: "\e973"
}

.solid-icon-Golf:before {
    content: "\e974"
}

.solid-icon-Google-Buzz:before {
    content: "\e975"
}

.solid-icon-Google-Drive:before {
    content: "\e976"
}

.solid-icon-Google-Play:before {
    content: "\e977"
}

.solid-icon-Google-Plus:before {
    content: "\e978"
}

.solid-icon-Google:before {
    content: "\e979"
}

.solid-icon-Gopro:before {
    content: "\e97a"
}

.solid-icon-Gorilla:before {
    content: "\e97b"
}

.solid-icon-Gowalla:before {
    content: "\e97c"
}

.solid-icon-Grave:before {
    content: "\e97d"
}

.solid-icon-Graveyard:before {
    content: "\e97e"
}

.solid-icon-Greece:before {
    content: "\e97f"
}

.solid-icon-Green-Energy:before {
    content: "\e980"
}

.solid-icon-Green-House:before {
    content: "\e981"
}

.solid-icon-Guitar:before {
    content: "\e982"
}

.solid-icon-Gun-2:before {
    content: "\e983"
}

.solid-icon-Gun-3:before {
    content: "\e984"
}

.solid-icon-Gun:before {
    content: "\e985"
}

.solid-icon-Gymnastics:before {
    content: "\e986"
}

.solid-icon-Hair-2:before {
    content: "\e987"
}

.solid-icon-Hair-3:before {
    content: "\e988"
}

.solid-icon-Hair-4:before {
    content: "\e989"
}

.solid-icon-Hair:before {
    content: "\e98a"
}

.solid-icon-Half-Moon:before {
    content: "\e98b"
}

.solid-icon-Halloween-HalfMoon:before {
    content: "\e98c"
}

.solid-icon-Halloween-Moon:before {
    content: "\e98d"
}

.solid-icon-Hamburger:before {
    content: "\e98e"
}

.solid-icon-Hammer:before {
    content: "\e98f"
}

.solid-icon-Hand-Touch:before {
    content: "\e990"
}

.solid-icon-Hand-Touch2:before {
    content: "\e991"
}

.solid-icon-Hand-TouchSmartphone:before {
    content: "\e992"
}

.solid-icon-Hand:before {
    content: "\e993"
}

.solid-icon-Hands:before {
    content: "\e994"
}

.solid-icon-Handshake:before {
    content: "\e995"
}

.solid-icon-Hanger:before {
    content: "\e996"
}

.solid-icon-Happy:before {
    content: "\e997"
}

.solid-icon-Hat-2:before {
    content: "\e998"
}

.solid-icon-Hat:before {
    content: "\e999"
}

.solid-icon-Haunted-House:before {
    content: "\e99a"
}

.solid-icon-HD-Video:before {
    content: "\e99b"
}

.solid-icon-HD:before {
    content: "\e99c"
}

.solid-icon-HDD:before {
    content: "\e99d"
}

.solid-icon-Headphone:before {
    content: "\e99e"
}

.solid-icon-Headphones:before {
    content: "\e99f"
}

.solid-icon-Headset:before {
    content: "\e9a0"
}

.solid-icon-Heart-2:before {
    content: "\e9a1"
}

.solid-icon-Heart:before {
    content: "\e9a2"
}

.solid-icon-Heels-2:before {
    content: "\e9a3"
}

.solid-icon-Heels:before {
    content: "\e9a4"
}

.solid-icon-Height-Window:before {
    content: "\e9a5"
}

.solid-icon-Helicopter-2:before {
    content: "\e9a6"
}

.solid-icon-Helicopter:before {
    content: "\e9a7"
}

.solid-icon-Helix-2:before {
    content: "\e9a8"
}

.solid-icon-Hello:before {
    content: "\e9a9"
}

.solid-icon-Helmet-2:before {
    content: "\e9aa"
}

.solid-icon-Helmet-3:before {
    content: "\e9ab"
}

.solid-icon-Helmet:before {
    content: "\e9ac"
}

.solid-icon-Hipo:before {
    content: "\e9ad"
}

.solid-icon-Hipster-Glasses:before {
    content: "\e9ae"
}

.solid-icon-Hipster-Glasses2:before {
    content: "\e9af"
}

.solid-icon-Hipster-Glasses3:before {
    content: "\e9b0"
}

.solid-icon-Hipster-Headphones:before {
    content: "\e9b1"
}

.solid-icon-Hipster-Men:before {
    content: "\e9b2"
}

.solid-icon-Hipster-Men2:before {
    content: "\e9b3"
}

.solid-icon-Hipster-Men3:before {
    content: "\e9b4"
}

.solid-icon-Hipster-Sunglasses:before {
    content: "\e9b5"
}

.solid-icon-Hipster-Sunglasses2:before {
    content: "\e9b6"
}

.solid-icon-Hipster-Sunglasses3:before {
    content: "\e9b7"
}

.solid-icon-Hokey:before {
    content: "\e9b8"
}

.solid-icon-Holly:before {
    content: "\e9b9"
}

.solid-icon-Home-2:before {
    content: "\e9ba"
}

.solid-icon-Home-3:before {
    content: "\e9bb"
}

.solid-icon-Home-4:before {
    content: "\e9bc"
}

.solid-icon-Home-5:before {
    content: "\e9bd"
}

.solid-icon-Home-Window:before {
    content: "\e9be"
}

.solid-icon-Home:before {
    content: "\e9bf"
}

.solid-icon-Homosexual:before {
    content: "\e9c0"
}

.solid-icon-Honey:before {
    content: "\e9c1"
}

.solid-icon-Hong-Kong:before {
    content: "\e9c2"
}

.solid-icon-Hoodie:before {
    content: "\e9c3"
}

.solid-icon-Horror:before {
    content: "\e9c4"
}

.solid-icon-Horse:before {
    content: "\e9c5"
}

.solid-icon-Hospital-2:before {
    content: "\e9c6"
}

.solid-icon-Hospital:before {
    content: "\e9c7"
}

.solid-icon-Host:before {
    content: "\e9c8"
}

.solid-icon-Hot-Dog:before {
    content: "\e9c9"
}

.solid-icon-Hotel:before {
    content: "\e9ca"
}

.solid-icon-Hour:before {
    content: "\e9cb"
}

.solid-icon-Hub:before {
    content: "\e9cc"
}

.solid-icon-Humor:before {
    content: "\e9cd"
}

.solid-icon-Hurt:before {
    content: "\e9ce"
}

.solid-icon-Ice-Cream:before {
    content: "\e9cf"
}

.solid-icon-ICQ:before {
    content: "\e9d0"
}

.solid-icon-ID-2:before {
    content: "\e9d1"
}

.solid-icon-ID-3:before {
    content: "\e9d2"
}

.solid-icon-ID-Card:before {
    content: "\e9d3"
}

.solid-icon-Idea-2:before {
    content: "\e9d4"
}

.solid-icon-Idea-3:before {
    content: "\e9d5"
}

.solid-icon-Idea-4:before {
    content: "\e9d6"
}

.solid-icon-Idea-5:before {
    content: "\e9d7"
}

.solid-icon-Idea:before {
    content: "\e9d8"
}

.solid-icon-Identification-Badge:before {
    content: "\e9d9"
}

.solid-icon-ImDB:before {
    content: "\e9da"
}

.solid-icon-Inbox-Empty:before {
    content: "\e9db"
}

.solid-icon-Inbox-Forward:before {
    content: "\e9dc"
}

.solid-icon-Inbox-Full:before {
    content: "\e9dd"
}

.solid-icon-Inbox-Into:before {
    content: "\e9de"
}

.solid-icon-Inbox-Out:before {
    content: "\e9df"
}

.solid-icon-Inbox-Reply:before {
    content: "\e9e0"
}

.solid-icon-Inbox:before {
    content: "\e9e1"
}

.solid-icon-Increase-Inedit:before {
    content: "\e9e2"
}

.solid-icon-Indent-FirstLine:before {
    content: "\e9e3"
}

.solid-icon-Indent-LeftMargin:before {
    content: "\e9e4"
}

.solid-icon-Indent-RightMargin:before {
    content: "\e9e5"
}

.solid-icon-India:before {
    content: "\e9e6"
}

.solid-icon-Info-Window:before {
    content: "\e9e7"
}

.solid-icon-Information:before {
    content: "\e9e8"
}

.solid-icon-Inifity:before {
    content: "\e9e9"
}

.solid-icon-Instagram:before {
    content: "\e9ea"
}

.solid-icon-Internet-2:before {
    content: "\e9eb"
}

.solid-icon-Internet-Explorer:before {
    content: "\e9ec"
}

.solid-icon-Internet-Smiley:before {
    content: "\e9ed"
}

.solid-icon-Internet:before {
    content: "\e9ee"
}

.solid-icon-iOS-Apple:before {
    content: "\e9ef"
}

.solid-icon-Israel:before {
    content: "\e9f0"
}

.solid-icon-Italic-Text:before {
    content: "\e9f1"
}

.solid-icon-Jacket-2:before {
    content: "\e9f2"
}

.solid-icon-Jacket:before {
    content: "\e9f3"
}

.solid-icon-Jamaica:before {
    content: "\e9f4"
}

.solid-icon-Japan:before {
    content: "\e9f5"
}

.solid-icon-Japanese-Gate:before {
    content: "\e9f6"
}

.solid-icon-Jeans:before {
    content: "\e9f7"
}

.solid-icon-Jeep-2:before {
    content: "\e9f8"
}

.solid-icon-Jeep:before {
    content: "\e9f9"
}

.solid-icon-Jet:before {
    content: "\e9fa"
}

.solid-icon-Joystick:before {
    content: "\e9fb"
}

.solid-icon-Juice:before {
    content: "\e9fc"
}

.solid-icon-Jump-Rope:before {
    content: "\e9fd"
}

.solid-icon-Kangoroo:before {
    content: "\e9fe"
}

.solid-icon-Kenya:before {
    content: "\e9ff"
}

.solid-icon-Key-2:before {
    content: "\ea00"
}

.solid-icon-Key-3:before {
    content: "\ea01"
}

.solid-icon-Key-Lock:before {
    content: "\ea02"
}

.solid-icon-Key:before {
    content: "\ea03"
}

.solid-icon-Keyboard:before {
    content: "\ea04"
}

.solid-icon-Keyboard3:before {
    content: "\ea05"
}

.solid-icon-Keypad:before {
    content: "\ea06"
}

.solid-icon-King-2:before {
    content: "\ea07"
}

.solid-icon-King:before {
    content: "\ea08"
}

.solid-icon-Kiss:before {
    content: "\ea09"
}

.solid-icon-Knee:before {
    content: "\ea0a"
}

.solid-icon-Knife-2:before {
    content: "\ea0b"
}

.solid-icon-Knife:before {
    content: "\ea0c"
}

.solid-icon-Knight:before {
    content: "\ea0d"
}

.solid-icon-Koala:before {
    content: "\ea0e"
}

.solid-icon-Korea:before {
    content: "\ea0f"
}

.solid-icon-Lamp:before {
    content: "\ea10"
}

.solid-icon-Landscape-2:before {
    content: "\ea11"
}

.solid-icon-Landscape:before {
    content: "\ea12"
}

.solid-icon-Lantern:before {
    content: "\ea13"
}

.solid-icon-Laptop-2:before {
    content: "\ea14"
}

.solid-icon-Laptop-3:before {
    content: "\ea15"
}

.solid-icon-Laptop-Phone:before {
    content: "\ea16"
}

.solid-icon-Laptop-Secure:before {
    content: "\ea17"
}

.solid-icon-Laptop-Tablet:before {
    content: "\ea18"
}

.solid-icon-Laptop:before {
    content: "\ea19"
}

.solid-icon-Laser:before {
    content: "\ea1a"
}

.solid-icon-Last-FM:before {
    content: "\ea1b"
}

.solid-icon-Last:before {
    content: "\ea1c"
}

.solid-icon-Laughing:before {
    content: "\ea1d"
}

.solid-icon-Layer-Backward:before {
    content: "\ea1e"
}

.solid-icon-Layer-Forward:before {
    content: "\ea1f"
}

.solid-icon-Leafs-2:before {
    content: "\ea20"
}

.solid-icon-Leafs:before {
    content: "\ea21"
}

.solid-icon-Leaning-Tower:before {
    content: "\ea22"
}

.solid-icon-Left--Right:before {
    content: "\ea23"
}

.solid-icon-Left--Right3:before {
    content: "\ea24"
}

.solid-icon-Left-2:before {
    content: "\ea25"
}

.solid-icon-Left-3:before {
    content: "\ea26"
}

.solid-icon-Left-4:before {
    content: "\ea27"
}

.solid-icon-Left-ToRight:before {
    content: "\ea28"
}

.solid-icon-Left:before {
    content: "\ea29"
}

.solid-icon-Leg-2:before {
    content: "\ea2a"
}

.solid-icon-Leg:before {
    content: "\ea2b"
}

.solid-icon-Lego:before {
    content: "\ea2c"
}

.solid-icon-Lemon:before {
    content: "\ea2d"
}

.solid-icon-Len-2:before {
    content: "\ea2e"
}

.solid-icon-Len-3:before {
    content: "\ea2f"
}

.solid-icon-Len:before {
    content: "\ea30"
}

.solid-icon-Leo-2:before {
    content: "\ea31"
}

.solid-icon-Leo:before {
    content: "\ea32"
}

.solid-icon-Leopard:before {
    content: "\ea33"
}

.solid-icon-Lesbian:before {
    content: "\ea34"
}

.solid-icon-Lesbians:before {
    content: "\ea35"
}

.solid-icon-Letter-Close:before {
    content: "\ea36"
}

.solid-icon-Letter-Open:before {
    content: "\ea37"
}

.solid-icon-Letter-Sent:before {
    content: "\ea38"
}

.solid-icon-Libra-2:before {
    content: "\ea39"
}

.solid-icon-Libra:before {
    content: "\ea3a"
}

.solid-icon-Library-2:before {
    content: "\ea3b"
}

.solid-icon-Library:before {
    content: "\ea3c"
}

.solid-icon-Life-Jacket:before {
    content: "\ea3d"
}

.solid-icon-Life-Safer:before {
    content: "\ea3e"
}

.solid-icon-Light-Bulb:before {
    content: "\ea3f"
}

.solid-icon-Light-Bulb2:before {
    content: "\ea40"
}

.solid-icon-Light-BulbLeaf:before {
    content: "\ea41"
}

.solid-icon-Lighthouse:before {
    content: "\ea42"
}

.solid-icon-Like-2:before {
    content: "\ea43"
}

.solid-icon-Like:before {
    content: "\ea44"
}

.solid-icon-Line-Chart:before {
    content: "\ea45"
}

.solid-icon-Line-Chart2:before {
    content: "\ea46"
}

.solid-icon-Line-Chart3:before {
    content: "\ea47"
}

.solid-icon-Line-Chart4:before {
    content: "\ea48"
}

.solid-icon-Line-Spacing:before {
    content: "\ea49"
}

.solid-icon-Line-SpacingText:before {
    content: "\ea4a"
}

.solid-icon-Link-2:before {
    content: "\ea4b"
}

.solid-icon-Link:before {
    content: "\ea4c"
}

.solid-icon-Linkedin-2:before {
    content: "\ea4d"
}

.solid-icon-Linkedin:before {
    content: "\ea4e"
}

.solid-icon-Linux:before {
    content: "\ea4f"
}

.solid-icon-Lion:before {
    content: "\ea50"
}

.solid-icon-Livejournal:before {
    content: "\ea51"
}

.solid-icon-Loading-2:before {
    content: "\ea52"
}

.solid-icon-Loading-3:before {
    content: "\ea53"
}

.solid-icon-Loading-Window:before {
    content: "\ea54"
}

.solid-icon-Loading:before {
    content: "\ea55"
}

.solid-icon-Location-2:before {
    content: "\ea56"
}

.solid-icon-Location:before {
    content: "\ea57"
}

.solid-icon-Lock-2:before {
    content: "\ea58"
}

.solid-icon-Lock-3:before {
    content: "\ea59"
}

.solid-icon-Lock-User:before {
    content: "\ea5a"
}

.solid-icon-Lock-Window:before {
    content: "\ea5b"
}

.solid-icon-Lock:before {
    content: "\ea5c"
}

.solid-icon-Lollipop-2:before {
    content: "\ea5d"
}

.solid-icon-Lollipop-3:before {
    content: "\ea5e"
}

.solid-icon-Lollipop:before {
    content: "\ea5f"
}

.solid-icon-Loop:before {
    content: "\ea60"
}

.solid-icon-Loud:before {
    content: "\ea61"
}

.solid-icon-Loudspeaker:before {
    content: "\ea62"
}

.solid-icon-Love-2:before {
    content: "\ea63"
}

.solid-icon-Love-User:before {
    content: "\ea64"
}

.solid-icon-Love-Window:before {
    content: "\ea65"
}

.solid-icon-Love:before {
    content: "\ea66"
}

.solid-icon-Lowercase-Text:before {
    content: "\ea67"
}

.solid-icon-Luggafe-Front:before {
    content: "\ea68"
}

.solid-icon-Luggage-2:before {
    content: "\ea69"
}

.solid-icon-Macro:before {
    content: "\ea6a"
}

.solid-icon-Magic-Wand:before {
    content: "\ea6b"
}

.solid-icon-Magnet:before {
    content: "\ea6c"
}

.solid-icon-Magnifi-Glass-:before {
    content: "\ea6d"
}

.solid-icon-Magnifi-Glass:before {
    content: "\ea6e"
}

.solid-icon-Magnifi-Glass2:before {
    content: "\ea6f"
}

.solid-icon-Mail-2:before {
    content: "\ea70"
}

.solid-icon-Mail-3:before {
    content: "\ea71"
}

.solid-icon-Mail-Add:before {
    content: "\ea72"
}

.solid-icon-Mail-Attachement:before {
    content: "\ea73"
}

.solid-icon-Mail-Block:before {
    content: "\ea74"
}

.solid-icon-Mail-Delete:before {
    content: "\ea75"
}

.solid-icon-Mail-Favorite:before {
    content: "\ea76"
}

.solid-icon-Mail-Forward:before {
    content: "\ea77"
}

.solid-icon-Mail-Gallery:before {
    content: "\ea78"
}

.solid-icon-Mail-Inbox:before {
    content: "\ea79"
}

.solid-icon-Mail-Link:before {
    content: "\ea7a"
}

.solid-icon-Mail-Lock:before {
    content: "\ea7b"
}

.solid-icon-Mail-Love:before {
    content: "\ea7c"
}

.solid-icon-Mail-Money:before {
    content: "\ea7d"
}

.solid-icon-Mail-Open:before {
    content: "\ea7e"
}

.solid-icon-Mail-Outbox:before {
    content: "\ea7f"
}

.solid-icon-Mail-Password:before {
    content: "\ea80"
}

.solid-icon-Mail-Photo:before {
    content: "\ea81"
}

.solid-icon-Mail-Read:before {
    content: "\ea82"
}

.solid-icon-Mail-Removex:before {
    content: "\ea83"
}

.solid-icon-Mail-Reply:before {
    content: "\ea84"
}

.solid-icon-Mail-ReplyAll:before {
    content: "\ea85"
}

.solid-icon-Mail-Search:before {
    content: "\ea86"
}

.solid-icon-Mail-Send:before {
    content: "\ea87"
}

.solid-icon-Mail-Settings:before {
    content: "\ea88"
}

.solid-icon-Mail-Unread:before {
    content: "\ea89"
}

.solid-icon-Mail-Video:before {
    content: "\ea8a"
}

.solid-icon-Mail-withAtSign:before {
    content: "\ea8b"
}

.solid-icon-Mail-WithCursors:before {
    content: "\ea8c"
}

.solid-icon-Mail:before {
    content: "\ea8d"
}

.solid-icon-Mailbox-Empty:before {
    content: "\ea8e"
}

.solid-icon-Mailbox-Full:before {
    content: "\ea8f"
}

.solid-icon-Male-2:before {
    content: "\ea90"
}

.solid-icon-Male-Sign:before {
    content: "\ea91"
}

.solid-icon-Male:before {
    content: "\ea92"
}

.solid-icon-MaleFemale:before {
    content: "\ea93"
}

.solid-icon-Man-Sign:before {
    content: "\ea94"
}

.solid-icon-Management:before {
    content: "\ea95"
}

.solid-icon-Mans-Underwear:before {
    content: "\ea96"
}

.solid-icon-Mans-Underwear2:before {
    content: "\ea97"
}

.solid-icon-Map-Marker:before {
    content: "\ea98"
}

.solid-icon-Map-Marker2:before {
    content: "\ea99"
}

.solid-icon-Map-Marker3:before {
    content: "\ea9a"
}

.solid-icon-Map:before {
    content: "\ea9b"
}

.solid-icon-Map2:before {
    content: "\ea9c"
}

.solid-icon-Marker-2:before {
    content: "\ea9d"
}

.solid-icon-Marker-3:before {
    content: "\ea9e"
}

.solid-icon-Marker:before {
    content: "\ea9f"
}

.solid-icon-Martini-Glass:before {
    content: "\eaa0"
}

.solid-icon-Mask:before {
    content: "\eaa1"
}

.solid-icon-Master-Card:before {
    content: "\eaa2"
}

.solid-icon-Maximize-Window:before {
    content: "\eaa3"
}

.solid-icon-Maximize:before {
    content: "\eaa4"
}

.solid-icon-Medal-2:before {
    content: "\eaa5"
}

.solid-icon-Medal-3:before {
    content: "\eaa6"
}

.solid-icon-Medal:before {
    content: "\eaa7"
}

.solid-icon-Medical-Sign:before {
    content: "\eaa8"
}

.solid-icon-Medicine-2:before {
    content: "\eaa9"
}

.solid-icon-Medicine-3:before {
    content: "\eaaa"
}

.solid-icon-Medicine:before {
    content: "\eaab"
}

.solid-icon-Megaphone:before {
    content: "\eaac"
}

.solid-icon-Memory-Card:before {
    content: "\eaad"
}

.solid-icon-Memory-Card2:before {
    content: "\eaae"
}

.solid-icon-Memory-Card3:before {
    content: "\eaaf"
}

.solid-icon-Men:before {
    content: "\eab0"
}

.solid-icon-Menorah:before {
    content: "\eab1"
}

.solid-icon-Mens:before {
    content: "\eab2"
}

.solid-icon-Metacafe:before {
    content: "\eab3"
}

.solid-icon-Mexico:before {
    content: "\eab4"
}

.solid-icon-Mic:before {
    content: "\eab5"
}

.solid-icon-Microphone-2:before {
    content: "\eab6"
}

.solid-icon-Microphone-3:before {
    content: "\eab7"
}

.solid-icon-Microphone-4:before {
    content: "\eab8"
}

.solid-icon-Microphone-5:before {
    content: "\eab9"
}

.solid-icon-Microphone-6:before {
    content: "\eaba"
}

.solid-icon-Microphone-8:before {
    content: "\eabb"
}

.solid-icon-Microphone:before {
    content: "\eabc"
}

.solid-icon-Microscope:before {
    content: "\eabd"
}

.solid-icon-Milk-Bottle:before {
    content: "\eabe"
}

.solid-icon-Mine:before {
    content: "\eabf"
}

.solid-icon-Minimize-Maximize-Close-Window:before {
    content: "\eac0"
}

.solid-icon-Minimize-Window:before {
    content: "\eac1"
}

.solid-icon-Minimize:before {
    content: "\eac2"
}

.solid-icon-Mirror:before {
    content: "\eac3"
}

.solid-icon-Mixer:before {
    content: "\eac4"
}

.solid-icon-Mixx:before {
    content: "\eac5"
}

.solid-icon-Money-2:before {
    content: "\eac6"
}

.solid-icon-Money-Bag:before {
    content: "\eac7"
}

.solid-icon-Money-Smiley:before {
    content: "\eac8"
}

.solid-icon-Money:before {
    content: "\eac9"
}

.solid-icon-Monitor-2:before {
    content: "\eaca"
}

.solid-icon-Monitor-3:before {
    content: "\eacb"
}

.solid-icon-Monitor-4:before {
    content: "\eacc"
}

.solid-icon-Monitor-5:before {
    content: "\eacd"
}

.solid-icon-Monitor-Analytics:before {
    content: "\eace"
}

.solid-icon-Monitor-Laptop:before {
    content: "\eacf"
}

.solid-icon-Monitor-phone:before {
    content: "\ead0"
}

.solid-icon-Monitor-Tablet:before {
    content: "\ead1"
}

.solid-icon-Monitor-Vertical:before {
    content: "\ead2"
}

.solid-icon-Monitor:before {
    content: "\ead3"
}

.solid-icon-Monitoring:before {
    content: "\ead4"
}

.solid-icon-Monkey:before {
    content: "\ead5"
}

.solid-icon-Monster:before {
    content: "\ead6"
}

.solid-icon-Morocco:before {
    content: "\ead7"
}

.solid-icon-Motorcycle:before {
    content: "\ead8"
}

.solid-icon-Mouse-2:before {
    content: "\ead9"
}

.solid-icon-Mouse-3:before {
    content: "\eada"
}

.solid-icon-Mouse-4:before {
    content: "\eadb"
}

.solid-icon-Mouse-Pointer:before {
    content: "\eadc"
}

.solid-icon-Mouse:before {
    content: "\eadd"
}

.solid-icon-Moustache-Smiley:before {
    content: "\eade"
}

.solid-icon-Movie-Ticket:before {
    content: "\eadf"
}

.solid-icon-Movie:before {
    content: "\eae0"
}

.solid-icon-Mp3-File:before {
    content: "\eae1"
}

.solid-icon-Museum:before {
    content: "\eae2"
}

.solid-icon-Mushroom:before {
    content: "\eae3"
}

.solid-icon-Music-Note:before {
    content: "\eae4"
}

.solid-icon-Music-Note2:before {
    content: "\eae5"
}

.solid-icon-Music-Note3:before {
    content: "\eae6"
}

.solid-icon-Music-Note4:before {
    content: "\eae7"
}

.solid-icon-Music-Player:before {
    content: "\eae8"
}

.solid-icon-Mustache-2:before {
    content: "\eae9"
}

.solid-icon-Mustache-3:before {
    content: "\eaea"
}

.solid-icon-Mustache-4:before {
    content: "\eaeb"
}

.solid-icon-Mustache-5:before {
    content: "\eaec"
}

.solid-icon-Mustache-6:before {
    content: "\eaed"
}

.solid-icon-Mustache-7:before {
    content: "\eaee"
}

.solid-icon-Mustache-8:before {
    content: "\eaef"
}

.solid-icon-Mustache:before {
    content: "\eaf0"
}

.solid-icon-Mute:before {
    content: "\eaf1"
}

.solid-icon-Myspace:before {
    content: "\eaf2"
}

.solid-icon-Navigat-Start:before {
    content: "\eaf3"
}

.solid-icon-Navigate-End:before {
    content: "\eaf4"
}

.solid-icon-Navigation-LeftWindow:before {
    content: "\eaf5"
}

.solid-icon-Navigation-RightWindow:before {
    content: "\eaf6"
}

.solid-icon-Nepal:before {
    content: "\eaf7"
}

.solid-icon-Netscape:before {
    content: "\eaf8"
}

.solid-icon-Network-Window:before {
    content: "\eaf9"
}

.solid-icon-Network:before {
    content: "\eafa"
}

.solid-icon-Neutron:before {
    content: "\eafb"
}

.solid-icon-New-Mail:before {
    content: "\eafc"
}

.solid-icon-New-Tab:before {
    content: "\eafd"
}

.solid-icon-Newspaper-2:before {
    content: "\eafe"
}

.solid-icon-Newspaper:before {
    content: "\eaff"
}

.solid-icon-Newsvine:before {
    content: "\eb00"
}

.solid-icon-Next-3:before {
    content: "\eb01"
}

.solid-icon-Next-Media:before {
    content: "\eb02"
}

.solid-icon-Next-Music:before {
    content: "\eb03"
}

.solid-icon-Next:before {
    content: "\eb04"
}

.solid-icon-No-Battery:before {
    content: "\eb05"
}

.solid-icon-No-Drop:before {
    content: "\eb06"
}

.solid-icon-No-Flash:before {
    content: "\eb07"
}

.solid-icon-No-Smoking:before {
    content: "\eb08"
}

.solid-icon-Noose:before {
    content: "\eb09"
}

.solid-icon-Normal-Text:before {
    content: "\eb0a"
}

.solid-icon-Note:before {
    content: "\eb0b"
}

.solid-icon-Notepad-2:before {
    content: "\eb0c"
}

.solid-icon-Notepad:before {
    content: "\eb0d"
}

.solid-icon-Nuclear:before {
    content: "\eb0e"
}

.solid-icon-Numbering-List:before {
    content: "\eb0f"
}

.solid-icon-Nurse:before {
    content: "\eb10"
}

.solid-icon-Office-Lamp:before {
    content: "\eb11"
}

.solid-icon-Office:before {
    content: "\eb12"
}

.solid-icon-Oil:before {
    content: "\eb13"
}

.solid-icon-Old-Camera:before {
    content: "\eb14"
}

.solid-icon-Old-Cassette:before {
    content: "\eb15"
}

.solid-icon-Old-Clock:before {
    content: "\eb16"
}

.solid-icon-Old-Radio:before {
    content: "\eb17"
}

.solid-icon-Old-Sticky:before {
    content: "\eb18"
}

.solid-icon-Old-Sticky2:before {
    content: "\eb19"
}

.solid-icon-Old-Telephone:before {
    content: "\eb1a"
}

.solid-icon-Old-TV:before {
    content: "\eb1b"
}

.solid-icon-On-Air:before {
    content: "\eb1c"
}

.solid-icon-On-Off-2:before {
    content: "\eb1d"
}

.solid-icon-On-Off-3:before {
    content: "\eb1e"
}

.solid-icon-On-off:before {
    content: "\eb1f"
}

.solid-icon-One-Finger:before {
    content: "\eb20"
}

.solid-icon-One-FingerTouch:before {
    content: "\eb21"
}

.solid-icon-One-Window:before {
    content: "\eb22"
}

.solid-icon-Open-Banana:before {
    content: "\eb23"
}

.solid-icon-Open-Book:before {
    content: "\eb24"
}

.solid-icon-Opera-House:before {
    content: "\eb25"
}

.solid-icon-Opera:before {
    content: "\eb26"
}

.solid-icon-Optimization:before {
    content: "\eb27"
}

.solid-icon-Orientation-2:before {
    content: "\eb28"
}

.solid-icon-Orientation-3:before {
    content: "\eb29"
}

.solid-icon-Orientation:before {
    content: "\eb2a"
}

.solid-icon-Orkut:before {
    content: "\eb2b"
}

.solid-icon-Ornament:before {
    content: "\eb2c"
}

.solid-icon-Over-Time:before {
    content: "\eb2d"
}

.solid-icon-Over-Time2:before {
    content: "\eb2e"
}

.solid-icon-Owl:before {
    content: "\eb2f"
}

.solid-icon-Pac-Man:before {
    content: "\eb30"
}

.solid-icon-Paint-Brush:before {
    content: "\eb31"
}

.solid-icon-Paint-Bucket:before {
    content: "\eb32"
}

.solid-icon-Paintbrush:before {
    content: "\eb33"
}

.solid-icon-Palette:before {
    content: "\eb34"
}

.solid-icon-Palm-Tree:before {
    content: "\eb35"
}

.solid-icon-Panda:before {
    content: "\eb36"
}

.solid-icon-Panorama:before {
    content: "\eb37"
}

.solid-icon-Pantheon:before {
    content: "\eb38"
}

.solid-icon-Pantone:before {
    content: "\eb39"
}

.solid-icon-Pants:before {
    content: "\eb3a"
}

.solid-icon-Paper-Plane:before {
    content: "\eb3b"
}

.solid-icon-Paper:before {
    content: "\eb3c"
}

.solid-icon-Parasailing:before {
    content: "\eb3d"
}

.solid-icon-Parrot:before {
    content: "\eb3e"
}

.solid-icon-Password-2shopping:before {
    content: "\eb3f"
}

.solid-icon-Password-Field:before {
    content: "\eb40"
}

.solid-icon-Password-shopping:before {
    content: "\eb41"
}

.solid-icon-Password:before {
    content: "\eb42"
}

.solid-icon-Pause-2:before {
    content: "\eb43"
}

.solid-icon-Pause:before {
    content: "\eb44"
}

.solid-icon-Paw:before {
    content: "\eb45"
}

.solid-icon-Pawn:before {
    content: "\eb46"
}

.solid-icon-Paypal:before {
    content: "\eb47"
}

.solid-icon-Pen-2:before {
    content: "\eb48"
}

.solid-icon-Pen-3:before {
    content: "\eb49"
}

.solid-icon-Pen-4:before {
    content: "\eb4a"
}

.solid-icon-Pen-5:before {
    content: "\eb4b"
}

.solid-icon-Pen-6:before {
    content: "\eb4c"
}

.solid-icon-Pen:before {
    content: "\eb4d"
}

.solid-icon-Pencil-Ruler:before {
    content: "\eb4e"
}

.solid-icon-Pencil:before {
    content: "\eb4f"
}

.solid-icon-Penguin:before {
    content: "\eb50"
}

.solid-icon-Pentagon:before {
    content: "\eb51"
}

.solid-icon-People-onCloud:before {
    content: "\eb52"
}

.solid-icon-Pepper-withFire:before {
    content: "\eb53"
}

.solid-icon-Pepper:before {
    content: "\eb54"
}

.solid-icon-Petrol:before {
    content: "\eb55"
}

.solid-icon-Petronas-Tower:before {
    content: "\eb56"
}

.solid-icon-Philipines:before {
    content: "\eb57"
}

.solid-icon-Phone-2:before {
    content: "\eb58"
}

.solid-icon-Phone-3:before {
    content: "\eb59"
}

.solid-icon-Phone-3G:before {
    content: "\eb5a"
}

.solid-icon-Phone-4G:before {
    content: "\eb5b"
}

.solid-icon-Phone-Simcard:before {
    content: "\eb5c"
}

.solid-icon-Phone-SMS:before {
    content: "\eb5d"
}

.solid-icon-Phone-Wifi:before {
    content: "\eb5e"
}

.solid-icon-Phone:before {
    content: "\eb5f"
}

.solid-icon-Photo-2:before {
    content: "\eb60"
}

.solid-icon-Photo-3:before {
    content: "\eb61"
}

.solid-icon-Photo-Album:before {
    content: "\eb62"
}

.solid-icon-Photo-Album2:before {
    content: "\eb63"
}

.solid-icon-Photo-Album3:before {
    content: "\eb64"
}

.solid-icon-Photo:before {
    content: "\eb65"
}

.solid-icon-Photos:before {
    content: "\eb66"
}

.solid-icon-Physics:before {
    content: "\eb67"
}

.solid-icon-Pi:before {
    content: "\eb68"
}

.solid-icon-Piano:before {
    content: "\eb69"
}

.solid-icon-Picasa:before {
    content: "\eb6a"
}

.solid-icon-Pie-Chart:before {
    content: "\eb6b"
}

.solid-icon-Pie-Chart2:before {
    content: "\eb6c"
}

.solid-icon-Pie-Chart3:before {
    content: "\eb6d"
}

.solid-icon-Pilates-2:before {
    content: "\eb6e"
}

.solid-icon-Pilates-3:before {
    content: "\eb6f"
}

.solid-icon-Pilates:before {
    content: "\eb70"
}

.solid-icon-Pilot:before {
    content: "\eb71"
}

.solid-icon-Pinch:before {
    content: "\eb72"
}

.solid-icon-Ping-Pong:before {
    content: "\eb73"
}

.solid-icon-Pinterest:before {
    content: "\eb74"
}

.solid-icon-Pipe:before {
    content: "\eb75"
}

.solid-icon-Pipette:before {
    content: "\eb76"
}

.solid-icon-Piramids:before {
    content: "\eb77"
}

.solid-icon-Pisces-2:before {
    content: "\eb78"
}

.solid-icon-Pisces:before {
    content: "\eb79"
}

.solid-icon-Pizza-Slice:before {
    content: "\eb7a"
}

.solid-icon-Pizza:before {
    content: "\eb7b"
}

.solid-icon-Plane-2:before {
    content: "\eb7c"
}

.solid-icon-Plane:before {
    content: "\eb7d"
}

.solid-icon-Plant:before {
    content: "\eb7e"
}

.solid-icon-Plasmid:before {
    content: "\eb7f"
}

.solid-icon-Plaster:before {
    content: "\eb80"
}

.solid-icon-Plastic-CupPhone:before {
    content: "\eb81"
}

.solid-icon-Plastic-CupPhone2:before {
    content: "\eb82"
}

.solid-icon-Plate:before {
    content: "\eb83"
}

.solid-icon-Plates:before {
    content: "\eb84"
}

.solid-icon-Plaxo:before {
    content: "\eb85"
}

.solid-icon-Play-Music:before {
    content: "\eb86"
}

.solid-icon-Plug-In:before {
    content: "\eb87"
}

.solid-icon-Plug-In2:before {
    content: "\eb88"
}

.solid-icon-Plurk:before {
    content: "\eb89"
}

.solid-icon-Pointer:before {
    content: "\eb8a"
}

.solid-icon-Poland:before {
    content: "\eb8b"
}

.solid-icon-Police-Man:before {
    content: "\eb8c"
}

.solid-icon-Police-Station:before {
    content: "\eb8d"
}

.solid-icon-Police-Woman:before {
    content: "\eb8e"
}

.solid-icon-Police:before {
    content: "\eb8f"
}

.solid-icon-Polo-Shirt:before {
    content: "\eb90"
}

.solid-icon-Portrait:before {
    content: "\eb91"
}

.solid-icon-Portugal:before {
    content: "\eb92"
}

.solid-icon-Post-Mail:before {
    content: "\eb93"
}

.solid-icon-Post-Mail2:before {
    content: "\eb94"
}

.solid-icon-Post-Office:before {
    content: "\eb95"
}

.solid-icon-Post-Sign:before {
    content: "\eb96"
}

.solid-icon-Post-Sign2ways:before {
    content: "\eb97"
}

.solid-icon-Posterous:before {
    content: "\eb98"
}

.solid-icon-Pound-Sign:before {
    content: "\eb99"
}

.solid-icon-Pound-Sign2:before {
    content: "\eb9a"
}

.solid-icon-Pound:before {
    content: "\eb9b"
}

.solid-icon-Power-2:before {
    content: "\eb9c"
}

.solid-icon-Power-3:before {
    content: "\eb9d"
}

.solid-icon-Power-Cable:before {
    content: "\eb9e"
}

.solid-icon-Power-Station:before {
    content: "\eb9f"
}

.solid-icon-Power:before {
    content: "\eba0"
}

.solid-icon-Prater:before {
    content: "\eba1"
}

.solid-icon-Present:before {
    content: "\eba2"
}

.solid-icon-Presents:before {
    content: "\eba3"
}

.solid-icon-Press:before {
    content: "\eba4"
}

.solid-icon-Preview:before {
    content: "\eba5"
}

.solid-icon-Previous:before {
    content: "\eba6"
}

.solid-icon-Pricing:before {
    content: "\eba7"
}

.solid-icon-Printer:before {
    content: "\eba8"
}

.solid-icon-Professor:before {
    content: "\eba9"
}

.solid-icon-Profile:before {
    content: "\ebaa"
}

.solid-icon-Project:before {
    content: "\ebab"
}

.solid-icon-Projector-2:before {
    content: "\ebac"
}

.solid-icon-Projector:before {
    content: "\ebad"
}

.solid-icon-Pulse:before {
    content: "\ebae"
}

.solid-icon-Pumpkin:before {
    content: "\ebaf"
}

.solid-icon-Punk:before {
    content: "\ebb0"
}

.solid-icon-Punker:before {
    content: "\ebb1"
}

.solid-icon-Puzzle:before {
    content: "\ebb2"
}

.solid-icon-QIK:before {
    content: "\ebb3"
}

.solid-icon-QR-Code:before {
    content: "\ebb4"
}

.solid-icon-Queen-2:before {
    content: "\ebb5"
}

.solid-icon-Queen:before {
    content: "\ebb6"
}

.solid-icon-Quill-2:before {
    content: "\ebb7"
}

.solid-icon-Quill-3:before {
    content: "\ebb8"
}

.solid-icon-Quill:before {
    content: "\ebb9"
}

.solid-icon-Quotes-2:before {
    content: "\ebba"
}

.solid-icon-Quotes:before {
    content: "\ebbb"
}

.solid-icon-Radio:before {
    content: "\ebbc"
}

.solid-icon-Radioactive:before {
    content: "\ebbd"
}

.solid-icon-Rafting:before {
    content: "\ebbe"
}

.solid-icon-Rain-Drop:before {
    content: "\ebbf"
}

.solid-icon-Rainbow-2:before {
    content: "\ebc0"
}

.solid-icon-Rainbow:before {
    content: "\ebc1"
}

.solid-icon-Ram:before {
    content: "\ebc2"
}

.solid-icon-Razzor-Blade:before {
    content: "\ebc3"
}

.solid-icon-Receipt-2:before {
    content: "\ebc4"
}

.solid-icon-Receipt-3:before {
    content: "\ebc5"
}

.solid-icon-Receipt-4:before {
    content: "\ebc6"
}

.solid-icon-Receipt:before {
    content: "\ebc7"
}

.solid-icon-Record-3:before {
    content: "\ebc8"
}

.solid-icon-Record-Media:before {
    content: "\ebc9"
}

.solid-icon-Record-Music:before {
    content: "\ebca"
}

.solid-icon-Record:before {
    content: "\ebcb"
}

.solid-icon-Recycling-2:before {
    content: "\ebcc"
}

.solid-icon-Recycling:before {
    content: "\ebcd"
}

.solid-icon-Reddit:before {
    content: "\ebce"
}

.solid-icon-Redhat:before {
    content: "\ebcf"
}

.solid-icon-Redirect:before {
    content: "\ebd0"
}

.solid-icon-Redo:before {
    content: "\ebd1"
}

.solid-icon-Reel:before {
    content: "\ebd2"
}

.solid-icon-Refinery:before {
    content: "\ebd3"
}

.solid-icon-Refresh-Window:before {
    content: "\ebd4"
}

.solid-icon-Refresh:before {
    content: "\ebd5"
}

.solid-icon-Reload-2:before {
    content: "\ebd6"
}

.solid-icon-Reload-3:before {
    content: "\ebd7"
}

.solid-icon-Reload:before {
    content: "\ebd8"
}

.solid-icon-Remote-Controll:before {
    content: "\ebd9"
}

.solid-icon-Remote-Controll2:before {
    content: "\ebda"
}

.solid-icon-Remove-Bag:before {
    content: "\ebdb"
}

.solid-icon-Remove-Basket:before {
    content: "\ebdc"
}

.solid-icon-Remove-Cart:before {
    content: "\ebdd"
}

.solid-icon-Remove-File:before {
    content: "\ebde"
}

.solid-icon-Remove-User:before {
    content: "\ebdf"
}

.solid-icon-Remove-Window:before {
    content: "\ebe0"
}

.solid-icon-Remove:before {
    content: "\ebe1"
}

.solid-icon-Rename:before {
    content: "\ebe2"
}

.solid-icon-Repair:before {
    content: "\ebe3"
}

.solid-icon-Repeat-2:before {
    content: "\ebe4"
}

.solid-icon-Repeat-3:before {
    content: "\ebe5"
}

.solid-icon-Repeat-4:before {
    content: "\ebe6"
}

.solid-icon-Repeat-5:before {
    content: "\ebe7"
}

.solid-icon-Repeat-6:before {
    content: "\ebe8"
}

.solid-icon-Repeat-7:before {
    content: "\ebe9"
}

.solid-icon-Repeat:before {
    content: "\ebea"
}

.solid-icon-Reset:before {
    content: "\ebeb"
}

.solid-icon-Resize:before {
    content: "\ebec"
}

.solid-icon-Restore-Window:before {
    content: "\ebed"
}

.solid-icon-Retouching:before {
    content: "\ebee"
}

.solid-icon-Retro-Camera:before {
    content: "\ebef"
}

.solid-icon-Retro:before {
    content: "\ebf0"
}

.solid-icon-Retweet:before {
    content: "\ebf1"
}

.solid-icon-Reverbnation:before {
    content: "\ebf2"
}

.solid-icon-Rewind:before {
    content: "\ebf3"
}

.solid-icon-RGB:before {
    content: "\ebf4"
}

.solid-icon-Ribbon-2:before {
    content: "\ebf5"
}

.solid-icon-Ribbon-3:before {
    content: "\ebf6"
}

.solid-icon-Ribbon:before {
    content: "\ebf7"
}

.solid-icon-Right-2:before {
    content: "\ebf8"
}

.solid-icon-Right-3:before {
    content: "\ebf9"
}

.solid-icon-Right-4:before {
    content: "\ebfa"
}

.solid-icon-Right-ToLeft:before {
    content: "\ebfb"
}

.solid-icon-Right:before {
    content: "\ebfc"
}

.solid-icon-Road-2:before {
    content: "\ebfd"
}

.solid-icon-Road-3:before {
    content: "\ebfe"
}

.solid-icon-Road:before {
    content: "\ebff"
}

.solid-icon-Robot-2:before {
    content: "\ec00"
}

.solid-icon-Robot:before {
    content: "\ec01"
}

.solid-icon-Rock-andRoll:before {
    content: "\ec02"
}

.solid-icon-Rocket:before {
    content: "\ec03"
}

.solid-icon-Roller:before {
    content: "\ec04"
}

.solid-icon-Roof:before {
    content: "\ec05"
}

.solid-icon-Rook:before {
    content: "\ec06"
}

.solid-icon-Rotate-Gesture:before {
    content: "\ec07"
}

.solid-icon-Rotate-Gesture2:before {
    content: "\ec08"
}

.solid-icon-Rotate-Gesture3:before {
    content: "\ec09"
}

.solid-icon-Rotation-390:before {
    content: "\ec0a"
}

.solid-icon-Rotation:before {
    content: "\ec0b"
}

.solid-icon-Router-2:before {
    content: "\ec0c"
}

.solid-icon-Router:before {
    content: "\ec0d"
}

.solid-icon-RSS:before {
    content: "\ec0e"
}

.solid-icon-Ruler-2:before {
    content: "\ec0f"
}

.solid-icon-Ruler:before {
    content: "\ec10"
}

.solid-icon-Running-Shoes:before {
    content: "\ec11"
}

.solid-icon-Running:before {
    content: "\ec12"
}

.solid-icon-Safari:before {
    content: "\ec13"
}

.solid-icon-Safe-Box:before {
    content: "\ec14"
}

.solid-icon-Safe-Box2:before {
    content: "\ec15"
}

.solid-icon-Safety-PinClose:before {
    content: "\ec16"
}

.solid-icon-Safety-PinOpen:before {
    content: "\ec17"
}

.solid-icon-Sagittarus-2:before {
    content: "\ec18"
}

.solid-icon-Sagittarus:before {
    content: "\ec19"
}

.solid-icon-Sailing-Ship:before {
    content: "\ec1a"
}

.solid-icon-Sand-watch:before {
    content: "\ec1b"
}

.solid-icon-Sand-watch2:before {
    content: "\ec1c"
}

.solid-icon-Santa-Claus:before {
    content: "\ec1d"
}

.solid-icon-Santa-Claus2:before {
    content: "\ec1e"
}

.solid-icon-Santa-onSled:before {
    content: "\ec1f"
}

.solid-icon-Satelite-2:before {
    content: "\ec20"
}

.solid-icon-Satelite:before {
    content: "\ec21"
}

.solid-icon-Save-Window:before {
    content: "\ec22"
}

.solid-icon-Save:before {
    content: "\ec23"
}

.solid-icon-Saw:before {
    content: "\ec24"
}

.solid-icon-Saxophone:before {
    content: "\ec25"
}

.solid-icon-Scale:before {
    content: "\ec26"
}

.solid-icon-Scarf:before {
    content: "\ec27"
}

.solid-icon-Scissor:before {
    content: "\ec28"
}

.solid-icon-Scooter-Front:before {
    content: "\ec29"
}

.solid-icon-Scooter:before {
    content: "\ec2a"
}

.solid-icon-Scorpio-2:before {
    content: "\ec2b"
}

.solid-icon-Scorpio:before {
    content: "\ec2c"
}

.solid-icon-Scotland:before {
    content: "\ec2d"
}

.solid-icon-Screwdriver:before {
    content: "\ec2e"
}

.solid-icon-Scroll-Fast:before {
    content: "\ec2f"
}

.solid-icon-Scroll:before {
    content: "\ec30"
}

.solid-icon-Scroller-2:before {
    content: "\ec31"
}

.solid-icon-Scroller:before {
    content: "\ec32"
}

.solid-icon-Sea-Dog:before {
    content: "\ec33"
}

.solid-icon-Search-onCloud:before {
    content: "\ec34"
}

.solid-icon-Search-People:before {
    content: "\ec35"
}

.solid-icon-secound:before {
    content: "\ec36"
}

.solid-icon-secound2:before {
    content: "\ec37"
}

.solid-icon-Security-Block:before {
    content: "\ec38"
}

.solid-icon-Security-Bug:before {
    content: "\ec39"
}

.solid-icon-Security-Camera:before {
    content: "\ec3a"
}

.solid-icon-Security-Check:before {
    content: "\ec3b"
}

.solid-icon-Security-Settings:before {
    content: "\ec3c"
}

.solid-icon-Security-Smiley:before {
    content: "\ec3d"
}

.solid-icon-Securiy-Remove:before {
    content: "\ec3e"
}

.solid-icon-Seed:before {
    content: "\ec3f"
}

.solid-icon-Selfie:before {
    content: "\ec40"
}

.solid-icon-Serbia:before {
    content: "\ec41"
}

.solid-icon-Server-2:before {
    content: "\ec42"
}

.solid-icon-Server:before {
    content: "\ec43"
}

.solid-icon-Servers:before {
    content: "\ec44"
}

.solid-icon-Settings-Window:before {
    content: "\ec45"
}

.solid-icon-Sewing-Machine:before {
    content: "\ec46"
}

.solid-icon-Sexual:before {
    content: "\ec47"
}

.solid-icon-Share-onCloud:before {
    content: "\ec48"
}

.solid-icon-Share-Window:before {
    content: "\ec49"
}

.solid-icon-Share:before {
    content: "\ec4a"
}

.solid-icon-Sharethis:before {
    content: "\ec4b"
}

.solid-icon-Shark:before {
    content: "\ec4c"
}

.solid-icon-Sheep:before {
    content: "\ec4d"
}

.solid-icon-Sheriff-Badge:before {
    content: "\ec4e"
}

.solid-icon-Shield:before {
    content: "\ec4f"
}

.solid-icon-Ship-2:before {
    content: "\ec50"
}

.solid-icon-Ship:before {
    content: "\ec51"
}

.solid-icon-Shirt:before {
    content: "\ec52"
}

.solid-icon-Shoes-2:before {
    content: "\ec53"
}

.solid-icon-Shoes-3:before {
    content: "\ec54"
}

.solid-icon-Shoes:before {
    content: "\ec55"
}

.solid-icon-Shop-2:before {
    content: "\ec56"
}

.solid-icon-Shop-3:before {
    content: "\ec57"
}

.solid-icon-Shop-4:before {
    content: "\ec58"
}

.solid-icon-Shop:before {
    content: "\ec59"
}

.solid-icon-Shopping-Bag:before {
    content: "\ec5a"
}

.solid-icon-Shopping-Basket:before {
    content: "\ec5b"
}

.solid-icon-Shopping-Cart:before {
    content: "\ec5c"
}

.solid-icon-Short-Pants:before {
    content: "\ec5d"
}

.solid-icon-Shoutwire:before {
    content: "\ec5e"
}

.solid-icon-Shovel:before {
    content: "\ec5f"
}

.solid-icon-Shuffle-2:before {
    content: "\ec60"
}

.solid-icon-Shuffle-3:before {
    content: "\ec61"
}

.solid-icon-Shuffle-4:before {
    content: "\ec62"
}

.solid-icon-Shuffle:before {
    content: "\ec63"
}

.solid-icon-Shutter:before {
    content: "\ec64"
}

.solid-icon-Sidebar-Window:before {
    content: "\ec65"
}

.solid-icon-Signal:before {
    content: "\ec66"
}

.solid-icon-Singapore:before {
    content: "\ec67"
}

.solid-icon-Skate-Shoes:before {
    content: "\ec68"
}

.solid-icon-Skateboard-2:before {
    content: "\ec69"
}

.solid-icon-Skateboard:before {
    content: "\ec6a"
}

.solid-icon-Skeleton:before {
    content: "\ec6b"
}

.solid-icon-Ski:before {
    content: "\ec6c"
}

.solid-icon-Skirt:before {
    content: "\ec6d"
}

.solid-icon-Skrill:before {
    content: "\ec6e"
}

.solid-icon-Skull:before {
    content: "\ec6f"
}

.solid-icon-Skydiving:before {
    content: "\ec70"
}

.solid-icon-Skype:before {
    content: "\ec71"
}

.solid-icon-Sled-withGifts:before {
    content: "\ec72"
}

.solid-icon-Sled:before {
    content: "\ec73"
}

.solid-icon-Sleeping:before {
    content: "\ec74"
}

.solid-icon-Sleet:before {
    content: "\ec75"
}

.solid-icon-Slippers:before {
    content: "\ec76"
}

.solid-icon-Smart:before {
    content: "\ec77"
}

.solid-icon-Smartphone-2:before {
    content: "\ec78"
}

.solid-icon-Smartphone-3:before {
    content: "\ec79"
}

.solid-icon-Smartphone-4:before {
    content: "\ec7a"
}

.solid-icon-Smartphone-Secure:before {
    content: "\ec7b"
}

.solid-icon-Smartphone:before {
    content: "\ec7c"
}

.solid-icon-Smile:before {
    content: "\ec7d"
}

.solid-icon-Smoking-Area:before {
    content: "\ec7e"
}

.solid-icon-Smoking-Pipe:before {
    content: "\ec7f"
}

.solid-icon-Snake:before {
    content: "\ec80"
}

.solid-icon-Snorkel:before {
    content: "\ec81"
}

.solid-icon-Snow-2:before {
    content: "\ec82"
}

.solid-icon-Snow-Dome:before {
    content: "\ec83"
}

.solid-icon-Snow-Storm:before {
    content: "\ec84"
}

.solid-icon-Snow:before {
    content: "\ec85"
}

.solid-icon-Snowflake-2:before {
    content: "\ec86"
}

.solid-icon-Snowflake-3:before {
    content: "\ec87"
}

.solid-icon-Snowflake-4:before {
    content: "\ec88"
}

.solid-icon-Snowflake:before {
    content: "\ec89"
}

.solid-icon-Snowman:before {
    content: "\ec8a"
}

.solid-icon-Soccer-Ball:before {
    content: "\ec8b"
}

.solid-icon-Soccer-Shoes:before {
    content: "\ec8c"
}

.solid-icon-Socks:before {
    content: "\ec8d"
}

.solid-icon-Solar:before {
    content: "\ec8e"
}

.solid-icon-Sound-Wave:before {
    content: "\ec8f"
}

.solid-icon-Sound:before {
    content: "\ec90"
}

.solid-icon-Soundcloud:before {
    content: "\ec91"
}

.solid-icon-Soup:before {
    content: "\ec92"
}

.solid-icon-South-Africa:before {
    content: "\ec93"
}

.solid-icon-Space-Needle:before {
    content: "\ec94"
}

.solid-icon-Spain:before {
    content: "\ec95"
}

.solid-icon-Spam-Mail:before {
    content: "\ec96"
}

.solid-icon-Speach-Bubble:before {
    content: "\ec97"
}

.solid-icon-Speach-Bubble2:before {
    content: "\ec98"
}

.solid-icon-Speach-Bubble3:before {
    content: "\ec99"
}

.solid-icon-Speach-Bubble4:before {
    content: "\ec9a"
}

.solid-icon-Speach-Bubble5:before {
    content: "\ec9b"
}

.solid-icon-Speach-Bubble6:before {
    content: "\ec9c"
}

.solid-icon-Speach-Bubble7:before {
    content: "\ec9d"
}

.solid-icon-Speach-Bubble8:before {
    content: "\ec9e"
}

.solid-icon-Speach-Bubble9:before {
    content: "\ec9f"
}

.solid-icon-Speach-Bubble10:before {
    content: "\eca0"
}

.solid-icon-Speach-Bubble11:before {
    content: "\eca1"
}

.solid-icon-Speach-Bubble12:before {
    content: "\eca2"
}

.solid-icon-Speach-Bubble13:before {
    content: "\eca3"
}

.solid-icon-Speach-BubbleAsking:before {
    content: "\eca4"
}

.solid-icon-Speach-BubbleComic:before {
    content: "\eca5"
}

.solid-icon-Speach-BubbleComic2:before {
    content: "\eca6"
}

.solid-icon-Speach-BubbleComic3:before {
    content: "\eca7"
}

.solid-icon-Speach-BubbleComic4:before {
    content: "\eca8"
}

.solid-icon-Speach-BubbleDialog:before {
    content: "\eca9"
}

.solid-icon-Speach-Bubbles:before {
    content: "\ecaa"
}

.solid-icon-Speak-2:before {
    content: "\ecab"
}

.solid-icon-Speak:before {
    content: "\ecac"
}

.solid-icon-Speaker-2:before {
    content: "\ecad"
}

.solid-icon-Speaker:before {
    content: "\ecae"
}

.solid-icon-Spell-Check:before {
    content: "\ecaf"
}

.solid-icon-Spell-CheckABC:before {
    content: "\ecb0"
}

.solid-icon-Spermium:before {
    content: "\ecb1"
}

.solid-icon-Spider:before {
    content: "\ecb2"
}

.solid-icon-Spiderweb:before {
    content: "\ecb3"
}

.solid-icon-Split-FourSquareWindow:before {
    content: "\ecb4"
}

.solid-icon-Split-Horizontal:before {
    content: "\ecb5"
}

.solid-icon-Split-Horizontal2Window:before {
    content: "\ecb6"
}

.solid-icon-Split-Vertical:before {
    content: "\ecb7"
}

.solid-icon-Split-Vertical2:before {
    content: "\ecb8"
}

.solid-icon-Split-Window:before {
    content: "\ecb9"
}

.solid-icon-Spoder:before {
    content: "\ecba"
}

.solid-icon-Spoon:before {
    content: "\ecbb"
}

.solid-icon-Sport-Mode:before {
    content: "\ecbc"
}

.solid-icon-Sports-Clothings1:before {
    content: "\ecbd"
}

.solid-icon-Sports-Clothings2:before {
    content: "\ecbe"
}

.solid-icon-Sports-Shirt:before {
    content: "\ecbf"
}

.solid-icon-Spot:before {
    content: "\ecc0"
}

.solid-icon-Spray:before {
    content: "\ecc1"
}

.solid-icon-Spread:before {
    content: "\ecc2"
}

.solid-icon-Spring:before {
    content: "\ecc3"
}

.solid-icon-Spurl:before {
    content: "\ecc4"
}

.solid-icon-Spy:before {
    content: "\ecc5"
}

.solid-icon-Squirrel:before {
    content: "\ecc6"
}

.solid-icon-SSL:before {
    content: "\ecc7"
}

.solid-icon-St-BasilsCathedral:before {
    content: "\ecc8"
}

.solid-icon-St-PaulsCathedral:before {
    content: "\ecc9"
}

.solid-icon-Stamp-2:before {
    content: "\ecca"
}

.solid-icon-Stamp:before {
    content: "\eccb"
}

.solid-icon-Stapler:before {
    content: "\eccc"
}

.solid-icon-Star-Track:before {
    content: "\eccd"
}

.solid-icon-Star:before {
    content: "\ecce"
}

.solid-icon-Starfish:before {
    content: "\eccf"
}

.solid-icon-Start2:before {
    content: "\ecd0"
}

.solid-icon-Start-3:before {
    content: "\ecd1"
}

.solid-icon-Start-ways:before {
    content: "\ecd2"
}

.solid-icon-Start:before {
    content: "\ecd3"
}

.solid-icon-Statistic:before {
    content: "\ecd4"
}

.solid-icon-Stethoscope:before {
    content: "\ecd5"
}

.solid-icon-Stop-2:before {
    content: "\ecd6"
}

.solid-icon-Stop-Music:before {
    content: "\ecd7"
}

.solid-icon-Stop:before {
    content: "\ecd8"
}

.solid-icon-Stopwatch-2:before {
    content: "\ecd9"
}

.solid-icon-Stopwatch:before {
    content: "\ecda"
}

.solid-icon-Storm:before {
    content: "\ecdb"
}

.solid-icon-Street-View:before {
    content: "\ecdc"
}

.solid-icon-Street-View2:before {
    content: "\ecdd"
}

.solid-icon-Strikethrough-Text:before {
    content: "\ecde"
}

.solid-icon-Stroller:before {
    content: "\ecdf"
}

.solid-icon-Structure:before {
    content: "\ece0"
}

.solid-icon-Student-Female:before {
    content: "\ece1"
}

.solid-icon-Student-Hat:before {
    content: "\ece2"
}

.solid-icon-Student-Hat2:before {
    content: "\ece3"
}

.solid-icon-Student-Male:before {
    content: "\ece4"
}

.solid-icon-Student-MaleFemale:before {
    content: "\ece5"
}

.solid-icon-Students:before {
    content: "\ece6"
}

.solid-icon-Studio-Flash:before {
    content: "\ece7"
}

.solid-icon-Studio-Lightbox:before {
    content: "\ece8"
}

.solid-icon-Stumbleupon:before {
    content: "\ece9"
}

.solid-icon-Suit:before {
    content: "\ecea"
}

.solid-icon-Suitcase:before {
    content: "\eceb"
}

.solid-icon-Sum-2:before {
    content: "\ecec"
}

.solid-icon-Sum:before {
    content: "\eced"
}

.solid-icon-Summer:before {
    content: "\ecee"
}

.solid-icon-Sun-CloudyRain:before {
    content: "\ecef"
}

.solid-icon-Sun:before {
    content: "\ecf0"
}

.solid-icon-Sunglasses-2:before {
    content: "\ecf1"
}

.solid-icon-Sunglasses-3:before {
    content: "\ecf2"
}

.solid-icon-Sunglasses-Smiley:before {
    content: "\ecf3"
}

.solid-icon-Sunglasses-Smiley2:before {
    content: "\ecf4"
}

.solid-icon-Sunglasses-W:before {
    content: "\ecf5"
}

.solid-icon-Sunglasses-W2:before {
    content: "\ecf6"
}

.solid-icon-Sunglasses-W3:before {
    content: "\ecf7"
}

.solid-icon-Sunglasses:before {
    content: "\ecf8"
}

.solid-icon-Sunrise:before {
    content: "\ecf9"
}

.solid-icon-Sunset:before {
    content: "\ecfa"
}

.solid-icon-Superman:before {
    content: "\ecfb"
}

.solid-icon-Support:before {
    content: "\ecfc"
}

.solid-icon-Surprise:before {
    content: "\ecfd"
}

.solid-icon-Sushi:before {
    content: "\ecfe"
}

.solid-icon-Sweden:before {
    content: "\ecff"
}

.solid-icon-Swimming-Short:before {
    content: "\ed00"
}

.solid-icon-Swimming:before {
    content: "\ed01"
}

.solid-icon-Swimmwear:before {
    content: "\ed02"
}

.solid-icon-Switch:before {
    content: "\ed03"
}

.solid-icon-Switzerland:before {
    content: "\ed04"
}

.solid-icon-Sync-Cloud:before {
    content: "\ed05"
}

.solid-icon-Sync:before {
    content: "\ed06"
}

.solid-icon-Synchronize-2:before {
    content: "\ed07"
}

.solid-icon-Synchronize:before {
    content: "\ed08"
}

.solid-icon-T-Shirt:before {
    content: "\ed09"
}

.solid-icon-Tablet-2:before {
    content: "\ed0a"
}

.solid-icon-Tablet-3:before {
    content: "\ed0b"
}

.solid-icon-Tablet-Orientation:before {
    content: "\ed0c"
}

.solid-icon-Tablet-Phone:before {
    content: "\ed0d"
}

.solid-icon-Tablet-Secure:before {
    content: "\ed0e"
}

.solid-icon-Tablet-Vertical:before {
    content: "\ed0f"
}

.solid-icon-Tablet:before {
    content: "\ed10"
}

.solid-icon-Tactic:before {
    content: "\ed11"
}

.solid-icon-Tag-2:before {
    content: "\ed12"
}

.solid-icon-Tag-3:before {
    content: "\ed13"
}

.solid-icon-Tag-4:before {
    content: "\ed14"
}

.solid-icon-Tag-5:before {
    content: "\ed15"
}

.solid-icon-Tag:before {
    content: "\ed16"
}

.solid-icon-Taj-Mahal:before {
    content: "\ed17"
}

.solid-icon-Talk-Man:before {
    content: "\ed18"
}

.solid-icon-Tap:before {
    content: "\ed19"
}

.solid-icon-Target-Market:before {
    content: "\ed1a"
}

.solid-icon-Target:before {
    content: "\ed1b"
}

.solid-icon-Taurus-2:before {
    content: "\ed1c"
}

.solid-icon-Taurus:before {
    content: "\ed1d"
}

.solid-icon-Taxi-2:before {
    content: "\ed1e"
}

.solid-icon-Taxi-Sign:before {
    content: "\ed1f"
}

.solid-icon-Taxi:before {
    content: "\ed20"
}

.solid-icon-Teacher:before {
    content: "\ed21"
}

.solid-icon-Teapot:before {
    content: "\ed22"
}

.solid-icon-Technorati:before {
    content: "\ed23"
}

.solid-icon-Teddy-Bear:before {
    content: "\ed24"
}

.solid-icon-Tee-Mug:before {
    content: "\ed25"
}

.solid-icon-Telephone-2:before {
    content: "\ed26"
}

.solid-icon-Telephone:before {
    content: "\ed27"
}

.solid-icon-Telescope:before {
    content: "\ed28"
}

.solid-icon-Temperature-2:before {
    content: "\ed29"
}

.solid-icon-Temperature-3:before {
    content: "\ed2a"
}

.solid-icon-Temperature:before {
    content: "\ed2b"
}