.startup .swiper-button-prev {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255/var(--tw-bg-opacity));
    border-radius: 50%;
    cursor: pointer;
    left: 60px;
    min-height: 60px;
    min-width: 60px;
    position: absolute;
    z-index: 2
}

.startup .swiper-button-prev:hover:after {
    color: #fff
}

.startup .swiper-button-prev:after {
    --tw-text-opacity: 1;
    color: rgb(170 170 170/var(--tw-text-opacity));
    display: block;
    font-size: 19px;
    line-height: 60px;
    text-align: center
}

.startup .swiper-button-next {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255/var(--tw-bg-opacity));
    border-radius: 50%;
    cursor: pointer;
    min-height: 60px;
    min-width: 60px;
    position: absolute;
    right: 60px;
    z-index: 2
}

.startup .swiper-button-next:hover:after {
    color: #fff
}

.startup .swiper-button-next:after {
    --tw-text-opacity: 1;
    color: rgb(170 170 170/var(--tw-text-opacity));
    display: block;
    font-size: 19px;
    line-height: 60px;
    text-align: center
}

.startup .startup-link {
    --tw-bg-opacity: 1;
    --tw-text-opacity: 1;
    align-items: center;
    background-color: rgb(0 176 87/var(--tw-bg-opacity));
    border-radius: 50px;
    color: rgb(255 255 255/var(--tw-text-opacity));
    display: flex;
    font-family: Poppins, sans-serif;
    padding: 9px 9px 9px 34px
}

.startup .startup-link:hover div {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255/var(--tw-bg-opacity))
}

.startup .startup-link:hover div i {
    --tw-text-opacity: 1;
    color: rgb(0 176 87/var(--tw-text-opacity))
}

.startup .startup-link div {
    --tw-bg-opacity: 1;
    align-items: center;
    background-color: rgb(0 124 62/var(--tw-bg-opacity));
    border-radius: 50%;
    display: flex;
    height: 38px;
    justify-content: center;
    margin-left: 19px;
    transition: var(--default-transition);
    width: 38px
}

.startup .startup-link div i {
    font-size: 12px;
    line-height: 16px;
    margin-top: 2px;
    padding-left: 3px;
    position: relative;
    top: -1px;
    transition: var(--default-transition)
}

.startup-footer .footer-menu span {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 15px
}

.startup-iconwithtext .icon-with-text-04 .feature-box-content .title {
    color: #262b35
}

.home-startup-interactivebanner .interactivebanners-main,
.home-startup-interactivebanner .interactivebanners-main div {
    border-radius: 6px
}

.startup-processstep .process-step-content {
    width: 80%
}

.home-startup-swiper .swiper-slide h1 {
    text-shadow: rgba(0, 0, 0, .3) 0 0 20px
}

.home-business-topbar .social-icon-style-01 li a {
    color: #828282
}

.home-business-piechart .piechart-style-02 .piechart-vertical p {
    width: 80%
}

.financepage header .navbar-nav .megamenu ul .title {
    text-transform: capitalize
}

.financepage .nav-item .simple-dropdown-menu li .nav-link,
.financepage header .navbar-nav .megamenu .nav-item .nav-link {
    text-transform: none
}

.finance-header-menu .navbar-nav>.nav-item {
    text-transform: uppercase
}

.finance-footer.footer-style-04 .subscribe-style-09 form button {
    background-color: #8bb867 !important;
    background-image: none !important
}

@media (max-width:575px) {
    .finance-footer.footer-style-04 .subscribe-style-09 form button {
        border-radius: 2px !important;
        bottom: 0;
        letter-spacing: inherit;
        margin-top: 0;
        overflow: hidden;
        padding: 4px 18px;
        position: absolute !important;
        right: -1px;
        top: 0;
        width: auto
    }
}

header .navbar-expand-lg.application-header .navbar-collapse.mobile-menu-modern {
    background: #302158
}

.application-iconwithtext i {
    margin-bottom: 20px
}

.application-iconwithtext>div {
    padding: 0
}

.application-iconwithtext .feature-box-content .title {
    margin-bottom: 5px
}

.consulting-about .list-style-02 i {
    font-size: 13px !important
}

.consulting-blogclean .post-details {
    padding: 40px
}

@media (max-width:1199px) {
    .consulting-blogclean .post-details {
        padding: 35px
    }
}

.consulting-footer .social-icon li:hover a {
    color: #fff
}

.consulting-footer .social-icon li a {
    color: #828282
}

.consulting-footer form .btn {
    background: #fff
}

.consulting-footer form .btn i {
    color: #bf8c4c
}

.seoagency-iconwithtext .icon-with-text-01 p {
    width: 85%
}

.seoagency-iconwithtext .icon-with-text-01 .feature-box-content .title {
    margin-bottom: 5px !important
}

.seo-agency-footer_06 div span {
    color: #374162;
    text-transform: capitalize
}

.seo-agency-footer_06 div p a {
    color: #374162
}

.eventsconference-counter .counter-style-03 {
    border-bottom: 1px solid #e4e4e4
}

.eventsconference-counter .counter-style-03>div {
    margin-bottom: 25px;
    margin-top: 5px
}

.eventsconference-counter .counter-style-03:last-child {
    border: 0
}

.eventsconference-counter .counter-style-03:last-child>div {
    margin-bottom: 0
}

.eventsconference-counter .counter-style-03 .postfix_sign {
    color: #232323 !important
}

.eventsconference-counter .counter-style-03 .counter-content {
    display: inline-block;
    width: 56%
}

.eventsconference-counter .counter-style-03 .counter-content .counter-title {
    letter-spacing: 0 !important;
    word-break: normal
}

.eventsconference-coundown .countdown-style-02>div {
    width: 120px
}

.eventsconference-coundown .countdown-style-02>div:after {
    color: #232323 !important;
    opacity: 1 !important;
    top: 18% !important
}

@media (max-width:575px) {
    .eventsconference-coundown .countdown-style-02>div {
        width: 50%
    }
}

.eventsconference-coundown .countdown-style-02 .number {
    display: inline-block;
    font-size: 38px !important;
    font-weight: 600;
    width: 80px !important
}

.eventsconference-coundown .countdown-style-02 .unit {
    font-weight: 500;
    opacity: 1
}

.eventsconference-accordion .accordion-style-04 .accordion-body {
    color: hsla(0, 0%, 100%, .302)
}

.eventsconference-tab .tab-title {
    padding: 0 40px 22px !important
}

@media (max-width:991px) {
    .eventsconference-tab .tab-title {
        padding: 20px 20px 22px !important
    }
}

.eventsconference-team .team-style-03 figure {
    border-radius: 5px;
    overflow: hidden
}

.eventsconference-testimonials .testimonials-style-03 .testimonials-bubble {
    padding: 28px 27px
}

.pricingtable-eventsconference .switch-tab .pricing-table-style-03.popular {
    padding-bottom: 5px
}

.pricingtable-eventsconference .switch-tab .pricing-table-style-03 .title {
    letter-spacing: 1px
}

.eventesconference-blog .blog-classic .blog-post-image {
    border-radius: 4px
}

.marketing-agency-parallx {
    --tw-bg-opacity: 1;
    background-color: rgb(244 217 86/var(--tw-bg-opacity));
    height: 100%;
    overflow: hidden;
    position: absolute;
    width: 80%
}

.marketing-agency-parallx span {
    --tw-text-opacity: 1;
    color: rgb(150 152 150/var(--tw-text-opacity));
    font-family: Poppins, sans-serif;
    font-size: 80px;
    font-weight: 600;
    height: 100%;
    letter-spacing: -3px;
    line-height: 80px;
    position: absolute;
    right: -20px;
    text-align: center;
    top: 0;
    white-space: nowrap;
    width: auto
}

@media (max-width:575px) {
    .marketing-agency-parallx span {
        font-size: 55px;
        line-height: 65px
    }
}

.marketing-agency-parallx span {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-writing-mode: vertical-rl;
    writing-mode: vertical-rl
}

.marketing-agency-testimonial:before {
    height: 0;
    position: absolute;
    right: 50px;
    top: 100%;
    width: 0
}

.marketing-agency-testimonial:before,
.marketingagency-quote :before {
    border: solid hsla(0, 0%, 93%, 0);
    border-top: solid #f4d956;
    border-width: 18px 0 0 18px;
    content: " "
}

.marketingagency-quote :before {
    height: 0;
    position: absolute;
    right: 50px;
    top: 100%;
    width: 0
}

.marketing-agency-progressbar .progressbar-title {
    font-weight: 500
}

.marketing-agency-counter h2 {
    color: #232323
}

.marketing-agency-counter .counter-content-section span {
    color: #9fa29f
}

.marketing-agency-testimonials .testimonials-bubble p {
    font-size: 16px;
    line-height: 34px
}

.restaurant-header-menu .navbar-nav>.nav-item>.nav-link {
    text-transform: uppercase
}

@media (max-width:575px) {
    .restaurant-experience {
        background: inherit !important
    }

    .restaurant-tab-style-03 .tab-style-03 .nav-item {
        padding-left: 0 !important
    }
}

.divider-full .divider-border:after,
.divider-full .divider-border:before {
    border-top: 1px solid #e4e4e4;
    content: "";
    flex: 1 1 auto
}

.divider-full .divider-border.divider-light:after,
.divider-full .divider-border.divider-light:before {
    border-color: hsla(0, 0%, 100%, .1)
}

.architecture-hamburgermenu .close-btn i {
    font-family: themify;
    font-style: normal
}

.architecture-hamburgermenu .close-btn i:before {
    content: "\e646"
}

.architecture-slider .swiper-button-next,
.architecture-slider .swiper-button-prev {
    background-color: #fff;
    color: #000;
    height: 67px;
    left: auto;
    right: 0;
    transition: var(--default-transition);
    width: 66px
}

.architecture-slider .swiper-button-next:after,
.architecture-slider .swiper-button-prev:after {
    font-size: 20px
}

.architecture-slider .swiper-button-next:hover,
.architecture-slider .swiper-button-prev:hover {
    background-color: #23262d;
    color: #fff
}

.architecture-slider .swiper-button-prev {
    bottom: 264px;
    top: auto;
    -webkit-transform: translateY(0);
    transform: translateY(0)
}

@media (max-width:1199px) {
    .architecture-slider .swiper-button-prev {
        bottom: 245px
    }
}

@media (max-width:767px) {
    .architecture-slider .swiper-button-prev {
        bottom: 67px
    }
}

.architecture-slider .swiper-button-next {
    bottom: 198px;
    left: auto;
    top: auto;
    -webkit-transform: translateY(0);
    transform: translateY(0)
}

@media (max-width:1199px) {
    .architecture-slider .swiper-button-next {
        bottom: 178px
    }
}

@media (max-width:767px) {
    .architecture-slider .swiper-button-next {
        bottom: 0
    }
}

.work-architecture-slider .portfolio-box {
    transition: var(--default-transition)
}

.work-architecture-slider .portfolio-box .portfolio-hover {
    margin-top: 15px;
    opacity: 0;
    transition: var(--default-transition);
    transition-delay: .2s
}

.work-architecture-slider .portfolio-box .portfolio-image {
    --tw-bg-opacity: 1;
    background-color: rgb(206 224 2/var(--tw-bg-opacity))
}

.work-architecture-slider .portfolio-box .portfolio-image img,
.work-architecture-slider .portfolio-box:hover {
    transition: var(--default-transition)
}

.work-architecture-slider .portfolio-box:hover .portfolio-hover {
    margin-top: 0;
    opacity: 1
}

.work-architecture-slider .portfolio-box:hover .portfolio-image img {
    opacity: .15
}

.hotel-resort-slider .swiper-button-next,
.hotel-resort-slider .swiper-button-prev {
    color: #fff;
    display: block;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.hotel-resort-slider .swiper-button-next:hover,
.hotel-resort-slider .swiper-button-prev:hover {
    opacity: .6
}

.hotel-resort-slider .swiper-button-next:after,
.hotel-resort-slider .swiper-button-prev:after {
    font-family: swiper-icons;
    font-size: 35px;
    height: 50px;
    width: 50px
}

.hotel-resort-slider .swiper-button-prev {
    left: 40px
}

.hotel-resort-slider .swiper-button-prev:after {
    content: "prev"
}

.hotel-resort-slider .swiper-button-next {
    right: 25px
}

.hotel-resort-slider .swiper-button-next:after {
    content: "next"
}

.hotel-resort-slider .swiper-pagination {
    --swiper-pagination-bullet-horizontal-gap: 5px;
    bottom: 39px
}

@-webkit-keyframes slideLeft {
    0% {
        -webkit-transform: scaleX(0);
        transform: scaleX(0)
    }

    to {
        -webkit-transform: scaleX(100%);
        transform: scaleX(100%)
    }
}

@keyframes slideLeft {
    0% {
        -webkit-transform: scaleX(0);
        transform: scaleX(0)
    }

    to {
        -webkit-transform: scaleX(100%);
        transform: scaleX(100%)
    }
}

@media (max-width:575px) {
    .hotelresort-client .client-logo-style-08 .col {
        border-bottom: 1px solid rgba(0, 0, 0, .102);
        border-right: 0
    }

    .hotelresort-client .client-logo-style-08 .col:last-child {
        border-bottom: 0
    }
}

.hotelresort-footer .footer-menu ul li a:hover {
    color: #c89965
}

.travel-agency-slider .swiper-button-next,
.travel-agency-slider .swiper-button-prev {
    background-color: hsla(0, 0%, 9%, .5)
}

.travel-agency-slider .swiper-button-prev {
    left: 40px
}

@media (max-width:575px) {
    .travel-agency-slider .swiper-button-prev {
        display: none
    }
}

.travel-agency-slider .swiper-button-next {
    right: 40px
}

@media (max-width:575px) {
    .travel-agency-slider .swiper-button-next {
        display: none
    }
}

.travel-agency-slider .swiper-pagination-bullets {
    bottom: 45px
}

.travel-agency-list-style .list-title {
    color: #232323
}

@media (max-width:575px) {
    .yogameditation-iconwithtext .icon-with-text-03>div {
        padding: 0 15px !important
    }
}

.yogameditation-accordion .accordion-style-02 .panel-title,
.yogameditation-accordion .accordion-style-02.light .accordion-button:after,
.yogameditation-accordion .accordion-style-02.light .accordion-button:not(.collapsed):after,
.yogameditation-iconwithtext .icon-with-text-03 .feature-box-content .title {
    color: #333045
}

.video-icon-wrapper,
.video-icon-wrapper i {
    height: 30px;
    width: 30px
}

.video-icon-wrapper i {
    align-items: center;
    display: flex;
    font-size: 26px;
    justify-content: center;
    left: 0;
    line-height: 1;
    position: absolute;
    top: 0
}

.gymfitness-header-menu .navbar-nav>.nav-item>.nav-link {
    text-transform: uppercase
}

.gym-fitness-pricing-table .pricing-table-style-03 .title {
    letter-spacing: 1px
}

.gym-fitness-pricing-table .pricing-table-style-03 .price {
    color: #262b35
}

.gym-fitness-pricing-table .pricing-table-style-03 .btn {
    --brand-color: #262b35 !important;
    border-radius: 0;
    letter-spacing: 1px
}

.gym-fitness-pricing-table .pricing-table-style-03.popular .title {
    background-color: #262b35
}

.gym-fitness-footer.footer-style-03 form button {
    background: #ff7a56 !important;
    border-radius: 0;
    border-width: 0;
    height: 46px !important;
    right: -2px !important
}

.gym-fitness-footer.footer-style-03 form button:active {
    border-color: transparent !important;
    color: #fff !important;
    outline: 0
}

.gym-fitness-footer>div:last-child {
    background-color: #1f232c;
    border-top: 0
}

.gym-fitness-footer>div p a {
    color: #8890a4 !important;
    text-decoration: none
}

.gym-fitness-footer>div p a:hover {
    color: #fff !important
}

header .spasalon-header .navbar-collapse.mobile-menu-modern {
    background: #232323
}

.text-overlap {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-writing-mode: vertical-rl;
    writing-mode: vertical-rl
}

.spasalon-slider .btn-slider-next>div,
.spasalon-slider .btn-slider-prev>div {
    transition: var(--default-transition)
}

.spasalon-slider .btn-slider-next>div i,
.spasalon-slider .btn-slider-prev>div i {
    transition: -webkit-transform .3s ease-in-out;
    transition: transform .3s ease-in-out;
    transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out
}

.spasalon-slider .btn-slider-next:hover>div,
.spasalon-slider .btn-slider-prev:hover>div {
    color: #ff8089
}

.spasalon-slider .btn-slider-prev i {
    margin-right: 10px
}

.spasalon-slider .btn-slider-prev:hover i {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px)
}

.spasalon-slider .btn-slider-next i {
    margin-left: 10px
}

.spasalon-slider .btn-slider-next:hover i {
    -webkit-transform: translateX(10px);
    transform: translateX(10px)
}

.spa-salon-swiper .swiper-pagination-fractional .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 80px
}

.spa-salon-swiper .swiper-pagination {
    display: none;
    left: 360px;
    position: absolute
}

@media (max-width:991px) {
    .spa-salon-swiper .swiper-pagination {
        justify-content: center;
        left: 0
    }
}

@media (max-width:575px) {
    .spa-salon-swiper .swiper-pagination {
        display: flex
    }
}

.spa-salon-swiper .swiper-pagination .swiper-pagination-bullet {
    color: #fff;
    font-family: Poppins, sans-serif;
    font-size: 15px;
    left: 0;
    margin-right: 20px;
    opacity: .5;
    top: 0;
    width: 20px
}

.spa-salon-swiper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    opacity: 1
}

@media (max-width:575px) {
    .cafe-tab-style-03 .tab-style-03 .nav-item {
        padding-left: 0
    }
}

.cafe-form ::-webkit-input-placeholder {
    --tw-text-opacity: 1;
    color: rgb(0 0 0/var(--tw-text-opacity));
    font-family: Poppins, sans-serif;
    font-size: 14px
}

.cafe-form ::placeholder {
    --tw-text-opacity: 1;
    color: rgb(0 0 0/var(--tw-text-opacity));
    font-family: Poppins, sans-serif;
    font-size: 14px
}

.decor-social-icon li a {
    color: #828282 !important;
    transition: var(--default-transition)
}

.decor-social-icon li a:hover {
    color: #bf8c4c !important
}

.home-decor .custom-pagination {
    bottom: 50px;
    left: 50%;
    position: absolute;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 5
}

.home-decor .custom-pagination .swiper-pagination-bullet {
    background: #bf8c4c;
    border: 1px solid #bf8c4c;
    height: 12px;
    margin-left: 5px;
    margin-right: 5px;
    opacity: .6;
    width: 12px
}

.home-decor .custom-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #bf8c4c;
    opacity: .2
}

.home-decor .small-circle {
    --tw-translate-x: -50%;
    --tw-translate-y: -50%;
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.home-decor .small-circle .element-one {
    background: hsla(22, 13%, 63%, .1);
    border-radius: 9999px;
    height: 830px;
    width: 830px
}

@media (max-width:1199px) {
    .home-decor .small-circle .element-one {
        height: 550px;
        width: 550px
    }
}

@media (max-width:767px) {
    .home-decor .small-circle .element-one {
        height: 400px;
        width: 400px
    }
}

@media (max-width:575px) {
    .home-decor .small-circle .element-one {
        height: 300px;
        width: 300px
    }
}

.decor-header .cart-total .btn:last-child {
    --brand-color: #bf8c4c !important
}

.dentist-iconwithtext {
    display: flex
}

.dentist-iconwithtext>div {
    background-color: #fff;
    box-shadow: 0 0 15px rgba(0, 0, 0, .08)
}

.dentist-iconwithtext>div:hover {
    -webkit-transform: translate3d(0, -3px, 0);
    transform: translate3d(0, -3px, 0)
}

.dentist-iconwithtext>div .feature-box-content span {
    display: block;
    font-size: 18px;
    margin-bottom: 15px
}

.dentist-list li {
    align-items: center
}

.dentist-list li .list-title {
    font-family: Poppins, sans-serif;
    line-height: 28px
}

.dentist-list li .list-content {
    width: 100%
}

.dentist-form ::-webkit-input-placeholder {
    --tw-text-opacity: 1;
    color: rgb(255 255 255/var(--tw-text-opacity));
    font-family: Poppins, sans-serif;
    font-size: 14px
}

.dentist-form ::placeholder {
    --tw-text-opacity: 1;
    color: rgb(255 255 255/var(--tw-text-opacity));
    font-family: Poppins, sans-serif;
    font-size: 14px
}

.dentist-icon-with-text .feature-box-content .title {
    font-size: 16px;
    line-height: 28px
}

.interior-design-swiper .swiper-pagination-bullets .swiper-pagination-bullet {
    background: #fff;
    border-color: #fff;
    height: 12px;
    opacity: .5;
    width: 12px
}

.interiordesign-icon-with-text .feature-box-bg-white-hover {
    transition: var(--default-transition)
}

.interiordesign-icon-with-text .feature-box-bg-white-hover:hover {
    background-color: #fff;
    border-color: #fff !important;
    box-shadow: 0 0 15px rgba(0, 0, 0, .08)
}

.interiordesign-icon-with-text .feature-box-bg-white-hover:hover .move-bottom-top {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0)
}

.interiordesign-icon-with-text .feature-box-bg-white-hover:hover .feature-box-move-bottom-top {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px)
}

.interiordesign-icon-with-text .feature-box-bg-white-hover .move-bottom-top {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    transition: var(--default-transition)
}

.interiordesign-icon-with-text .feature-box-bg-white-hover .feature-box-move-bottom-top {
    transition: var(--default-transition)
}

.interiordesign-counter .vertical-counter span {
    font-size: 40px !important
}

.interiordesign-counter .counter-content {
    margin-top: 0
}

.interiordesign-counter .counter-content .counter-title {
    font-weight: 400
}

.interiordesign-header-menu .navbar-nav>.nav-item>.nav-link {
    text-transform: uppercase
}

.interiordesign-blog .blog-author {
    background: var(--base-color) !important;
    -webkit-background-clip: text !important
}

header .digital-agency-header .navbar-collapse.mobile-menu-full {
    background: #333a3a
}

@media (max-width:575px) {

    .digital-agency-testimonial-carousel .swiper-navigation-02 .testimonial-carousel-style-07-next,
    .digital-agency-testimonial-carousel .swiper-navigation-02 .testimonial-carousel-style-07-prev {
        display: none
    }
}

.footer-digitalagency .footer-menu>span {
    font-size: 14px !important;
    letter-spacing: 2px;
    line-height: 20px
}

.footer-digitalagency .footer-menu .subtitle {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -.5px;
    line-height: 32px
}

@media (max-width:1199px) {
    .footer-digitalagency .footer-menu .subtitle {
        font-size: 18px;
        line-height: 22px
    }
}

.footer-digitalagency .footer-menu .instagram-col {
    font-size: 14px !important;
    letter-spacing: 2px;
    line-height: 20px
}

.footer-digitalagency .footer-details {
    padding-bottom: 5.5rem
}

.footer-digitalagency .footer-details p a {
    font-weight: 500
}

.design-agency-portfolio-bordered .scale div:first-child {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -1px;
    line-height: 28px
}

@media (max-width:575px) {
    .design-agency-blog-masonry {
        margin-top: 4rem
    }
}

.design-agency-blog-masonry .category {
    color: #828282
}

.design-agency-blog-masonry .category:hover {
    color: #000
}

.creative-footer .footer-menu span {
    letter-spacing: 2px;
    margin-bottom: 20px
}

@media (max-width:575px) {
    .creative-footer .footer-menu span {
        margin-bottom: 10px
    }
}

.creative-footer .footer-menu ul li a {
    font-size: 16px;
    line-height: 34px
}

.freelancer-portfolioswitchimg .portfolio-switchimg .portfolio-content span {
    text-transform: uppercase
}

.freelancer-iconwithtext .icon-with-text-08 i {
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-image: linear-gradient(90deg, #5cc088, #50c28d, #83b879, #c9a95e, #dda556);
    box-shadow: none;
    margin-right: 35px
}

.freelancer-iconwithtext .icon-with-text-08 .feature-box-content .title {
    font-weight: 600;
    letter-spacing: -.5px
}

.freelancer-social-icon .social-icon.md li {
    margin: 0 !important
}

.brandinga-agency-testimonials .swiper-slide span {
    line-height: 40px
}

@media (max-width:575px) {
    .brandinga-agency-testimonials .swiper-slide span {
        line-height: 28px
    }
}

.brandinga-agency-testimonials .swiper-pagination {
    bottom: 0 !important
}

.brandinga-agency-social-icon .social-icon li {
    margin-bottom: 0 !important
}

.brandinga-agency-social-icon .social-icon li a {
    color: #fff
}

.brandingagency-portfolioscattered .portfolio-scattered .portfolio-hover h6 {
    text-transform: uppercase
}

.photography-slider .swiper-button-next,
.photography-slider .swiper-button-prev {
    bottom: 70px;
    cursor: pointer;
    height: 50px;
    top: auto;
    width: auto
}

.photography-slider .swiper-button-next span,
.photography-slider .swiper-button-prev span {
    --tw-text-opacity: 1;
    color: rgb(130 130 130/var(--tw-text-opacity));
    display: inline-block;
    font-size: 18px;
    line-height: 22px;
    transition: var(--default-transition)
}

.photography-slider .swiper-button-next .line,
.photography-slider .swiper-button-prev .line {
    --tw-bg-opacity: 1;
    background-color: rgb(40 41 43/var(--tw-bg-opacity));
    border-style: none;
    display: inline-block;
    height: 1px;
    margin-right: 15px;
    position: relative;
    width: 133px
}

.photography-slider .swiper-button-next .line:after,
.photography-slider .swiper-button-prev .line:after {
    background: linear-gradient(270deg, #3edfb6, #58e49a, #7ce878, #a5e850, #d0e51b);
    content: "";
    display: inline-block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: var(--default-transition);
    width: 0
}

.photography-slider .swiper-button-next:after,
.photography-slider .swiper-button-prev:after {
    content: ""
}

.photography-slider .swiper-button-next:hover .line:after,
.photography-slider .swiper-button-prev:hover .line:after {
    width: 100%
}

.photography-slider .swiper-button-next:hover span,
.photography-slider .swiper-button-prev:hover span {
    --tw-text-opacity: 1;
    color: rgb(255 255 255/var(--tw-text-opacity))
}

@media (max-width:575px) {

    .photography-slider .swiper-button-next,
    .photography-slider .swiper-button-prev {
        display: none
    }
}

.photography-slider .swiper-button-next .line {
    margin-left: 15px;
    margin-right: 0
}

.photography-slider .swiper-button-next .line:after {
    left: auto;
    right: 0
}

.photography-bg-gradient {
    -webkit-text-fill-color: transparent;
    background: linear-gradient(90deg, #3edfb6, #58e49a, #7ce878, #a5e850, #d0e51b);
    -webkit-background-clip: text
}

.portfoliooverlay-personalportfolio .portfolio-overlay .portfolio-hover div {
    text-transform: uppercase
}

.verticalportfolio-social-icon li a {
    color: #fff !important
}

.verticalportfolio-social-icon li a:hover {
    opacity: .5 !important
}

.vertical-portfolio .swiper-pagination-vertical.swiper-pagination-bullets {
    right: 110px !important
}

.vertical-portfolio .swiper-pagination-vertical .swiper-pagination-bullet {
    margin: 10px 0 !important
}

.home-interactive-portfolio {
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0/var(--tw-bg-opacity));
    overflow: hidden;
    padding: 0
}

.home-interactive-portfolio .fullscreen-hover-box {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10.5rem;
    padding-right: 10.5rem;
    position: relative;
    width: 100%;
    z-index: 1
}

@media (max-width:991px) {
    .home-interactive-portfolio .fullscreen-hover-box {
        padding-left: 4rem;
        padding-right: 4rem
    }
}

@media (max-width:575px) {
    .home-interactive-portfolio .fullscreen-hover-box {
        padding-left: 10px;
        padding-right: 10px
    }
}

.fullscreen-hover-list {
    max-height: 80vh;
    overflow: auto;
    width: 100%
}

.fullscreen-hover-list .hover-list-item.active .interactive-number {
    opacity: 1;
    -webkit-transform: translateX(65px);
    transform: translateX(65px);
    transition-duration: .7s;
    transition-timing-function: ease-in-out
}

.fullscreen-hover-list .hover-list-item.active .interactive-line {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
    transition-duration: .7s;
    transition-timing-function: ease-in-out
}

.fullscreen-hover-list .hover-list-item.active .interactive-icon {
    display: none
}

@media (max-width:991px) {
    .fullscreen-hover-list .hover-list-item.active .interactive-icon {
        display: inline-block
    }
}

.fullscreen-hover-list .hover-list-item.active .interactive-icon {
    opacity: 1;
    visibility: visible
}

.fullscreen-hover-list .hover-list-item.active .interactive-title {
    --tw-text-opacity: 1;
    -webkit-text-stroke: 0 #4e4e4f;
    color: rgb(255 255 255/var(--tw-text-opacity));
    transition-duration: .5s;
    transition-timing-function: ease-in-out
}

.fullscreen-hover-list .hover-list-item.active .interactive-title:after {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    white-space: nowrap;
    width: calc(100% - 70px)
}

@media (max-width:767px) {
    .fullscreen-hover-list .hover-list-item.active .interactive-title:after {
        width: 100%
    }
}

@media (max-width:575px) {
    .fullscreen-hover-list .hover-list-item.active .interactive-title:after {
        height: 42%;
        width: 100%
    }
}

.fullscreen-hover-list .hover-list-item.active .fullscreen-hover-image {
    opacity: 1;
    visibility: visible
}

.fullscreen-hover-list .hover-list-item a {
    --tw-text-opacity: 1;
    color: rgb(130 130 130/var(--tw-text-opacity));
    color: #828282;
    display: inline-block;
    text-decoration: none
}

.fullscreen-hover-list .hover-list-item a .interactive-number {
    --tw-text-opacity: 1;
    color: rgb(255 255 255/var(--tw-text-opacity));
    display: inline-block;
    font-weight: 300;
    margin-bottom: 0;
    opacity: .3;
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    vertical-align: middle
}

.fullscreen-hover-list .hover-list-item a .interactive-line {
    --tw-bg-opacity: 1;
    background-color: rgb(228 228 228/var(--tw-bg-opacity));
    display: inline-block;
    height: 1px;
    margin-left: 35px;
    vertical-align: middle;
    width: 30px
}

@media (max-width:575px) {
    .fullscreen-hover-list .hover-list-item a .interactive-line {
        margin-left: 5px
    }
}

.fullscreen-hover-list .hover-list-item a .interactive-line {
    opacity: .2;
    transition-duration: .7s;
    transition-timing-function: ease-in-out
}

.fullscreen-hover-list .hover-list-item a .interactive-title {
    --tw-text-opacity: 1;
    color: rgb(62 62 62/var(--tw-text-opacity));
    display: inline-block;
    font-family: Poppins, sans-serif;
    font-size: 80px;
    font-weight: 600;
    line-height: 80px;
    padding: 25px 35px;
    position: relative;
    vertical-align: middle
}

@media (max-width:1199px) {
    .fullscreen-hover-list .hover-list-item a .interactive-title {
        font-size: 70px;
        line-height: 70px;
        padding: 20px 35px
    }
}

@media (max-width:991px) {
    .fullscreen-hover-list .hover-list-item a .interactive-title {
        font-size: 55px;
        line-height: 60px
    }
}

@media (max-width:767px) {
    .fullscreen-hover-list .hover-list-item a .interactive-title {
        font-size: 35px;
        line-height: 35px;
        padding: 25px
    }
}

@media (max-width:575px) {
    .fullscreen-hover-list .hover-list-item a .interactive-title {
        font-size: 30px;
        line-height: 35px;
        padding: 15px
    }
}

.fullscreen-hover-list .hover-list-item a .interactive-title {
    -webkit-text-stroke: 2px #4e4e4f;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    transition-duration: .5s
}

@media (max-width:575px) {
    .fullscreen-hover-list .hover-list-item a .interactive-title {
        -webkit-text-stroke: 1px #4e4e4f
    }
}

.fullscreen-hover-list .hover-list-item a .interactive-title:after {
    -webkit-text-fill-color: #fff;
    bottom: 25px;
    content: attr(data-link-text);
    height: calc(100% - 50px);
    left: 35px;
    overflow: hidden;
    position: absolute;
    transition: .4s ease-in-out;
    width: 0
}

.fullscreen-hover-list .hover-list-item a .interactive-icon {
    --tw-text-opacity: 1;
    color: rgb(255 255 255/var(--tw-text-opacity));
    display: inline-block;
    font-size: 50px;
    line-height: 1.5;
    position: relative;
    vertical-align: middle
}

@media (max-width:575px) {
    .fullscreen-hover-list .hover-list-item a .interactive-icon {
        font-size: 40px;
        margin-left: 15px
    }
}

.fullscreen-hover-list .hover-list-item a .interactive-icon {
    opacity: 0;
    transition-duration: .5s;
    transition-timing-function: ease-in-out;
    visibility: hidden
}

.fullscreen-hover-list .hover-list-item .fullscreen-hover-image {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    left: 0;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    transition: .3s ease-in-out;
    visibility: hidden;
    width: 100%;
    z-index: -1
}

@media (max-width:991px) {
    .split-swiper .swiper-wrapper {
        display: block
    }
}

.split-swiper .swiper-slide {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    width: 100%
}

@media (max-width:991px) {
    .split-swiper .swiper-slide {
        flex-direction: row-reverse
    }
}

.split-swiper .swiper-slide.swiper-slide-active .swiper-half-l {
    z-index: 1
}

.split-swiper .swiper-slide.swiper-slide-active .swiper-half-l,
.split-swiper .swiper-slide.swiper-slide-active .swiper-half-r {
    min-height: 600px;
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0)
}

.split-swiper .swiper-slide.swiper-slide-duplicate-active .swiper-half-l,
.split-swiper .swiper-slide.swiper-slide-duplicate-active .swiper-half-r {
    -webkit-transform: translateY(0);
    transform: translateY(0)
}

.split-swiper .swiper-slide .swiper-half-l {
    opacity: 0;
    position: relative;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    transition: .5s ease-in-out;
    width: 50%
}

@media (max-width:991px) {
    .split-swiper .swiper-slide .swiper-half-l {
        flex: 0 0 100%;
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }

    @media screen and (orientation:landscape) {
        .split-swiper .swiper-slide .swiper-half-l {
            min-height: 500px
        }
    }
}

.split-swiper .swiper-slide .swiper-half-r {
    opacity: 0;
    position: relative;
    width: 50%
}

@media (max-width:991px) {
    .split-swiper .swiper-slide .swiper-half-r {
        width: 100%
    }
}

.split-swiper .swiper-slide .swiper-half-r {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    transition: .5s ease-in-out
}

@media (max-width:991px) {
    .split-swiper .swiper-slide .swiper-half-r {
        flex: 0 0 100%;
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }

    @media screen and (orientation:landscape) {
        .split-swiper .swiper-slide .swiper-half-r {
            min-height: 500px
        }
    }

    .split-swiper .swiper-slide {
        height: auto !important;
        opacity: 1 !important;
        -webkit-transform: translateY(0) !important;
        transform: translateY(0) !important
    }
}

.split-swiper .swiper-pagination-bullets {
    right: 60px !important
}

.split-swiper .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 10px 0 !important
}

.split-swiper .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
    --tw-bg-opacity: 1;
    background-color: rgb(35 35 35/var(--tw-bg-opacity));
    height: 16px;
    width: 16px
}

.splitportfolio-hamburgermenu ul {
    font-family: Poppins, sans-serif;
    font-weight: 600;
    letter-spacing: -2px;
    width: 100%
}

.splitportfolio-hamburgermenu ul li {
    padding-bottom: 13px;
    padding-top: 13px
}

.splitportfolio-hamburgermenu ul li:hover a {
    color: #00000099
}

.splitportfolio-hamburgermenu ul li a {
    --tw-text-opacity: 1;
    color: rgb(35 35 35/var(--tw-text-opacity));
    display: inline-block;
    font-size: 44px;
    line-height: 50px;
    padding: 0;
    position: relative
}

@media (max-width:991px) {
    .splitportfolio-hamburgermenu ul li a {
        font-size: 30px;
        line-height: 38px
    }
}

@media (max-width:767px) {
    .splitportfolio-hamburgermenu ul li a {
        font-size: 24px;
        line-height: 30px
    }
}

.splitportfolio-hamburgermenu ul li a {
    transition: var(--default-transition)
}

.splitportfolio-hamburgermenu ul li a:hover {
    outline: 0;
    outline-offset: 0;
    text-decoration: none
}

.splitportfolio-hamburgermenu ul li a:hover:before {
    visibility: visible;
    width: 100%
}

.splitportfolio-hamburgermenu ul li a:before {
    --tw-translate-x: -50%;
    background-color: #17171780;
    bottom: 0;
    content: "";
    height: 2px;
    left: 50%;
    position: absolute;
    -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transition: var(--default-transition);
    visibility: hidden;
    width: 0
}

.creativeportfolio-humburger-menu .hamburger-menu-wrapper ul li {
    padding-bottom: 13px;
    padding-top: 13px
}

.creativeportfolio-humburger-menu .hamburger-menu-wrapper ul li a {
    color: #fff;
    font-family: Poppins, sans-serif;
    font-size: 44px;
    font-weight: 500;
    line-height: 50px
}

.creative-portfolio-slider .swiper-slide h2 {
    position: relative
}

.creative-portfolio-slider .swiper-slide h2:after {
    --tw-translate-y: -50%;
    --tw-scale-x: 0;
    --tw-bg-opacity: 1;
    background-color: rgb(35 35 35/var(--tw-bg-opacity));
    content: "";
    height: 4px;
    left: 0;
    position: absolute;
    top: 55%;
    transition: var(--default-transition);
    width: 100%
}

.creative-portfolio-slider .swiper-slide h2:after,
.creative-portfolio-slider .swiper-slide:hover h2:after {
    -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.creative-portfolio-slider .swiper-slide:hover h2:after {
    --tw-scale-x: 1
}

.creative-portfolio-slider .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 0 !important
}

.furniture-shop .small-circle {
    --tw-translate-x: -50%;
    --tw-translate-y: -50%;
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.furniture-shop .small-circle .element-one {
    background: hsla(22, 13%, 63%, .1);
    border-radius: 9999px;
    height: 650px;
    width: 650px
}

@media (max-width:1199px) {
    .furniture-shop .small-circle .element-one {
        height: 550px;
        width: 550px
    }
}

@media (max-width:767px) {
    .furniture-shop .small-circle .element-one {
        height: 400px;
        width: 400px
    }
}

@media (max-width:575px) {
    .furniture-shop .small-circle .element-one {
        height: 300px;
        width: 300px
    }
}

.furniture-shop .big-circle {
    border-radius: 9999px;
    border-radius: 100%;
    left: 60%;
    max-height: 1500px;
    max-width: 1500px;
    min-height: 1500px;
    min-width: 1500px;
    position: absolute;
    top: -100px;
    transition: var(--default-transition);
    white-space: nowrap
}

@media (max-width:767px) {
    .furniture-shop .big-circle {
        left: -6%;
        right: 0;
        top: 65%
    }
}

.shop-category .shop-product .shop-product-image {
    transition: all .3s ease-in-out
}

.shop-category .shop-product .shop-product-image a {
    left: 50%;
    opacity: 0;
    top: 50%;
    -webkit-transform: scale(1) translate(-50%, -50%);
    transform: scale(1) translate(-50%, -50%);
    transition: var(--default-transition)
}

.shop-category .shop-product .shop-product-image img {
    transition: opacity .3s cubic-bezier(.075, .82, .165, 1)
}

.shop-category:hover .shop-product .shop-product-image {
    -webkit-transform: scale(.9);
    transform: scale(.9)
}

.shop-category:hover .shop-product .shop-product-image a {
    box-shadow: 0 0 25px rgba(0, 0, 0, .08);
    opacity: 1;
    -webkit-transform: scale(1.1) translate(-50%, -50%);
    transform: scale(1.1) translate(-50%, -50%)
}

.shop-category:hover .shop-product .shop-product-image img {
    opacity: .4 !important
}

.furniture-shop .swiper-pagination .swiper-pagination-bullet {
    --tw-bg-opacity: 1;
    background-color: rgb(35 35 35/var(--tw-bg-opacity));
    border-color: transparent;
    border-width: 2px;
    height: 10px;
    opacity: .3;
    width: 10px
}

.furniture-shop .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    opacity: 1
}

.furniture-shop .swiper-horizontal>.swiper-pagination-bullets {
    bottom: 30px !important
}

@media (max-width:1199px) {
    .furniture-shop .swiper-horizontal>.swiper-pagination-bullets {
        bottom: 60px !important
    }
}

@media (max-width:991px) {
    @media screen and (orientation:landscape) {
        .furniture-shop .swiper-horizontal>.swiper-pagination-bullets {
            bottom: 0 !important
        }
    }
}

.fashionshop-shopwide .filter-menu .active span,
.fashionshop-shopwide .filter-menu span:hover {
    border-bottom-width: 2px
}

@media (max-width:575px) {
    .fashionshop-shopwide .grid-wrapper ul li .product-box .product-image {
        border-radius: 0 !important
    }
}

.fashionshop-footer>div {
    padding: 5% 0
}

@media (max-width:575px) {
    .fashionshop-footer>div {
        padding: 50px 0
    }
}

.fashionshop-footer>div p a {
    font-weight: 400
}

.fashion-shop-swiper .custom-pagination {
    bottom: 80px
}

.fashion-shop-swiper .custom-pagination .swiper-pagination-bullet {
    border: 0;
    border-bottom: 2px solid transparent;
    border-radius: 0;
    color: #232323;
    font-family: Poppins, sans-serif;
    font-size: 15px;
    line-height: 20px;
    margin-left: 15px;
    margin-right: 15px;
    opacity: .5
}

.fashion-shop-swiper .custom-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    border-bottom: 2px solid #232323;
    color: #000;
    opacity: 1
}

.lifestyleblog-footer>div {
    padding-top: 4%
}

@media (max-width:575px) {
    .lifestyleblog-footer>div {
        padding-bottom: 5%;
        padding-top: 50px
    }
}

.lifestyleblog-footer .text-themecolor {
    font-weight: 400
}

.home-classic-blog .blog-clean .blog-post {
    border-radius: 0 !important;
    box-shadow: none;
    -webkit-transform: none !important;
    transform: none !important
}

.home-classic-blog .blog-clean .blog-post .blog-rounded-icon {
    background-color: initial !important;
    border: none !important
}

.home-classic-blog .blog-clean .blog-post .blog-rounded-icon i {
    color: #fff;
    font-size: 28px !important
}

.about-me-counter .fancy-text-box-03 .text-box-content {
    padding: 3rem
}

@media (max-width:575px) {
    .about-me-counter .fancy-text-box-03 .text-box-content .text-box {
        flex-direction: column
    }
}

.about-me-counter .fancy-text-box-03 .text-box-content .text-box .fancy-box-wrapper {
    flex: none;
    padding-left: 35px
}

.about-me-counter .fancy-text-box-03 .text-box-content .text-box .fancy-box-wrapper span {
    line-height: 16px
}

.about-me-counter .fancy-text-box-03 .text-box-content .text-box .fancy-box-wrapper p {
    font-family: Poppins, sans-serif;
    line-height: normal
}

@media (max-width:575px) {
    .about-me-counter .fancy-text-box-03 .text-box-content .text-box .fancy-box-wrapper {
        border-left: 0;
        margin: 0;
        padding: 0;
        text-align: center
    }
}

.about-me-counter .fancy-text-box-03 .counter-style-01 .counter-numbers {
    font-weight: 500;
    letter-spacing: -1px;
    margin-bottom: 0
}

.about-me-blockquote .blockquote-style-01 {
    padding: 6rem
}

@media (max-width:1600px) {
    .about-me-blockquote .blockquote-style-01 {
        padding: 2rem
    }
}

@media (max-width:991px) {
    .about-me-blockquote .blockquote-style-01 {
        padding: 5rem
    }
}

.about-me-blockquote .blockquote-style-01 h6 {
    width: 100%
}

.about-me-blockquote .blockquote-style-01 span {
    opacity: .7
}

.about-me-progressbar .progressbar-title {
    font-size: 13px;
    font-weight: 500;
    line-height: 22px
}

@media (max-width:767px) {
    .about-me-progressbar .progressbar-main {
        padding-bottom: 1rem
    }
}

@media (max-width:575px) {
    .about-me-page-tab span {
        margin-bottom: 20px
    }
}

.about-me-icon-with-text div:first-child {
    padding: 3.5rem
}

@media (max-width:1199px) {
    .about-me-icon-with-text div:first-child {
        padding: .5rem
    }
}

.about-me-portfolio-overlay .portfolio-hover i {
    color: #fff
}

@media (max-width:575px) {
    .about-me-subscribe .subscribe-style-02 form button {
        position: relative !important
    }
}

.about-us-bg-gradient {
    background: linear-gradient(90deg, #CC9304, #CC9304, #CC9304);
    background-size: 200% auto;
    color: #fff;
    transition: all .3s cubic-bezier(.25, .8, .25, 1)
}

.about-us-bg-gradient:hover {
    background-position: 100%
}

.icon-with-text-02.about-us-icon-with-text>div {
    position: relative
}

.icon-with-text-02.about-us-icon-with-text>div:after {
    background-image: linear-gradient(to right top, #CC9304, #CC9304, #CC9304, #CC9304, #CC9304);
    content: "";
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition-duration: .4s;
    width: 100%;
    z-index: -1
}

.icon-with-text-02.about-us-icon-with-text>div i {
    --tw-gradient-from: #CC9304 var(--tw-gradient-from-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
    --tw-gradient-to: rgba(0, 56, 227, 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #CC9304 var(--tw-gradient-via-position), var(--tw-gradient-to);
    --tw-gradient-to: #CC9304 var(--tw-gradient-to-position);
    background-image: linear-gradient(to right, var(--tw-gradient-stops))
}

.icon-with-text-02.about-us-icon-with-text>div:hover:after {
    opacity: 1
}

.icon-with-text-02.about-us-icon-with-text>div:hover i {
    --tw-gradient-from: #fff var(--tw-gradient-from-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
    --tw-gradient-to: hsla(0, 0%, 100%, 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #fff var(--tw-gradient-via-position), var(--tw-gradient-to);
    --tw-gradient-to: #fff var(--tw-gradient-to-position);
    background-image: linear-gradient(to right, var(--tw-gradient-stops))
}

.icon-with-text-02.about-us-icon-with-text>div:hover .feature-box-content p,
.icon-with-text-02.about-us-icon-with-text>div:hover .feature-box-content span {
    color: #fff
}

.team-about-us .title-bottom .title {
    color: #232323 !important
}

.our-story-page .process-step-style-05.process-step .process-step-icon-box {
    padding: 45px 0 0
}

.our-story-process-step-style .process-step-description {
    padding-left: 1rem;
    padding-right: 1rem
}

.our-story-fancy-text-box .fancy-box-wrapper {
    padding-right: 40px
}

.our-story-fancy-text-box .fancy-box-wrapper p {
    font-family: Roboto, sans-serif !important
}

.our-story-fancy-text-box .text-box-content h4 {
    font-size: 2.3rem;
    line-height: 3rem
}

.our-story-testimonial .swiper-wrapper {
    cursor: url(../img/icon-move-black.png), move !important
}

.our-story-testimonial .swiper-wrapper .swiper-slide i {
    color: #c3c3c3
}

.our-story-testimonial .swiper-wrapper .swiper-slide span:nth-child(2) {
    color: #232323
}

.our-story-testimonial .swiper-wrapper .swiper-slide span:nth-child(4) {
    -webkit-text-fill-color: transparent;
    background: linear-gradient(90deg, #556fff, #556fff, #e05fc4, #f767a6, #ff798e);
    -webkit-background-clip: text
}

.who-we-are .icon-with-text-01>div {
    --tw-text-opacity: 1;
    color: rgb(255 255 255/var(--tw-text-opacity));
    padding: 3rem;
    position: relative;
    z-index: 10
}

@media (max-width:991px) {
    .who-we-are .icon-with-text-01>div {
        padding: 2.5rem
    }
}

.who-we-are .icon-with-text-01>div:after {
    background-color: #fff;
    border-radius: 4px;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition-duration: .4s;
    width: 100%;
    z-index: -1
}

.who-we-are .icon-with-text-01>div .feature-box-content .title {
    --tw-text-opacity: 1;
    color: rgb(255 255 255/var(--tw-text-opacity));
    display: inline-block;
    margin-bottom: 10px
}

.who-we-are .icon-with-text-01>div .feature-box-content p {
    opacity: .6;
    width: 100%
}

.who-we-are .icon-with-text-01>div:hover:after {
    opacity: 1
}

.who-we-are .icon-with-text-01>div:hover .title,
.who-we-are .icon-with-text-01>div:hover i,
.who-we-are .icon-with-text-01>div:hover p {
    color: #232323
}

.who-we-are .btn-custom-class {
    height: 120px !important;
    width: 120px !important
}

@media (max-width:767px) {
    .who-we-are .btn-custom-class {
        height: 80px !important;
        width: 80px !important
    }
}

.who-we-are .feature-box p {
    width: 75%
}

@media (max-width:575px) {
    .who-we-are .feature-box p {
        width: 90%
    }
}

.who-we-are .who-we-are-icon-with-text-02 {
    position: relative
}

.who-we-are .who-we-are-icon-with-text-02>div {
    background-color: #f7f7f7
}

@media (max-width:991px) {
    .who-we-are .who-we-are-icon-with-text-02>div {
        box-shadow: none !important
    }
}

.who-we-are .who-we-are-icon-with-text-02>div:after {
    background-color: #232323;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition-duration: .4s;
    width: 100%;
    z-index: -1
}

.who-we-are .who-we-are-icon-with-text-02>div:hover {
    -webkit-transform: translateY(0);
    transform: translateY(0)
}

.who-we-are .who-we-are-icon-with-text-02>div:hover i {
    color: #fff
}

.who-we-are .who-we-are-icon-with-text-02>div:hover:after {
    opacity: 1
}

.who-we-are .who-we-are-icon-with-text-02>div:hover .feature-box-content .title {
    color: #fff
}

.who-we-are .who-we-are-icon-with-text-02>div:hover .feature-box-content p {
    color: #fff;
    opacity: .65
}

.who-we-are .who-we-are-bg-color {
    background-image: linear-gradient(to right top, #b783ff, #e37be0, #fa7cc1, #ff85a6, #ff9393)
}

.ourservice-pricingtable .pricing-table-style-04 .pricing-wrapper {
    box-shadow: none
}

.ourservice-list .list-style-03 {
    font-size: 15px;
    font-weight: 400;
    text-transform: none
}

.ourservice-start-your-project .btn.btn-link:hover {
    color: #0038e3;
    opacity: 1
}

.ourservice-start-your-project .btn.btn-link:hover:after {
    background-color: #0038e3;
    opacity: 1
}

.our-team-page-team .team-style-04 figure figcaption .title-bottom .title {
    color: #232323
}

.latestnews-swiper .swiper-button-prev {
    align-items: center;
    background: #fff;
    display: flex;
    height: 60px;
    justify-content: center;
    left: 0;
    padding: 10px;
    position: absolute;
    top: 50%;
    -webkit-transform: matrix(1, 0, 0, 1, 20, -30);
    transform: matrix(1, 0, 0, 1, 20, -30);
    transition: all .3s;
    width: 60px;
    z-index: 1000
}

.latestnews-swiper .swiper-button-prev :hover {
    background-color: #fff
}

.latestnews-swiper .swiper-button-next {
    align-items: center;
    background: #fff;
    box-sizing: border-box;
    display: flex;
    height: 60px;
    justify-content: center;
    left: 100%;
    padding: 10px;
    position: absolute;
    top: 50%;
    -webkit-transform: matrix(1, 0, 0, 1, -80, -30);
    transform: matrix(1, 0, 0, 1, -80, -30);
    transition: all .3s;
    -webkit-transition: all .3s;
    width: 60px;
    z-index: 1000
}

.pricingtable-pricingpackages .switch-tab .nav-tabs .nav-link.active {
    background-image: linear-gradient(to right top, #0039e3, #4132e0, #5e28dd, #741bd9, #8600d4) !important
}

.pricingtable-pricingpackages .pricing-table-style-01.popular .pricing-wrapper a {
    background: #000;
    border: 2px solid #000;
    color: #fff;
    font-size: 11px;
    padding: 8px 22px
}

.pricingtable-pricingpackages .pricing-table-style-01.popular .pricing-wrapper a:hover {
    background: #fff;
    color: #000
}

.coming-soon-v2 .notify-me {
    background-color: rgba(0, 0, 0, .8);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 15
}

.coming-soon-v2 .countdown-style-03>div:first-child {
    padding-left: 0
}

.coming-soon-v2 .countdown-style-03>div:last-child {
    padding-right: 0
}

.coming-soon-v2 .countdown-style-03>div .unit {
    margin-top: 10px;
    opacity: .5 !important
}

.coming-soon-v2 .countdown-style-03 .number {
    font-size: 47px
}

.comingsoon-subscribe .subscribe-style-07 form input {
    font-size: 15px
}

.faqs-page-list .list-style li {
    border-bottom: 1px solid #e4e4e4;
    padding: 10px 0
}

.faqs-page-list .list-style li:last-child {
    border-bottom: none
}

.portfolio_btn .portfolio_img {
    opacity: 0;
    transition-duration: .5s
}

.portfolio_btn:hover .portfolio_img {
    opacity: 1
}

.portfolio_btn:hover .btn_icon {
    --tw-text-opacity: 1;
    color: rgb(255 255 255/var(--tw-text-opacity));
    transition-duration: .3s
}

.portfolio_btn:hover .portfolio_disc {
    color: #fff;
    transition-duration: .3s
}

.SingleProjectPage05 .swiper-wrapper {
    cursor: url(../img/download.png), move
}

.SingleProjectPage05 .swiper-wrapper .swiper-slide {
    opacity: .6
}

.SingleProjectPage05 .swiper-wrapper .swiper-slide.swiper-slide-active {
    opacity: 1
}

.white-header-portfolio .portfolio-scattered .portfolio-box .portfolio-hover h6 {
    text-transform: uppercase
}

.rotate-text .cd-text:after {
    bottom: -2px;
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    width: 100%
}

header.reverse-scroll.sticky-header.header-appear>.header-transparent .header-btn {
    --brand-color: #232323 !important;
    --text-color: #fff !important
}

@media (max-width:991px) {
    .navbar-expand-lg .header-btn {
        --brand-color: #232323 !important;
        --text-color: #fff !important
    }
}

@media (max-width:767px) {
    .navbar-expand-md .header-btn {
        --brand-color: #232323 !important;
        --text-color: #fff !important
    }
}

@media (max-width:575px) {
    .navbar-expand-sm .header-btn {
        --brand-color: #232323 !important;
        --text-color: #fff !important
    }
}

.center-logo .center-logo-btn {
    border-image-slice: 1;
    border-image-source: linear-gradient(45deg, #556fff, #ff798e)
}

.one-page-navigation.sticky-header {
    border-bottom-width: 0
}

.one-page-navigation.sticky-header .navbar-expand-lg.header-transparent {
    background-color: #000 !important;
    transition: var(--default-transition)
}

.one-page-navigation.sticky-header .navbar-expand-lg.navbar-dark.header-transparent .social-icon.light a {
    color: #fff
}

.one-page-navigation.sticky-header .navbar-expand-lg.navbar-dark.header-transparent .navbar-toggler .navbar-toggler-line {
    background-color: #fff
}

.one-page-navigation .navbar-nav .nav-item .active {
    opacity: .5
}

.fancy-text b {
    border-bottom: 2px solid #f4d956
}

.OnePageNavigationPage-fancytext-box .fancy-text-box-02 .col .text-box-content {
    box-shadow: none
}

.header-push-button .hamburgermenu-modern-page ul {
    font-family: Poppins, sans-serif;
    width: 100%
}

.header-push-button .hamburgermenu-modern-page ul li {
    padding-bottom: 13px;
    padding-top: 13px
}

@media (max-width:767px) {
    .header-push-button .hamburgermenu-modern-page ul li {
        padding-bottom: 7px;
        padding-top: 7px
    }
}

.header-push-button .hamburgermenu-modern-page ul li a {
    display: inline-block;
    font-size: 44px;
    line-height: 50px;
    padding: 0;
    position: relative
}

@media (max-width:991px) {
    .header-push-button .hamburgermenu-modern-page ul li a {
        font-size: 30px;
        line-height: 38px
    }
}

@media (max-width:767px) {
    .header-push-button .hamburgermenu-modern-page ul li a {
        font-size: 24px;
        line-height: 30px
    }
}

.header-push-button .hamburgermenu-modern-page ul li a {
    color: #232323;
    transition: var(--default-transition)
}

.header-push-button .hamburgermenu-modern-page ul li a:hover {
    color: hsla(0, 0%, 9%, .502);
    outline: 0;
    outline-offset: 0;
    text-decoration: none
}

.header-push-button .hamburgermenu-modern-page ul li a:hover:before {
    visibility: visible;
    width: 100%
}

.header-push-button .hamburgermenu-modern-page ul li a:before {
    background: hsla(0, 0%, 9%, .5);
    bottom: 0;
    content: "";
    height: 2px;
    left: 50%;
    position: absolute;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    transition: var(--default-transition);
    visibility: hidden;
    width: 0
}

.header-push-button.light .hamburgermenu-modern-page ul li:hover a {
    color: hsla(0, 0%, 100%, .5)
}

.header-push-button.light .hamburgermenu-modern-page ul li a {
    --tw-text-opacity: 1;
    color: rgb(255 255 255/var(--tw-text-opacity))
}

.header-push-button.light .hamburgermenu-modern-page ul li a:before {
    background-color: hsla(0, 0%, 100%, .5)
}

.left-sidebar-menu.collapsible-menu>.accordion-item>.accordion-header .accordion-button {
    font-size: 14px;
    line-height: normal;
    padding: 9px 0
}

.headeralwaysfixed-portfolio .portfolio-scattered .portfolio-box .portfolio-hover h6 {
    text-transform: uppercase
}

.blogpostlayout-page-02.swiper-navigation-03 .swiper-button-next,
.blogpostlayout-page-02.swiper-navigation-03 .swiper-button-prev {
    background: #fff;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.blogpostlayout-page-02.swiper-navigation-03 .swiper-button-prev {
    left: 40px
}

@media (max-width:767px) {
    .blogpostlayout-page-02.swiper-navigation-03 .swiper-button-prev {
        left: 25px
    }
}

@media (max-width:575px) {
    .blogpostlayout-page-02.swiper-navigation-03 .swiper-button-prev {
        left: -5px
    }
}

.blogpostlayout-page-02.swiper-navigation-03 .swiper-button-next {
    right: 40px
}

@media (max-width:767px) {
    .blogpostlayout-page-02.swiper-navigation-03 .swiper-button-next {
        right: 25px
    }
}

@media (max-width:575px) {
    .blogpostlayout-page-02.swiper-navigation-03 .swiper-button-next {
        right: -5px
    }
}

.quantity {
    display: inline-block;
    position: relative;
    vertical-align: middle
}

.qty-btn {
    background-color: initial;
    border-width: 0;
    border-left: 1px solid #e8e8e8;
    height: 19px;
    line-height: 19px;
    margin: 0;
    padding: 0;
    position: absolute;
    width: 22px
}

.qty-btn:focus {
    border-style: none;
    border-left: 1px solid #e8e8e8
}

.qty-plus {
    border-bottom: 1px solid #e8e8e8;
    border-radius: 0;
    height: 1.25rem;
    line-height: 1.25rem;
    padding-left: 3px;
    right: 1px;
    top: 3px;
    width: 22px
}

.qty-plus:focus {
    border-style: none;
    border-bottom: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8
}

.qty-minus {
    border-radius: 0;
    padding-left: 2px;
    right: 1px;
    top: 1.25rem
}

.qty-text {
    --tw-border-opacity: 1;
    border-color: rgb(223 223 223/var(--tw-border-opacity));
    border-radius: 0;
    border-width: 1px;
    line-height: 2.5rem;
    margin: 0;
    padding: 0 1.5rem 0 0;
    text-align: center;
    width: 65px
}

.coupon-code-panel,
.coupon-code-panel input {
    position: relative
}

.coupon-code-panel input {
    border: 1px dashed #e4e4e4;
    margin: 0;
    padding: 10px 70px 11px 45px;
    width: 100%
}

.coupon-code-panel:before {
    content: "\e8bf";
    font-family: feather;
    left: 1.25rem;
    line-height: 44px;
    position: absolute;
    z-index: 2
}

.coupon-code-panel .apply-coupon-btn {
    --tw-text-opacity: 1;
    color: rgb(35 35 35/var(--tw-text-opacity));
    font-weight: 500;
    position: absolute;
    right: 10px;
    text-transform: capitalize;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.cart-btn,
.coupon-code-panel .apply-coupon-btn {
    font-size: 12px;
    line-height: 16px;
    line-height: 1.25rem
}

.cart-btn {
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0/var(--tw-bg-opacity));
    bottom: 0;
    height: 2px;
    left: 50%;
    position: relative
}

.cart-btn:after {
    content: "";
    position: absolute;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 91.666667%
}

.total-price-table td,
.total-price-table th {
    border-style: none;
    border-bottom: 1px solid #e4e4e4;
    padding-bottom: 20px;
    padding-top: 20px
}

.total-price-table tr:last-child td,
.total-price-table tr:last-child th {
    border-style: none
}

.total-price-table .calculate-shipping th .calculate-shipping-title {
    --tw-text-opacity: 1;
    color: rgb(33 37 41/var(--tw-text-opacity));
    position: relative
}

.total-price-table .calculate-shipping th .calculate-shipping-title:after {
    content: "\e842";
    font-family: feather;
    font-weight: 700;
    position: absolute;
    right: 0;
    top: 0
}

.total-price-table .calculate-shipping th .calculate-shipping-title:hover {
    --tw-text-opacity: 1;
    color: rgb(33 37 41/var(--tw-text-opacity));
    outline-width: 0
}

.price-filter.ui-widget.ui-widget-content {
    --tw-bg-opacity: 1;
    background-color: rgb(223 223 223/var(--tw-bg-opacity));
    border: 1px solid #fff;
    border-radius: 0;
    height: 5px;
    margin: 25px 3px 30px 8px
}

.price-filter .ui-slider-range {
    --tw-bg-opacity: 1;
    background-color: rgb(35 35 35/var(--tw-bg-opacity));
    height: 3px
}

.price-filter .ui-slider-handle {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255/var(--tw-bg-opacity));
    border: 1px solid #bbb;
    border-radius: 9999px;
    cursor: ew-resize;
    height: 13px;
    outline-width: 0;
    top: -5px;
    width: 13px
}

.price-filter-details .btn-filter {
    --tw-text-opacity: 1;
    background-color: initial;
    border: 1px solid #d6d6d6;
    color: rgb(16 16 16/var(--tw-text-opacity));
    float: right;
    font-size: 10px;
    font-weight: 500;
    line-height: 19px;
    outline: 2px solid transparent;
    outline-offset: 2px;
    padding: 1px .75rem;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase
}

.price-filter-details .btn-filter:hover {
    --tw-bg-opacity: 1;
    --tw-text-opacity: 1;
    background-color: rgb(35 35 35/var(--tw-bg-opacity));
    border: 1px solid #232323;
    color: rgb(255 255 255/var(--tw-text-opacity))
}

.price-filter-details .price-filter-amount .price-amount {
    background-color: initial;
    border-style: none;
    max-width: 115px;
    padding: 0
}

.cart-products {
    border-collapse: collapse;
    border-color: #dee2e6;
    caption-side: bottom;
    color: #212529;
    margin-bottom: 1rem;
    vertical-align: top;
    width: 100%
}

@media (max-width:991px) {
    .cart-products {
        margin-bottom: 40px
    }
}

.cart-products thead {
    border: 0 solid;
    border-color: inherit;
    vertical-align: bottom
}

@media (max-width:991px) {
    .cart-products thead {
        display: none
    }
}

.cart-products thead tr {
    border: 0 solid;
    border-color: inherit
}

.cart-products thead tr th {
    border-bottom: 1px solid #e4e4e4 !important;
    border-top: 0;
    font-family: Poppins, sans-serif;
    font-weight: 500;
    padding: 15px 10px;
    text-transform: capitalize
}

.cart-products tbody {
    vertical-align: inherit
}

.cart-products tbody .product {
    border: 0 solid;
    border-color: inherit
}

@media (max-width:991px) {
    .cart-products tbody .product {
        background: 0 0;
        border-bottom: 1px solid #e8e8e8;
        display: block;
        margin-bottom: 20px;
        overflow: hidden;
        padding-bottom: 20px;
        padding-left: 95px;
        position: relative
    }
}

.cart-products tbody .product .product-remove {
    border-bottom: 1px solid #e4e4e4;
    border-top: 0;
    color: #828282;
    line-height: 28px;
    padding: 25px 10px;
    vertical-align: middle;
    width: 25px
}

@media (max-width:991px) {
    .cart-products tbody .product .product-remove {
        border: none;
        margin: 0;
        padding: 0;
        position: absolute;
        right: 0;
        text-align: right;
        top: 0;
        width: auto;
        z-index: 11
    }

    .cart-products tbody .product .product-remove:before {
        color: #232323;
        content: attr(data-title);
        display: block;
        float: left;
        font-family: Poppins, sans-serif;
        font-size: 12px;
        font-weight: 500
    }
}

.cart-products tbody .product .product-remove span {
    font-size: 18px;
    line-height: 22px
}

@media (max-width:991px) {
    .cart-products tbody .product .product-remove span {
        font-size: 16px;
        line-height: 22px
    }
}

.cart-products tbody .product .product-thumbnail {
    border-bottom: 1px solid #e4e4e4;
    border-top: 0;
    color: #828282;
    line-height: 28px;
    padding: 25px 10px;
    vertical-align: middle;
    width: 110px
}

@media (max-width:991px) {
    .cart-products tbody .product .product-thumbnail {
        border: none;
        display: inline-block;
        left: 0;
        margin-bottom: 10px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        text-align: left;
        top: 0;
        width: 80px
    }

    .cart-products tbody .product .product-thumbnail:before {
        color: #232323;
        content: attr(data-title);
        display: block;
        float: left;
        font-family: Poppins, sans-serif;
        font-size: 12px;
        font-weight: 500
    }
}

.cart-products tbody .product .product-thumbnail a .cart-product-image {
    height: auto;
    max-width: 75px
}

.cart-products tbody .product .product-name {
    border-bottom: 1px solid #e4e4e4;
    border-top: 0;
    color: #828282;
    line-height: 28px;
    padding: 25px 10px;
    vertical-align: middle
}

@media (max-width:991px) {
    .cart-products tbody .product .product-name {
        border: none;
        display: block;
        margin-bottom: 10px;
        padding: 0;
        text-align: left
    }

    .cart-products tbody .product .product-name:before {
        color: #232323;
        content: attr(data-title);
        display: block;
        float: left;
        font-family: Poppins, sans-serif;
        font-size: 12px;
        font-weight: 500
    }
}

.cart-products tbody .product .product-name a {
    color: #828282;
    text-decoration: none
}

.cart-products tbody .product .product-name span {
    float: left;
    width: 100%
}

@media (max-width:991px) {
    .cart-products tbody .product .product-name span {
        display: block;
        float: none
    }
}

.cart-products tbody .product .product-price {
    border-bottom: 1px solid #e4e4e4;
    border-top: 0;
    color: #828282;
    line-height: 28px;
    padding: 25px 10px;
    vertical-align: middle
}

@media (max-width:991px) {
    .cart-products tbody .product .product-price {
        border: none;
        display: block;
        margin-bottom: 10px;
        padding: 0;
        text-align: right
    }

    .cart-products tbody .product .product-price:before {
        color: #232323;
        content: attr(data-title);
        display: block;
        float: left;
        font-family: Poppins, sans-serif;
        font-size: 12px;
        font-weight: 500
    }
}

.cart-products tbody .product .product-quantity {
    border-bottom: 1px solid #e4e4e4;
    border-top: 0;
    color: #828282;
    line-height: 28px;
    padding: 25px 10px;
    vertical-align: middle
}

@media (max-width:991px) {
    .cart-products tbody .product .product-quantity {
        border: none;
        display: block;
        margin-bottom: 10px;
        padding: 0;
        text-align: right
    }

    .cart-products tbody .product .product-quantity:before {
        color: #232323;
        content: attr(data-title);
        display: block;
        float: left;
        font-family: Poppins, sans-serif;
        font-size: 12px;
        font-weight: 500
    }
}

.cart-products tbody .product .product-quantity .quantity {
    display: inline-block;
    position: relative;
    vertical-align: middle
}

.cart-products tbody .product .product-quantity .quantity .screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    width: 1px
}

.cart-products tbody .product .product-quantity .quantity .qty-minus {
    background: 0 0;
    border: 0;
    border-left: 1px solid #e8e8e8;
    border-radius: 0;
    cursor: pointer;
    height: 19px;
    line-height: 19px;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 1px;
    top: 20px;
    width: 22px
}

.cart-products tbody .product .product-quantity .quantity .qty-text {
    -webkit-appearance: textfield;
    border-radius: 0;
    height: 40px;
    line-height: 40px;
    margin: 0;
    outline: 0;
    padding: 0 24px 0 0;
    text-align: center;
    width: 65px
}

.cart-products tbody .product .product-quantity .quantity .qty-plus {
    background: 0 0;
    border: 0;
    border-bottom: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8;
    border-radius: 0;
    height: 19px;
    line-height: 19px;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 1px;
    top: 1px;
    width: 22px
}

.cart-products tbody .product .product-subtotal {
    border-bottom: 1px solid #e4e4e4;
    border-top: 0;
    color: #828282;
    line-height: 28px;
    padding: 25px 10px;
    vertical-align: middle
}

@media (max-width:991px) {
    .cart-products tbody .product .product-subtotal {
        border: none;
        display: block;
        margin: 0;
        padding: 0;
        text-align: right
    }

    .cart-products tbody .product .product-subtotal:before {
        color: #232323;
        content: attr(data-title);
        display: block;
        float: left;
        font-family: Poppins, sans-serif;
        font-size: 12px;
        font-weight: 500
    }
}

.cart-dropdown select {
    -webkit-appearance: none;
    appearance: none;
    background-image: url(../img/down-arrow.png);
    background-position: right 14px center;
    background-repeat: no-repeat;
    background-size: 10px;
    border-radius: 0;
    cursor: pointer;
    line-height: normal
}

.cart-dropdown select:focus-within {
    outline: none !important
}

.checkout-page select {
    -webkit-appearance: none;
    appearance: none;
    background-image: url(../img/down-arrow.png);
    background-position: right 14px center;
    background-repeat: no-repeat;
    background-size: 10px;
    border-radius: 0;
    cursor: pointer
}

.checkout-page select:focus-within {
    outline: none !important
}

.checkout-page .checkout-accordion .accordion-button {
    background: transparent;
    border: transparent;
    padding: 0
}

.checkout-page .checkout-accordion .accordion-button:after {
    background: transparent;
    content: ""
}

.checkout-page .checkout-accordion .accordion-button .accordion-toggle {
    display: inline-block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%
}

.checkout-page .checkout-accordion .accordion-item {
    background: transparent;
    border: transparent
}

.checkout-page .accordion-header label {
    color: #828282
}

.single-product .header-appear nav {
    border: 0
}

.single-product .tab-style-07 .nav-link.active {
    border-color: #232323
}

.single-product input::-webkit-inner-spin-button,
.single-product input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0
}

.single-product .shop-color li {
    margin-right: 12px
}

.single-product .shop-color li>input[type=radio]+label span {
    border-radius: 9999px;
    display: inline-block;
    height: 32px;
    width: 32px
}

.single-product input[type=radio]+label span {
    border-radius: 9999px;
    cursor: pointer;
    display: inline-block;
    height: 15px;
    position: relative;
    vertical-align: middle;
    width: 15px
}

.single-product .shop-color li>input[type=radio]+label span:after {
    font-size: 10px;
    line-height: 32px
}

.single-product .shop-color li>input[type=radio]:checked+label span:after {
    --tw-text-opacity: 1;
    color: rgb(255 255 255/var(--tw-text-opacity));
    content: "\f00c";
    font-family: Font Awesome\ 5 Free;
    font-size: 10px;
    font-weight: 900;
    height: 100%;
    left: 0;
    line-height: 32px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%
}

.single-product .shop-size {
    margin-right: 25px
}

.single-product .shop-size li {
    margin-right: 14px
}

.single-product .shop-size li:last-child {
    margin-right: 0
}

.single-product .shop-size li>input[type=radio]:checked+label span {
    --tw-text-opacity: 1;
    border-color: #23232340;
    border-width: 1px;
    color: rgb(35 35 35/var(--tw-text-opacity))
}

.single-product .shop-size li>input[type=radio]+label span {
    --tw-bg-opacity: 1;
    background-color: rgb(247 247 247/var(--tw-bg-opacity));
    border-color: transparent;
    border-radius: 9999px;
    border-width: 1px;
    height: 32px;
    line-height: 30px;
    text-align: center;
    width: 32px
}

.single-product .table-style-01,
.single-product .table-style-01 table {
    width: 100%
}

.single-product .table-style-01 tr th {
    padding: 12px 10px;
    text-align: center
}

.single-product .table-style-01 tr th:first-child {
    padding-left: 20px;
    text-align: left
}

.single-product .table-style-01 td {
    padding: 12px 10px;
    text-align: center
}

.single-product .size-chart .inner-modal {
    width: 60%
}

.single-product .single-product-thumb .swiper {
    width: calc(100% - 30px)
}

@media (max-width:991px) {
    .single-product .single-product-thumb .swiper {
        width: 100%
    }
}

.single-product .single-product-thumb .swiper .swiper-button-next,
.single-product .single-product-thumb .swiper .swiper-button-prev {
    --tw-bg-opacity: 1;
    --tw-text-opacity: 1;
    align-items: center;
    background-color: rgb(35 35 35/var(--tw-bg-opacity));
    bottom: 0;
    color: rgb(255 255 255/var(--tw-text-opacity));
    display: inline-block;
    display: flex;
    font-family: Font Awesome\ 5 Free;
    font-weight: 900;
    height: 40px;
    justify-content: center;
    line-height: 40px;
    position: absolute;
    top: auto;
    width: 48%
}

@media (max-width:991px) {

    .single-product .single-product-thumb .swiper .swiper-button-next,
    .single-product .single-product-thumb .swiper .swiper-button-prev {
        height: 30px;
        line-height: 30px;
        top: 50%;
        width: 30px
    }
}

.single-product .single-product-thumb .swiper .swiper-button-next:after,
.single-product .single-product-thumb .swiper .swiper-button-prev:after {
    display: none
}

@media (max-width:991px) {
    .single-product .single-product-thumb .swiper .swiper-button-prev {
        -webkit-transform: translateY(-50%) rotate(-90deg);
        transform: translateY(-50%) rotate(-90deg)
    }
}

.single-product .single-product-thumb .swiper .swiper-button-prev:before {
    content: "\f077"
}

@media (max-width:991px) {
    .single-product .single-product-thumb .swiper .swiper-button-next {
        -webkit-transform: translateY(-50%) rotate(-90deg);
        transform: translateY(-50%) rotate(-90deg)
    }
}

.single-product .single-product-thumb .swiper .swiper-button-next:before {
    content: "\f078"
}

.product-summary .breadcrumb {
    margin-bottom: 0
}

.product-summary .breadcrumb li {
    font-family: Roboto, sans-serif
}

.product-summary .quantity .qty-text {
    height: 100%
}

.product-summary .quantity .qty-btn {
    height: 50%
}

.product-summary .quantity .qty-btn.qty-minus {
    top: 50%
}

.product-images-box .swiper-button-next,
.product-images-box .swiper-button-prev {
    height: 65px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 65px;
    z-index: 10
}

.product-images-box .swiper-button-next:after,
.product-images-box .swiper-button-prev:after {
    color: #232323;
    font-family: Font Awesome\ 5 Free;
    font-weight: 700
}

.product-images-box .swiper-button-next {
    right: 0
}

.product-images-box .swiper-button-next:after {
    content: "\f054"
}

.product-images-box .swiper-button-prev {
    left: 0
}

.product-images-box .swiper-button-prev:after {
    content: "\f053"
}

.size-chart-popup {
    width: 1000px
}

@media (max-width:575px) {
    .size-chart-popup {
        width: 100%
    }
}

.size-chart-popup table {
    width: 100%
}

.table-style-01 {
    position: relative;
    width: 100%
}

@media (max-width:575px) {
    .table-style-01 {
        overflow: auto
    }
}

.table-style-01 table {
    width: 100%
}

@media (max-width:575px) {
    .table-style-01 table {
        width: -webkit-max-content;
        width: max-content
    }
}

.table-style-01 td,
.table-style-01 th {
    padding: 12px 10px;
    text-align: center
}

.table-style-01 tr td:first-child,
.table-style-01 tr th:first-child {
    padding-left: 20px;
    text-align: left
}

.demo-filter-color {
    background-image: linear-gradient(0deg, #f4f5f9, #f4f5f9, #f4f5f9, #f4f5f9, #fff)
}

.feturebox-section .icon-wrapper:hover {
    -webkit-transform: translate3d(0, -3px, 0);
    transform: translate3d(0, -3px, 0)
}

.features-elements .feature-box {
    transition: var(--default-transition)
}

.features-elements .feature-box.feature-box-shadow,
.features-elements .feature-box.feature-box-shadow .feature-box-overlay {
    box-shadow: 0 0 20px rgba(0, 0, 0, .05);
    transition: var(--default-transition)
}

.features-elements .feature-box.feature-box-shadow .feature-box-overlay {
    --tw-bg-opacity: 1;
    background-color: rgb(76 80 95/var(--tw-bg-opacity));
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1
}

.features-elements .feature-box.feature-box-shadow .feature-box-content span,
.features-elements .feature-box.feature-box-shadow .feature-box-icon i {
    transition: var(--default-transition)
}

.features-elements .feature-box.feature-box-shadow:hover {
    border-color: transparent;
    -webkit-transform: translate3d(0, -3px, 0);
    transform: translate3d(0, -3px, 0)
}

.features-elements .feature-box.feature-box-shadow:hover .feature-box-content span,
.features-elements .feature-box.feature-box-shadow:hover .feature-box-icon i {
    --tw-text-opacity: 1;
    color: rgb(255 255 255/var(--tw-text-opacity));
    z-index: 1
}

.features-elements .feature-box.feature-box-shadow:hover .feature-box-overlay {
    opacity: 1;
    z-index: -1
}

.landing-custom-iconwithtext .custom-icon-with-text01 {
    padding: 25px 2rem
}

.landing-custom-iconwithtext .custom-icon-with-text01 .title {
    font-size: 17px;
    font-weight: 500
}

.demo-filter .filter-menu span {
    border: none
}

.unique-demos .portfolio-content {
    font-size: 13px;
    padding-bottom: 0;
    padding-top: 15px
}

.colorful-page {
    -webkit-animation: myanimation 10s infinite;
    animation: myanimation 10s infinite;
    color: #000;
    font-weight: 700;
    height: 100%;
    width: 100%
}

@-webkit-keyframes myanimation {
    0% {
        background-color: #0038e3
    }

    25% {
        background-color: #ff6737
    }

    50% {
        background-color: #25b15f
    }

    75% {
        background-color: #cc2d92
    }

    to {
        background-color: #0038e3
    }
}

@keyframes myanimation {
    0% {
        background-color: #0038e3
    }

    25% {
        background-color: #ff6737
    }

    50% {
        background-color: #25b15f
    }

    75% {
        background-color: #cc2d92
    }

    to {
        background-color: #0038e3
    }
}

/*# sourceMappingURL=pages.css.map */